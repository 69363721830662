import React from "react";
import { connect } from "react-redux"
import { withStyles } from "@material-ui/core/styles";
import { Grid } from "@material-ui/core";
import FMessage from "../widgets/FMessage";
import FInput from "../widgets/FInput";
import { mapDispatchToProps, mapStateToProps } from "../../fredux/ActionMaker";
import {API_SERVICE_PROVIDER ,  API_DOCUMENTS_URL, CHOICE_FUEL_CARD, API_DRIVERS_URL, API_CARRIERS_URL, API_VEHICLES_URL, TAB_ADMIN, API_FUEL_CARDS_URL, ADMIN_FUEL_CARDS } from "../../util/Const";
import DocumentView from "../document"
import {  VALIDATE_FUEL_CARD_ID, VALIDATE_REQUIRED, VALIDATE_OBJECT_REQUIRED } from "../../util/ValidatorConst";
import FDetailComponent from "../../util/FDetailComponent";
import { isValid } from "../../util/Validator";
import FAutoComplete from "../widgets/FAutoComplete";

const styles = theme => ({
    root: {
        display: "flex",
        paddingBottom: theme.spacing(1),

    },
    padding: {
        marginTop: theme.spacing(4),
    },

    header: {
        background: '#f5f5f5'
    },
    button: {
        marginRight: theme.spacing(2),
    }
});

const PROPS = {
    errorData: {
    },
    errorMessage: {
        fuelCardSourceId: 'Invalid Fuel Card Number',
        fuelCardPin: 'Invalid Pin',
        company: 'No Service Provider Selected/Found  Under Carrier',
        driver: 'No Driver Selected/Found  Under Carrier',
        vehicle: 'No Vehicle Selected/Found  Under Carrier',
        carrier: 'Invalid Carrier'
    },
    touched: {
    },
    data: {
        fuelCardSourceId: '',
        fuelCardPin: '',
        company: {},
        driver: {},
        vehicle: {},
        carrier: {}
    },
    validators: {
        fuelCardSourceId: [VALIDATE_FUEL_CARD_ID],
        fuelCardPin: [VALIDATE_REQUIRED],
        company: [VALIDATE_OBJECT_REQUIRED],
        driver: [VALIDATE_OBJECT_REQUIRED],
        vehicle: [VALIDATE_OBJECT_REQUIRED],
        carrier: [VALIDATE_OBJECT_REQUIRED],
    }
}



class FuelCardDetail extends FDetailComponent {
    constructor(props) {
        super(props, API_FUEL_CARDS_URL, CHOICE_FUEL_CARD, PROPS)
    }
  
    getErrorData = () => {
        let errorData = {

            fuelCardSourceId: !isValid(this.state.data.fuelCardSourceId, this.state.validators.fuelCardSourceId),
            fuelCardPin: !isValid(this.state.data.fuelCardPin, this.state.validators.fuelCardPin),

            company: !isValid(this.state.data.company, this.state.validators.company),
            driver: !isValid(this.state.data.driver, this.state.validators.driver),
            vehicle: !isValid(this.state.data.vehicle, this.state.validators.vehicle),
            carrier: !isValid(this.state.data.carrier, this.state.validators.carrier)
        }
        return errorData
    }

    onCarrierChange = () => {
        this.updateValues('driver', {driver: ''})
        this.updateValues('vehicle', {vehicle: ''})
        this.updateValues('company', {company: ''})
    }
    
    getCurrentTitle = () => {
        let title = (this.state.data.driver && this.state.data.driver.prettyName &&
                    this.state.data.driver.prettyName + ' -  ' + 
                    this.state.data.fuelCardSourceId)  || ''
        title = 'Fuel Service: ' +  title
        this.props.updateCurrentTab(TAB_ADMIN)
        this.props.updateCurrentTitle && this.props.updateCurrentTitle(title)
        return title
    }


    render() {
        const { classes } = this.props;
        this.getCurrentTitle()

        return (
            <React.Fragment>
                <Grid container direction='column' justify='space-between' alignItems='stretch' spacing={3} >
                    <Grid item xs>
                        <FMessage error={this.props.crudServerError}
                            success={this.props.crudServerSuccess} />
                    </Grid>
                    <Grid container className={classes.root} spacing= {2} xs={12} >
                            <Grid item xs>
                                    <FAutoComplete
                                                url={API_CARRIERS_URL}
                                                name='carrier'
                                                // required
                                                hasError={this.state.errorData.carrier}
                                                helpMessage={this.state.errorMessage.carrier}
                                                label='Authority *'
                                                // queryParam='search'
                                                value={this.state.data.carrier}
                                                defaultValue={this.state.data.carrier}
                                                notifyParent={this.updateValues}
                                                disabled={!this.state.isNew}
                                                notifyDependency={this.onCarrierChange}
                                                />
                                </Grid>
                            <Grid item xs>
                                    <FAutoComplete
                                        url={API_DRIVERS_URL}
                                        // required
                                        // queryParam={`carrier=${this.state.data.carrier && this.state.data.carrier.id}`}
                                        hasCarrier={true}
                                        carrier={this.state.data.carrier}
                                        name='driver'
                                        hasError={this.state.errorData.driver}
                                        helpMessage={this.state.errorMessage.driver}
                                        label='Driver *'
                                        // queryParam='search'
                                        value={this.state.data.driver}
                                        defaultValue={this.state.data.driver}
                                        notifyParent={this.updateValues}
                                        // disabled={!this.state.isNew}
                                        />
                                   
                                </Grid>
                                <Grid item xs>
                            <FAutoComplete
                                url={API_VEHICLES_URL}
                                // required
                                queryParam={'vehicleType=TRUCK'}
                                hasCarrier={true}
                                carrier = {this.state.data.carrier}
                                name='vehicle'
                                hasError={this.state.errorData.vehicle}
                                helpMessage={this.state.errorMessage.vehicle}
                                label='Truck *'
                                // queryParam='search'
                                value={this.state.data.vehicle}
                                defaultValue={this.state.data.vehicle}
                                notifyParent={this.updateValues}
                                // disabled={!this.state.isNew}
                                />
                            </Grid>          
                            <Grid item xs>
                                    <FAutoComplete
                                        url={API_SERVICE_PROVIDER}
                                        // required
                                        queryParam={'companyType=FUEL_CARD_SERVICE_PROVIDER'}
                                        hasCarrier={true}
                                        carrier = {this.state.data.carrier}
                                        name='company'
                                        hasError={this.state.errorData.company}
                                        helpMessage={this.state.errorMessage.company}
                                        label='Fuel Card Service Provider *'
                                        // queryParam='search'
                                        value={this.state.data.company}
                                        defaultValue={this.state.data.company}
                                        notifyParent={this.updateValues}
                                        // disabled={!this.state.isNew}
                                />
                                 </Grid>                 
                         
                    </Grid>
                    <Grid container className={classes.root} spacing= {2} xs={11} >
                            
                            
                            <Grid item xs>
                               <FInput name='fuelCardSourceId' required
                                    value={this.state.data.fuelCardSourceId}
                                    hasError={this.state.errorData.fuelCardSourceId}
                                    helpMessage={this.state.errorMessage.fuelCardSourceId}
                                    label="Card Number" notifyParent={this.updateValues}
                                />
                            </Grid>

                            <Grid item xs>
                               <FInput name='fuelCardPin' required
                                    value={this.state.data.fuelCardPin}
                                    hasError={this.state.errorData.fuelCardPin}
                                    helpMessage={this.state.errorMessage.fuelCardPin}
                                    label="Pin" notifyParent={this.updateValues}
                                />
                            </Grid>
                        </Grid>
                            
                   
                    <Grid container className={classes.root} spacing= {2} xs={11} >
                            
                            <Grid item xs>
                                 <FInput
                                    name='desc' value={this.state.data.desc} multiline={true}
                                    value={this.state.data.desc}
                                    hasError={this.state.errorData.desc}
                                    helpMessage={this.state.errorMessage.desc}
                                    label="Description" notifyParent={this.updateValues} />
                            </Grid>
                    </Grid>     
                    <Grid item container xs={8} >
                            {this.getUpdateButton(this.props.profile, ADMIN_FUEL_CARDS)}
                        </Grid>
                    <Grid item xs className={classes.padding}>
                        {!this.state.isNew &&
                            <DocumentView
                                ownerId={this.props.match.params.id}
                                owner={this.state.data}
                                listUrl={API_DOCUMENTS_URL + `?&fuel_card=${this.props.match.params.id}`}
                                ownerType='fuel_card'
                                policy_id={ADMIN_FUEL_CARDS}
                                documentChoices={this.props.document_fuel_card_choices}
                            />}
                    </Grid>
                </Grid >
             </React.Fragment>
        );
    };
}


export default withStyles(styles)(connect(
    mapStateToProps, mapDispatchToProps)(FuelCardDetail))
