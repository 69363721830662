import React from "react";
import { connect } from "react-redux"
import { withStyles } from "@material-ui/core/styles";


import { Grid } from "@material-ui/core";
import FSelect from "../widgets/FSelect";
import FMessage from "../widgets/FMessage";
import FInput from "../widgets/FInput";
import { mapDispatchToProps, mapStateToProps } from "../../fredux/ActionMaker";
import {API_DOCUMENTS_URL, TAB_SAFETY_AND_COMPLIANCE, API_RESIDENT_HISTORY, CHOICE_RESIDENT_HISTORY, API_CARRIERS_URL, API_DRIVERS_URL, 
    AFETY_AND_COMPLINACE_EPLOYMENT_HISTORYS, 
    SAFETY_AND_COMPLINACE_RESIDENT_HISTORYS} from "../../util/Const";
import DocumentView from "../document"
import {VALIDATE_CARRIER_REQUIRED, VALIDATE_OBJECT_REQUIRED,  VALIDATE_REQUIRED } from "../../util/ValidatorConst";
import FDetailComponent from "../../util/FDetailComponent";
import FDate from "../widgets/FDate";
import { isValid } from "../../util/Validator";
import FAutoComplete from "../widgets/FAutoComplete";

const styles = theme => ({
    root: {
        display: "flex",

    },
    padding: {
        marginTop: theme.spacing(4),
    },

    header: {
        background: '#f5f5f5'
    },
    button: {
        marginRight: theme.spacing(2),
    }
});

const PROPS = {
    errorData: {
    },
    errorMessage: {
        residentStartDate: 'Invalid Date',
        residentEndDate: 'Invalid Date',
        residentVerificationMethod: 'Invalid Type',
        residentVerificationStatus: 'Invalid Data',
        residentVerificationAttemptCount: 'Invalid Dataaa',
        driver: 'No Driver Selected/Found  Under Carrier',
        carrier: 'Invalid Carrier',       
    },
    touched: {
    },
    data: {
        residentStartDate: '',
        residentEndDate: '',
        residentVerificationMethod: '',
        residentVerificationStatus:'',
        residentVerificationAttemptCount: '',
        driver: {},
        carrier: {}
    },
    validators: {
        residentStartDate: [VALIDATE_REQUIRED],
        residentEndDate: [VALIDATE_REQUIRED],
        residentVerificationMethod: [VALIDATE_REQUIRED],
        residentVerificationStatus:[VALIDATE_REQUIRED],
        residentVerificationAttemptCount: [VALIDATE_REQUIRED],
        driver: [VALIDATE_OBJECT_REQUIRED],
        carrier: [VALIDATE_CARRIER_REQUIRED],
    }
}



class ResidentHistoryDetail extends FDetailComponent {
    constructor(props) {
        super(props, API_RESIDENT_HISTORY, CHOICE_RESIDENT_HISTORY, PROPS)
    }
    
    isemploymentVerificationAttemptCountRequired = () => {
        return (this.state.data.residentVerificationMethod == 'POST_ACCIDENT');
    }

    getErrorData = () => {
        let errorData = {
            residentStartDate: !isValid(this.state.data.residentStartDate, this.state.validators.residentStartDate),
            residentEndDate: !isValid(this.state.data.residentEndDate, this.state.validators.residentEndDate),

            residentVerificationMethod: !isValid(this.state.data.residentVerificationMethod, this.state.validators.residentVerificationMethod),
            residentVerificationStatus: !isValid(this.state.data.residentVerificationStatus, this.state.validators.residentVerificationStatus),            

            // residentVerificationAttemptCount: !isValid(this.state.data.residentVerificationAttemptCount, this.state.validators.residentVerificationAttemptCount),
            driver: !isValid(this.state.data.driver, this.state.validators.driver),
            carrier: !isValid(this.state.data.carrier, this.state.validators.carrier)
        }
        return errorData
    }

    onCarrierChange = () => {
        this.updateValues('driver', {driver: ''})
    }

    getCurrentTitle = () => {
        let pName = (this.state.data.driver.prettyName && this.state.data.driver.prettyName + ' -  ' + this.state.data.residentVerificationMethod)  || ''

        let title = 'Resident Hisory: '+  pName
        this.props.updateCurrentTab(TAB_SAFETY_AND_COMPLIANCE)
        this.props.updateCurrentTitle && this.props.updateCurrentTitle(title)
        return title
    }
    
    render() {
        // let pName = (this.state.data.driver.prettyName && this.state.data.driver.prettyName + ' -  ' + this.state.data.residentVerificationMethod)  || ''
        this.getCurrentTitle()

        const { classes } = this.props;
        return (
            <React.Fragment>
                {/* <FTitle label={title}/> */}
                <Grid container direction='column' justify='space-between' alignItems='stretch' spacing={3} >
                    <Grid item xs>
                        <FMessage error={this.props.crudServerError}
                            success={this.props.crudServerSuccess} />
                    </Grid>
                    <Grid container className={classes.root} spacing= {2} xs={12} >
                            <Grid item xs>
                                    <FAutoComplete
                                        url={API_CARRIERS_URL}
                                        name='carrier'
                                        hasError={this.state.errorData.carrier}
                                        helpMessage={this.state.errorMessage.carrier}
                                        label='Authority'
                                        // queryParam='search'
                                        value={this.state.data.carrier}
                                        defaultValue={this.state.data.carrier}
                                        notifyParent={this.updateValues}
                                        disabled={!this.state.isNew}
                                        notifyDependency={this.onCarrierChange}

                                        />
                                   
                                <Grid item xs>
                                <FAutoComplete
                                        url={API_DRIVERS_URL}
                                        // queryParam={`carrier=${this.state.data.carrier && this.state.data.carrier.id}`}
                                        hasCarrier={true}
                                        carrier={this.state.data.carrier}
                                        name='driver'
                                        hasError={this.state.errorData.driver}
                                        helpMessage={this.state.errorMessage.driver}
                                        label='Driver'
                                        // queryParam='search'
                                        value={this.state.data.driver}
                                        defaultValue={this.state.data.driver}
                                        notifyParent={this.updateValues}
                                        // disabled={!this.state.isNew}
                                        />

                                 
                                </Grid>
            
                                <Grid item xs>
                                    <FSelect
                                        name='residentVerificationMethod'
                                        value={this.state.data.residentVerificationMethod}
                                        hasError={this.state.errorData.residentVerificationMethod}
                                        helpMessage={this.state.errorMessage.residentVerificationMethod}
                                        label='Resident Verification Method *'
                                        data={this.props.employmentVerificationMethodChoices}
                                        valueRef='prettyName'
                                        keyRef='prettyName'
                                        notifyParent={this.updateValues}
                                    />
                            </Grid>
                    </Grid>
                    <Grid container className={classes.root} spacing= {2} xs={12} >

                               <Grid item xs>
                                    <FDate
                                        name='residentStartDate'
                                        required={true}
                                        defaultValue={this.state.data.residentStartDate}
                                        hasError={this.state.errorData.residentStartDate}
                                        helpMessage={this.state.errorMessage.residentStartDate}
                                        label="Resident Start Date *"
                                        notifyParent={this.updateValues} />
                               
                                </Grid>
                                <Grid item xs>
                                    <FDate
                                        name='residentEndDate'
                                        required={true}
                                        defaultValue={this.state.data.residentEndDate}
                                        hasError={this.state.errorData.residentEndDate}
                                        helpMessage={this.state.errorMessage.residentEndDate}
                                        label="Resident End Date  "
                                        notifyParent={this.updateValues} />
                               
                                </Grid>
                           
                           
                           
                                <Grid item xs={3}>
                                    <FInput
                                        name='residentVerificationAttemptCount'
                                        required={true}
                                        type='number'
                                        value={this.state.data.residentVerificationAttemptCount}
                                        hasError={this.state.errorData.residentVerificationAttemptCount}
                                        helpMessage={this.state.errorMessage.residentVerificationAttemptCount}
                                        label="Resident History verification Attempts "
                                        notifyParent={this.updateValues} />
                                </Grid>
                            <Grid item xs>
                                <FSelect
                                    name='residentVerificationStatus'
                                    value={this.state.data.residentVerificationStatus}
                                    hasError={this.state.errorData.residentVerificationStatus}
                                    helpMessage={this.state.errorMessage.residentVerificationStatus}
                                    label='Verification Status'
                                    data={this.props.employmentVerificationStatusChoices}
                                    valueRef='prettyName'
                                    keyRef='prettyName'
                                    notifyParent={this.updateValues}
                                />
                            </Grid>
                            
                           
                    </Grid>     
                   
                    <Grid container className={classes.root} spacing= {2} xs={12} >
                            
                           
                            <Grid item xs>
                                 <FInput
                                    name='desc' value={this.state.data.desc} multiline={true}
                                    value={this.state.data.desc}
                                    hasError={this.state.errorData.desc}
                                    helpMessage={this.state.errorMessage.desc}
                                    label="Description" notifyParent={this.updateValues} />
                            </Grid>
                    </Grid>     
                    <Grid item container xs={8} >
                            {this.getUpdateButton(this.props.profile, SAFETY_AND_COMPLINACE_RESIDENT_HISTORYS)}
                    </Grid>
                   
                    <Grid item xs className={classes.padding}>
                        {!this.state.isNew &&
                            <DocumentView
                                ownerId={this.props.match.params.id}
                                owner={this.state.data}
                                listUrl={API_DOCUMENTS_URL + `?&resident_history=${this.props.match.params.id}`}
                                ownerType='resident_history'
                                policy_id={SAFETY_AND_COMPLINACE_RESIDENT_HISTORYS}
                                documentChoices={this.props.document_resident_history_choices}
                            />}
                    </Grid>
                </Grid >
                </Grid>
             </React.Fragment>
        );
    };
}


export default withStyles(styles)(connect(
    mapStateToProps, mapDispatchToProps)(ResidentHistoryDetail))
