import React from 'react';
import FRouterLink, { FRouterAddLink } from '../widgets/FRouterLink';
import {ROUTE_DRIVER_URL, ROUTE_CARRIERS_URL, API_TRIPS_URL, CHOICE_TRIP, ROUTE_TRIP_URL, ROUTE_VEHICLE_URL, TAB_FLEET, FLEET_TRIPS } from '../../util/Const';
import FListComponent from '../../util/FListComponent';
import { connect } from "react-redux"
import { mapStateToProps, mapDispatchToProps } from '../../fredux/ActionMaker';
import FMaterialTable from '../widgets/FMaterialTable';
import FTableAction, { ACTION_TABLE_DELETE_ROW } from '../widgets/FTableAction';
import { isProfileDispatcher } from '../../util/ACL';
import { getPrettyDate } from '../../util';
import BlockIcon from '@material-ui/icons/Block';


class Trips extends FListComponent {

  constructor(props) {
    super(props, API_TRIPS_URL, CHOICE_TRIP)
    this.currentTitle = 'Trips ( Mileage )'
    this.currentTab = TAB_FLEET
    this.routeUrl = ROUTE_TRIP_URL
    this.policyID = FLEET_TRIPS
    this.includeStateAction = false
  }

  // getActions = (rowData) => {
  //   let actions = []

  //   actions.push(ACTION_TABLE_DELETE_ROW)
  //   return actions
  // }

  render() {
    return (
      <div>
       
        <FMaterialTable
          tableRef={this.tableRef}
          columns={[
            
            {
              title: 'Trip ID', 
              field: 'prettyName',
              render: rowData => {
                return (
                  <React.Fragment>
                    <FRouterLink editIcon={true} label={rowData.prettyName} to={ROUTE_TRIP_URL + rowData.id} />
                  </React.Fragment>
                )
              }
            },
            // {
            //   title: 'Driver', field: 'driver.prettyName',
            //   render: rowData => {
            //     return (
            //       <React.Fragment>
            //         <FRouterLink label={rowData.driver.prettyName} 
            //            to={ROUTE_DRIVER_URL + rowData.driver.id}
            //            disabled={isProfileDispatcher(this.props.profile)}/>
            //       </React.Fragment>
            //     )
            //   }
            // },
            {
              title: 'Carrier', 
              render: this.getCarrierLink
            },
            {
              title: 'Driver', 
              render: this.getDriverLink
            },
            {
              title: 'Vehicle', 
              render: this.getVehicleLink
            },
            
            // {
            //   title: 'Vehicle', field: 'vehicle.prettyName',
            //   render: rowData => {
            //     return (
            //       <React.Fragment>
            //         <FRouterLink label={rowData.vehicle.prettyName} 
            //            to={ROUTE_VEHICLE_URL + rowData.vehicle.id}
            //            disabled={isProfileDispatcher(this.props.profile)}/>
            //       </React.Fragment>
            //     )
            //   }
            // },
            // {
            //   title: 'Carrier', 
            //   field: 'carrier.prettyName',
            //   render: rowData => {
            //     return (
            //       <React.Fragment>
            //         <FRouterLink label={rowData.carrier.prettyName} to={ROUTE_CARRIERS_URL + rowData.carrier.id} />
            //       </React.Fragment>
            //     )
            //   }
            // },
           
            { title: 'Date', render: rowData => getPrettyDate(rowData.tripDate) },

            { title: 'Jurisdiction', field: 'tripJurisdiction' },
            { title: 'Total Distance (Mi)', field: 'tripDistance' },   
     
            // {
            //   title: 'Actions', 
            //   render: rowData => {
            //     return (
            //       <React.Fragment>
            //         <FTableAction 
            //             actions={this.getActions(rowData)}
            //             onTableAction = {this.onTableAction}
            //             rowData={rowData}
            //              />
            //       </React.Fragment>
            //     )
            //   }
            // },
            {
              title: 'Actions', 
              render: rowData => {
                let actions  = this.getActions(rowData, FLEET_TRIPS)

                return (
                  <React.Fragment>
                    {!!actions.length && <FTableAction 
                        actions={actions}
                        onTableAction = {this.onTableAction}
                        rowData={rowData}
                         />
                    }
                    {!!!actions.length && <BlockIcon color="disabled" />}
                  </React.Fragment>
                )
              }
            },
            
          ]}
          actions={
            [
              // {
              //   icon: () => (
              //     <FRouterAddLink to={ROUTE_TRIP_URL + 'add'} color='secondary' />
              //   ),
              //   isFreeAction: true
              // }
              this.getAddNewModelActions()
            ]}
          options = {{
              pageSize: this.state.pageSize,
              pageSizeOptions: this.state.pageSizeOptions,
              actionsColumnIndex: -1
           }}
          data={this.fetchData}
          title="Trips ( Mileage)"
          listErrCode={this.state.listErrCode}
        />

      </div>
    );
  }
}

export default connect(
  mapStateToProps, mapDispatchToProps)(Trips)
