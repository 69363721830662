import React from 'react'
// import FleetReportHeader from './FleetFilter';
// import { TAB_REPORT, API_REPORT_FLEET } from '../../../util/Const';
// import { connect } from "react-redux"
// import { withStyles } from "@material-ui/core/styles";
// import { mapDispatchToProps, mapStateToProps } from "../../../fredux/ActionMaker";
// import { getPrettyDate } from '../../../util';
// import { Grid } from "@material-ui/core"
// import FButton from "../../widgets/FButton";
// import Fleets from './Fleets';
import { GET, DOWNLOAD_FILE, handleApiError } from '../../util/FAPI';
import { getErrroMessage } from '../widgets/FMessage';
import { PAGE_SIZE, PAGE_SIZE_OPTIONS } from '../../util/Const';


export default class BaseReport extends React.Component {
  tableRef = React.createRef();


  constructor(props, addProps) {
    super(props)

    this.state = {
      page: 0, 
      pageSize: PAGE_SIZE,
      pageSizeOptions: PAGE_SIZE_OPTIONS,
      filter: '',
      listErrCode: undefined,
      ...addProps
    }
  }

  updateValues = (name, obj) => {
    this.setState(prevState => {
      let newState = {
        ...prevState,
        data: {
          ...prevState.data, ...obj
        }
      }
      return newState
    }, () => {
      this.updateFilter()
    })
  }
  
  refreshFilter = (urlWithFilter) => {
    const that = this
    this.setState({filter: urlWithFilter, page: 0}, ()=>{
      that.tableRef.current && that.tableRef.current.onQueryChange({page: 0});
    })
  }
  
  componentDidMount() {
    if (!!this.currentTitle){
      this.props.updateCurrentTitle && this.props.updateCurrentTitle(this.currentTitle)
    }
    if (!!this.currentTab){
      this.props.updateCurrentTab(this.currentTab)
    }
  }
  
  getCurrentTitle = (title, tab) => {
    this.props.updateCurrentTab(tab)
    this.props.updateCurrentTitle && this.props.updateCurrentTitle(title)
  }
  
  onDownload = (evt, format) => {

    let url = this.state.downloadUrl + this.state.filter
    if (!!format) {
      url = url + format
    }
    const d = new Date();
    const fileName = `${d.getFullYear()}_${d.getMonth()}_${d.getHours()}_${d.getSeconds()}_${this.state.reportFileName || 'report'}`
    DOWNLOAD_FILE(url, fileName)  
  }

  
   
  fetchDataq = (query) => {
    this.setState({page: 0}, ()=>{
      return this._fetchData(query)
    })
  }
 
  fetchData = (query) => {
    query = query || {page: this.state.page}
    return new Promise((resolve, reject) => {
        let url = ''
        url = this.state.listUrl
        if (!url.includes('?')) {
          url += '?'
        }
        let page = query.page + 1
        url += `${this.state.filter}&per_page=${this.state.pageSize}&page=${page}`
      const that = this
      GET(url)                
        .then(response => {
         
          let page =  parseInt(url.substr(url.lastIndexOf('=') + 1));          
          const res = response.data;  
          resolve({
            data: res.results,
            page: page - 1,  
            totalCount: res.count,
          })
        }).catch(error => {
          //  err = getErrroMessage(err)
          //  that.props.updateServerError && that.props.updateServerError({ msg: err })
          let err = handleApiError(that.props, error)
          if (!!err.errCode) {
            this.setState({listErrCode :err.errCode})
          }
          resolve({
            data: [],
            page: 0,  
            totalCount: 0,
          })
        })
    })
  }
}


// export default withStyles(styles)(connect(
//   mapStateToProps, mapDispatchToProps)(FleetReport))
