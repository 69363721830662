import React, { Component } from 'react';
import { FormControlLabel, Checkbox } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";

const style = theme => ({
  root: {
     margin: theme.spacing(1),
  }
});

class FCheckBox extends Component {

  onChange = (evt) => {
    const checked = evt.target.checked
    this.props.notifyParent(this.props.name, {[this.props.name]:checked})
  }

  render() {
    const { classes } = this.props
    return (

      <div className={classes.root} style={{color: this.props.hasError? 'red': ''}}>
        <FormControlLabel
          control={
            <Checkbox
              disabled={this.props.disabled}
              name={this.props.name}
              labelPlacement={"top"}
              checked={!!this.props.value}
              value={!!this.props.value}
              onChange={this.onChange}
              />
          }
          label={this.props.label}
        />
      </div>);
  }
}

export default withStyles(style)(FCheckBox)