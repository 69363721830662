import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux"
import { withStyles } from "@material-ui/core/styles";


import { Grid } from "@material-ui/core";
import FSelect from "../widgets/FSelect";
import FMessage from "../widgets/FMessage";
import FInput from "../widgets/FInput";
import { mapDispatchToProps, mapStateToProps } from "../../fredux/ActionMaker";
import { API_CUSTOMER_URL,  API_DOCUMENTS_URL,  CHOICE_CUSTOMER, API_CARRIERS_URL, TAB_FLEET, FLEET_CUSTOMERS } from "../../util/Const";
import DocumentView from "../document"
import { isValid } from "../../util/Validator";
import { VALIDATE_CARRIER_REQUIRED, VALIDATE_REQUIRED, VALIDATE_EMAIL, VALIDATE_PHONE_NUMBER } from "../../util/ValidatorConst";
import FDetailComponent from "../../util/FDetailComponent";
import FAutoComplete from "../widgets/FAutoComplete";


const styles = theme => ({
    root: {
        // display: "flex",

    },
    padding: {
        marginTop: theme.spacing(4),
    },

    header: {
        background: '#f5f5f5'
    },
    button: {
        marginRight: theme.spacing(2),
    },
    dropDown: {
        marginLeft: theme.spacing(2),
        
      },
});

const PROPS = {
    errorData: {
    },
    errorMessage: {
        carrier:"Invalid Carrier",
        customerName: 'Invalid customer Name',
        customerEmail: 'Invalid Email',
        customerDOT: 'Invalid DOT',
        customerMC: 'Invalid MC',
        customerPhoneNumber: 'Invalid Phone',
        // status: 'Can\'t be empty',
        customerType: 'Invalid customer Type',
        customerContactName: 'Invalid Name',
        customerContactPhoneNumber: 'Invalid Phone Number',
        customerContactEmail: 'Invalid Email',
        customerInvoiceToEmail: 'Invalid Email',
    },
    touched: {},
    data: {
        carrier: {},
        customerName: '',
        customerEmail: '',
        customerDOT: '',
        customerMC: '',
        customerPhoneNumber: '',
        // status: '',
        customerType: '',
        customerType: '',
        customerContactName: '',
        customerContactPhoneNumber: '',
        customerContactEmail: '',
        customerInvoiceToEmail: '',

    },
    validators: {
        carrier: [VALIDATE_CARRIER_REQUIRED],
        customerName: [VALIDATE_REQUIRED],
        customerEmail: [ VALIDATE_EMAIL],
        customerDOT: [],
        customerMC: [],
        customerPhoneNumber: [VALIDATE_PHONE_NUMBER],
        customerPhysicalAddress: [VALIDATE_REQUIRED],
        // status: [VALIDATE_REQUIRED],
        customerType: [VALIDATE_REQUIRED],
        customerContactName: [],
        customerContactPhoneNumber: [VALIDATE_PHONE_NUMBER],
        customerContactEmail: [VALIDATE_EMAIL],
        customerInvoiceToEmail: [VALIDATE_EMAIL],
    }
}

class CustomersDetial extends FDetailComponent {
    constructor(props) {
        super(props, API_CUSTOMER_URL, CHOICE_CUSTOMER, PROPS)
        this.setState({ showDOTInfo: this.showDOTInfo() })
    }

    getErrorData = () => {
        let errorData = {
            carrier: !isValid(this.state.data.carrier, this.state.validators.carrier),
            customerName: !isValid(this.state.data.customerName, this.state.validators.customerName),
            customerEmail: !isValid(this.state.data.customerEmail, this.state.validators.customerEmail),
            customerDOT: !isValid(this.state.data.customerDOT, this.state.validators.customerDOT),
            customerMC:  !isValid(this.state.data.customerMC, this.state.validators.customerMC),
            customerPhoneNumber: !isValid(this.state.data.customerPhoneNumber, this.state.validators.customerPhoneNumber),
            customerPhysicalAddress: !isValid(this.state.data.customerPhysicalAddress, this.state.validators.customerPhysicalAddress),
            customerType: !isValid(this.state.data.customerType, this.state.validators.customerType),
        }
        return errorData
    }

    showDOTInfo = () => {
        let customerType = this.state.data.customerType
        if (customerType == 'BROKER' || customerType == 'CARRIER') {
            return true
        } else {
            return false
        }
    }

    getCurrentTitle = () => {
        let title = 'Customer -  ' + this.state.data.customerName
        this.props.updateCurrentTab(TAB_FLEET)
        this.props.updateCurrentTitle && this.props.updateCurrentTitle(title)
    }

    render() {
        const { classes } = this.props;
        this.getCurrentTitle()

        return (
            <React.Fragment>
                {/* <FTitle label={'Customer -  ' + this.state.data.customerName} /> */}
                <Grid container direction='column' justify='space-between' alignItems='stretch' spacing={4} >
                    <Grid item xs>
                        <FMessage error={this.props.crudServerError}
                            success={this.props.crudServerSuccess} />
                    </Grid>
                    <Grid container  spacing= {2} xs={12} >
                           <Grid  item xs className={classes.dropDown} >
                               <FAutoComplete
                                        url={API_CARRIERS_URL}
                                        name='carrier'
                                        hasError={this.state.errorData.carrier}
                                        helpMessage={this.state.errorMessage.carrier}
                                        label='Authority'
                                        // queryParam='search'
                                        value={this.state.data.carrier}
                                        defaultValue={this.state.data.carrier}
                                        notifyParent={this.updateValues}
                                        disabled={!this.state.isNew}
                                        notifyDependency={this.onCarrierChange}
                                        />
                            </Grid>
                            <Grid item xs className={classes.dropDown}>
                                <FSelect
                                    name='customerType'
                                    value={this.state.data.customerType}
                                    hasError={this.state.errorData.customerType}
                                    helpMessage={this.state.errorMessage.customerType}
                                    label='Customer Type'
                                    data={this.props.customerTypeChoice}
                                    valueRef='prettyName'
                                    keyRef='prettyName'
                                    notifyParent={this.updateValues}
                                />
                            </Grid>
                            <Grid item xs>
                                <FInput name='customerName' required
                                    value={this.state.data.customerName}
                                    hasError={this.state.errorData.customerName}
                                    helpMessage={this.state.errorMessage.customerName}
                                    label="Customer / Company Name"
                                    notifyParent={this.updateValues}
                                />
                            </Grid>
                           

                    </Grid>
                    <Grid container className={classes.root} spacing= {4} xs={12} > 
                            <Grid item xs>
                                <FInput name='customerEmail' required
                                    value={this.state.data.customerEmail}
                                    hasError={this.state.errorData.customerEmail}
                                    helpMessage={this.state.errorMessage.customerEmail}
                                    label="Company Email" notifyParent={this.updateValues}
                                />
                            </Grid>
                            <Grid item xs>
                                <FInput name='customerPhoneNumber' required
                                    value={this.state.data.customerPhoneNumber}
                                    hasError={this.state.errorData.customerPhoneNumber}
                                    helpMessage={this.state.errorMessage.customerPhoneNumber}
                                    label="Company Phone Number" notifyParent={this.updateValues}
                                />
                            </Grid>
                            <Grid item xs>
                                <FInput name='customerInvoiceToEmail'
                                    value={this.state.data.customerInvoiceToEmail}
                                    hasError={this.state.errorData.customerInvoiceToEmail}
                                    helpMessage={this.state.errorMessage.customerInvoiceToEmail}
                                    label="Invoicing Email" notifyParent={this.updateValues}
                                />
                            </Grid>
                            
                           
                            
                           
                    </Grid>
                    <Grid container className={classes.root} spacing= {4} xs={12} >
                          <Grid item xs>
                                    <FInput name='customerPhysicalAddress' 
                                        xs
                                        value={this.state.data.customerPhysicalAddress}
                                        hasError={this.state.errorData.customerPhysicalAddress}
                                        helpMessage={this.state.errorMessage.customerPhysicalAddress}
                                        label="Mailing Address (address, city, State, zipcode)" notifyParent={this.updateValues}
                                        notifyParent={this.updateValues}
                                    />
                                </Grid> 
                           <Grid item xs>
                                <FInput name='customerBillingAddress' 
                                    xs required
                                    value={this.state.data.customerBillingAddress}
                                    hasError={this.state.errorData.customerBillingAddress}
                                    helpMessage={this.state.errorMessage.customerBillingAddress}
                                    label="Billing Address" notifyParent={this.updateValues}
                                    notifyParent={this.updateValues}
                                />
                            </Grid>
                            
                            <Grid item xs hidden={!(this.state.data.customerType == 'BROKER' || this.state.data.customerType == 'CARRIER')}>
                                <FInput name='customerDOT'
                                    value={this.state.data.customerDOT}
                                    hasError={this.state.errorData.customerDOT}
                                    helpMessage={this.state.errorMessage.customerDOT}
                                    label="DOT" notifyParent={this.updateValues}
                                />
                            </Grid>
                            <Grid item xs hidden={!(this.state.data.customerType == 'BROKER' || this.state.data.customerType == 'CARRIER')}>
                                <FInput name='customerMC'
                                    value={this.state.data.customerMC}
                                    hasError={this.state.errorData.customerMC}
                                    helpMessage={this.state.errorMessage.customerMC}
                                    label="MC" notifyParent={this.updateValues}
                                />
                            </Grid>
                    </Grid>
                    
                    <Grid container className={classes.root} spacing= {4} xs={12} > 
                            <Grid item xs>
                                <FInput name='customerContactName' 
                                    xs 
                                    value={this.state.data.customerContactName}
                                    hasError={this.state.errorData.customerContactName}
                                    helpMessage={this.state.errorMessage.customerContactName}
                                    label=" Agent Name" notifyParent={this.updateValues}
                                />
                            </Grid>

                           <Grid item xs>
                                <FInput name='customerContactEmail'
                                    value={this.state.data.customerContactEmail}
                                    hasError={this.state.errorData.customerContactEmail}
                                    helpMessage={this.state.errorMessage.customerContactEmail}
                                    label="Agent Email" notifyParent={this.updateValues}
                                />
                            </Grid>
                            <Grid item xs>
                                <FInput name='customerContactPhoneNumber'
                                    value={this.state.data.customerContactPhoneNumber}
                                    hasError={this.state.errorData.customerContactPhoneNumber}
                                    helpMessage={this.state.errorMessage.customerContactPhoneNumber}
                                    label="Agent Phone Number" notifyParent={this.updateValues}
                                />
                            </Grid>

                    </Grid>
                    <Grid  container spacing={2} xs={12}>
                            <Grid item xs>
                                <FInput
                                    name='desc' value={this.state.data.desc} multiline={true}
                                    value={this.state.data.desc}
                                    hasError={this.state.errorData.desc}
                                    helpMessage={this.state.errorMessage.desc}
                                    label="Description" notifyParent={this.updateValues} />
                            </Grid>
                    </Grid>
                    <Grid item container xs={8} >
                            {this.getUpdateButton(this.props.profile, FLEET_CUSTOMERS)}
                    </Grid>
                    {/* <Grid container  spacing= {4} xs={12} >
                        <Grid item container xs={8} >
                            <FButton color='primary'
                                label={this.state.isNew ? 'Save' : 'Update'}
                                loading={this.state.loading}
                                onClick={this.onSaveData}
                            />
                            <Grid item style={{ paddingTop: '9px' }}>
                                <FRouteBackLink variant='outlined' color='secondary' className={'backButton'} />
                            </Grid>
                        </Grid>
                    </Grid> */}
                </Grid>
                <Grid item xs className={classes.padding}>
                    {!this.state.isNew &&
                        <DocumentView
                            ownerId={this.props.match.params.id}
                            owner={this.state.data}
                            listUrl={API_DOCUMENTS_URL + `?&customer=${this.props.match.params.id}`}
                            ownerType='customer'
                            policy_id={FLEET_CUSTOMERS}
                       />}
                </Grid>

            </React.Fragment >
        );
    };
}

CustomersDetial.propTypes = {
    classes: PropTypes.object.isRequired
};

export default withStyles(styles)(connect(
    mapStateToProps, mapDispatchToProps)(CustomersDetial))
