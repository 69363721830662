import React, { Component } from 'react';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import { Link } from 'react-router-dom'
import { withStyles } from '@material-ui/core/styles'

import ListItemText from '@material-ui/core/ListItemText';
import { mapDispatchToProps, mapStateToProps } from "../../fredux/ActionMaker";
import * as CONST from "../../util/Const";
import { connect } from 'react-redux'
import {withRouter} from 'react-router-dom';



const style = theme => ({
  active: {
    // color: '#3f51b5',
    color: 'blue',
    backgroundColor: 'white',
  },
  inActive: {
    backgroundColor: 'rgba(255, 255, 255, 0.12)',
    // color: 'red',
  }
});

class FListItem extends Component {

  getActiveColor = () => {
    let pathName = this.props.location && this.props.location.pathname
    if (pathName.includes(this.props.to)){
       return (this.props.classes.active)
     }
     return (this.props.classes.inActive)
  }

  render() {
    const className = this.getActiveColor()
    if (!!this.props.disabled) {
      return ''
    }
    return (
       <ListItem 
          disabled={!!this.props.disabled}
          component={Link} to={this.props.to} 
          className={className}
          button>
          <ListItemIcon>
              {this.props.children}
          </ListItemIcon>
          <ListItemText primary={this.props.label} />
      </ListItem>
      );
  }
}



export default withRouter(withStyles(style)(
  connect(
    mapStateToProps,
    mapDispatchToProps)(FListItem))
)

// export default withStyles(style)(connect(
//   mapStateToProps, mapDispatchToProps)(FListItem))

// export default withStyles(style)(FListItem)