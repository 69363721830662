import React from 'react';
import { roundDecimal, getPrettyDate } from '../../../util';
import FMaterialTable from '../../widgets/FMaterialTable';

export default class Fleets extends React.Component {
  constructor(props) {
    super(props)
  }
  

  render() {

    return (
      <div>

        <FMaterialTable
          tableRef={this.props.tableRef}
          columns={[
            {
              title: 'Reference #', field: 'fleetLoadNumber',
              // render: rowData => {
              //   return (
              //     <React.Fragment>
              //       <FRouterLink label={rowData.fleetLoadNumber} to={ROUTE_FLEET_URL + rowData.id} />
              //     </React.Fragment>
              //   )
              // }
            },
            // {
            //   title: 'Driver', field: 'driver.prettyName',
            //   render: rowData => {
            //     return (
            //       <React.Fragment>
            //         <FRouterLink label={rowData.driver.prettyName} 
            //            to={ROUTE_DRIVER_URL + rowData.driver.id}
            //            disabled={isProfileDispatcher(this.props.profile)}/>
            //       </React.Fragment>
            //     )
            //   }
            // },
            // {
            //   title: 'Carrier', field: 'carrier.prettyName',
            //   render: rowData => {

            //     return (
            //       <React.Fragment>
            //         <FRouterLink label={rowData.carrier.prettyName} to={ROUTE_CARRIERS_URL + rowData.carrier.id}
            //           disabled={ isProfileDispatcher(this.props.profile)}/>
            //       </React.Fragment>
            //     )
            //   }
            // },
            // {
            //   title: 'Customer',
            //   field: 'prettyName',
            //   render: rowData => {
            //     if (!!!rowData.customer){
            //       return '';
            //     }
            //     return (
            //       <React.Fragment>
            //         <FRouterLink label={rowData.customer.prettyName} to={ROUTE_CUSTOMER_URL + rowData.customer.id} />
            //       </React.Fragment>
            //     )
            //   }
            // },
            
            { title: 'Pickup Date', field: 'fleetPickUpDate',
             render: rowData => getPrettyDate(rowData.fleetPickUpDate) },

            // {
            //   title: 'Trip Date', render: rowData => {
            //     return (
            //       <React.Fragment>
            //          <b>{this.getPrettyDate(rowData.fleetPickUpDate)}</b> to <b>{this.getPrettyDate(rowData.fleetDeliveryDate)}</b>
            //       </React.Fragment>
            //     )
            //   }
            // },
            {
              title: 'Trip Location', field: 'fleetPickupLocation',
              render: rowData => {
                return (
                  <React.Fragment>
                     <b>{rowData.fleetPickupLocation}</b> to <b>{rowData.fleetDeliveryLocation}</b>
                  </React.Fragment>
                )
              }
            },
            
            // { title: 'Pickup Location', field: 'fleetPickupLocation' },
            // { title: 'Delivery Date', render: rowData => this.getPrettyDate(rowData.fleetDeliveryDate) },
            // { title: 'Delivery Location', field: 'fleetDeliveryLocation' },

            // {
            //   title: 'Vehicle', hidden: true, 
            //   field: 'fleetVehicle',
            //   render: rowData => {
            //     return (
            //       <React.Fragment>
            //         <FRouterLink label={rowData.fleetVehicle} to={ROUTE_VEHICLE_URL + rowData.fleetVehicle} />
            //       </React.Fragment>
            //     )
            //   }
            // },
            // {
            //   title: 'Dispatcher', hidden: true, render: rowData => {
            //     return (
            //       <React.Fragment>
            //         <FRouterLink label={rowData.fleetDispatcher} to={ROUTE_DISPATCHER_URL + rowData.fleetDispatcher} />
            //       </React.Fragment>
            //     )
            //   }
            // },
            { title: 'Lumper Fee', field: 'fleetLumperFee', render: rowData => roundDecimal(rowData.fleetLumperFee) },
            { title: 'Load Fee', field: 'fleetLoadFee', render: rowData => roundDecimal(rowData.fleetLoadFee) },
            { title: 'Detantion Fee', field: 'fleetDetantionFee', render: rowData => roundDecimal(rowData.fleetDetantionFee) },
            { title: 'No Used (TONU)', field: 'fleetNoServiceFee', render: rowData => roundDecimal(rowData.fleetNoServiceFee) },
            { title: 'Mileage', field: 'fleetDistance', render: rowData => roundDecimal(rowData.fleetDistance, 1) },

            
            // {
            //   title: 'Confirmation / BOL',
            //   render: rowData => {
            //     return (
            //       <React.Fragment>
            //         {rowData.fleetConfirmation && <Link href={rowData.fleetConfirmation} target="_blank">Confirmation</Link>}
            //         &nbsp; &nbsp; | &nbsp; &nbsp;
            //         {rowData.fleetBOL && <Link href={rowData.fleetBOL} target="_blank">BOL</Link>}
            //       </React.Fragment>
            //     )
            //   }
            // },
            // { title: 'Fleet Status', field: 'status' },
            // {
            //   title: 'Actions', 
            //   render: rowData => {
            //     return (
            //       <React.Fragment>
            //         <FTableAction 
            //             actions={this.getActions(rowData)}
            //             onTableAction = {this.onTableAction}
            //             rowData={rowData}
            //              />
            //       </React.Fragment>
            //     )
            //   }
            // },
          ]}
          // actions={
          //   [
          //     {
          //       icon: () => (
          //         <FRouterAddLink to={ROUTE_FLEET_URL + 'add'} color='secondary' />
          //       ),
          //       isFreeAction: true
          //     }
          //   ]} 
          options = {{
              pageSize: this.props.pstate.pageSize,
              pageSizeOptions: this.props.pstate.pageSizeOptions,
              actionsColumnIndex: -1,
              search: false
           }}
          data={this.props.fetchData}
          listErrCode={this.props.listErrCode}
          // title="Fleets"
          
          // components={{
          //   Toolbar: props => (
          //       <div style={{ backgroundColor: '#e8eaf5' }}>
          //           <MTableToolbar {...props} />
          //       </div>
          //   )
          // }}
        />

      </div>
    );
  }
}


// export default connect(
//   mapStateToProps, mapDispatchToProps)(Fleets)
