import React, { Component } from 'react';
import Button from '@material-ui/core/Button';
import { withStyles, CircularProgress, Grid } from '@material-ui/core';
import MaterialTable, { MTableToolbar, MaterialTableProps } from 'material-table';
import { mapStateToProps, mapDispatchToProps } from '../../fredux/ActionMaker';
import { connect } from "react-redux"
import FMessage from './FMessage';
import { TablePagination, TablePaginationProps } from '@material-ui/core';

//TODO (Estifanos) Patch Remove this once patch for pagination material table is integrated (version "^1.69.2")
// This patch fixes the pagination button not working issue

function PatchedPagination(props: TablePaginationProps) {
  const {
    ActionsComponent,
    onChangePage,
    onChangeRowsPerPage,
    ...tablePaginationProps
  } = props;

  return (
    <TablePagination
      {...tablePaginationProps}
      // @ts-expect-error onChangePage was renamed to onPageChange
      onPageChange={onChangePage}
      onRowsPerPageChange={onChangeRowsPerPage}
      ActionsComponent={(subprops) => {
        const { onPageChange, ...actionsComponentProps } = subprops;
        return (
          // @ts-expect-error ActionsComponent is provided by material-table
          <ActionsComponent
            {...actionsComponentProps}
            onChangePage={onPageChange}
          />
        );
      }}
    />
  );
}



const style = theme => ({
  root: {
    margin: theme.spacing(1),
    width: '100%',
    borderTopWidth: 1, 
    borderColor: 'red',
    borderStyle: 'solid'
  }

});

const getOptions = function(props) {
  const options = props.options || {}
  const defOptions = {
      pageSize: props.pageSize || 10,
      pageSizeOptions: props.pageSizeOptions || [10]
  }
  return {...options, ...defOptions}
}

class FMaterialTable extends Component {
  
  getErrorMessage = function() {
    if(this.props.listErrCode == 403) {
      return "You don\'t have enough permission!" 
      
    }else {
      return "No records to display"
    }
  }
  render() { 
    const { classes, columns, listUrl, pageSize, pageSizeOptions, title, showTitle,  data, actions } = this.props
    let options = this.props.options
    options.sorting = false
    options.rowStyle = {
      height: '40px !important'  // use !important to override other styles
    };
    return (
      <div className="form-group">
       
         {/* {this.props.listErrCode != 403 && 
             <Grid item xs>
                <FMessage error={this.props.crudServerError}/>
             </Grid>
         } */}
      <MaterialTable
          className={classes.root}
          tableRef={this.props.tableRef}
          columns={columns}
          actions= {actions}
          options = {options}
          data={data}
          title={!!this.props.showTitle && title}
          isLoading = {this.isLoading || this.props.isLoading}
          components={{
            Toolbar: props => (
                <div style={{ backgroundColor: 'white' }}>
                    <MTableToolbar {...props} />
                </div>
            ),
            Pagination: PatchedPagination,
          }}

          localization={{
            body: {
              emptyDataSourceMessage: this.getErrorMessage(),
             }
          }}
        />
        </div>
    )}
}


export class FMaterialSimpleTable extends Component {
  
  getErrorMessage = function() {
    if(this.props.listErrCode == 403) {
      return "You don\'t have enough permission!" 
      
    }else {
      return "No records to display"
    }
  }
  render() { 
    const { classes, columns, listUrl, pageSize, pageSizeOptions, title, showTitle,  data, actions } = this.props
    // let options = this.props.options
    // options.sorting = false
    return (
      <div className="form-group">
       
         {/* {this.props.listErrCode != 403 && 
             <Grid item xs>
                <FMessage error={this.props.crudServerError}/>
             </Grid>
         } */}
      <MaterialTable
          // className={classes.root}
          // tableRef={this.props.tableRef}
          columns={columns}
          actions= {actions}
          options = {{
            search: true,
            showTitle: true,
            toolbar: true,
            paging: true,
            sorting: false,
            // maxBodyHeight: 400,
            pageSize:  this.props.pageSize || 3,
            pageSizeOptions: this.props.pageSizeOptions || [3],
            // headerStyle: {
            //   backgroundColor: '#F0F8FF',
            //   color: '#000'
            // },
            actionsColumnIndex: -1

         }}
          data={data}
          title={!!this.props.title && title}
          isLoading = {this.isLoading || this.props.isLoading}
          components={{
            Toolbar: props => (
                <div style={{ backgroundColor: '#F0F8FF' }}>
                    <MTableToolbar {...props} />
                </div>
            ),
            Pagination: PatchedPagination,
          }}

          localization={{
            body: {
              emptyDataSourceMessage: this.getErrorMessage(),
             }
          }}
        />
        </div>
    )}
}


export default withStyles(style)(connect(
  mapStateToProps, mapDispatchToProps)(FMaterialTable))