import React from 'react';
import MaterialTable, { MTableToolbar } from 'material-table'
import { Link } from '@material-ui/core';
import FListComponent from '../../util/FListComponent';
import { mapStateToProps, mapDispatchToProps } from '../../fredux/ActionMaker';
import { connect } from "react-redux"

import FRouterLink, { FRouterAddLink } from '../widgets/FRouterLink';

import {  ROUTE_EXPENSE_URL, TAB_EXPENSE, EXPENSE_EXPNESES, CHOICE_EXPENSE, API_EXPENSE_URL } from '../../util/Const';
import { roundDecimal } from '../../util';
import FMaterialTable from '../widgets/FMaterialTable';
import FTableAction, { ACTION_TABLE_DELETE_ROW } from '../widgets/FTableAction';
import AttachmentIcon from '@material-ui/icons/Attachment';
import { green } from '@material-ui/core/colors';
import BlockIcon from '@material-ui/icons/Block';
import { getExpenseType, redForNegative } from '../../util/icon_util';
import FCheckBox from '../widgets/FCheckBox';

class Expenses extends FListComponent {

  constructor(props) {
    super(props, API_EXPENSE_URL, CHOICE_EXPENSE, `hideFutureExpenses=true&`)
    this.currentTitle = 'Expenses'
    this.currentTab = TAB_EXPENSE
    this.routeUrl = ROUTE_EXPENSE_URL
    this.policyID = EXPENSE_EXPNESES
    this.includeStateAction = false
  }

  
  getDeleteURL = () => {
    return API_EXPENSE_URL
  }

  getExpenseFor = (rowData) => {
   let expenseFor = rowData.expenseFor
   if (expenseFor != 'fleet') {
     return expenseFor
   }
   return this.getDriverLink(rowData)
  //  return (
  //   <React.Fragment>
  //     <FRouterLink label={rowData.driver.prettyName} 
  //        to={ROUTE_DRIVER_URL + rowData.driver.id}
  //        disabled={isProfileDispatcher(this.props.profile)}/>
  //   </React.Fragment>
  // )
  //  return expenseFor
  }

  // getActions = (rowData) => {
  //   let actions = []
  //   // rowData.state == 'ACTIVE'? actions.push(ACTION_TABLE_DEACTIVATE): 
  //   //       actions.push(ACTION_TABLE_ACTIVATE)
  //   actions.push(ACTION_TABLE_DELETE_ROW)
  //   return actions
  // }

  updateFilter = (val) => {
    let hideFutureExpenses = !this.state.hideFutureExpenses
    
    this.setState(prevState => {
      let newState = {
        ...prevState,
        queryParams: `hideFutureExpenses=${hideFutureExpenses}&`,
        hideFutureExpenses: hideFutureExpenses
      }
      return newState
    }, () => {
      this.tableRef.current.onQueryChange();
    })
  }
  
  render() {
    return (
      <div>
        <FCheckBox
            name='hideFutureExpenses'
            value={this.state.hideFutureExpenses}
            label="Hide Scheduled Transactions"
            notifyParent={this.updateFilter} />

        <FMaterialTable
          tableRef={this.tableRef}
          columns={[
            {
              title: 'Transaciton #', 
              field: 'expenseTransactionID',
              render: rowData => {
                return (
                  <React.Fragment>
                    <FRouterLink editIcon={true}  label={rowData.expenseTransactionID} to={ROUTE_EXPENSE_URL + rowData.id} />
                  </React.Fragment>
                )
              }
            },
            {
              title: 'Expense Type', 
              render: rowData => {
                return (
                  <React.Fragment>
                      {getExpenseType(rowData)}
                  </React.Fragment>
                )
              }
            },
            // { title: 'Expense Type', field: 'expenseType'},
            {
              title: 'Driver', 
              render: this.getDriverLink
            },

            {
              title: 'Truck / Trailer', 
              render: this.getVehicleLink
            },

            // {
            //   title: 'Expense For',
            //   render: rowData => {
            //     return (
            //       <React.Fragment>
            //         {this.getExpenseFor(rowData)}
            //       </React.Fragment>
            //     )
            //   }
            // },
          
            { title: 'Transaction Date / Receipt', field: 'expenseTransactionDate', 
              
               render:  rowData => {
                  const td = this.getPrettyDate(rowData.expenseTransactionDate)
                  return (
                    <React.Fragment>
                          
                          {td} { rowData.expenseReceipt && <Link href={rowData.expenseReceipt} target="_blank"> <AttachmentIcon style={{ fontSize: 25, color: green[500] }}/></Link>}

                    </React.Fragment>
                  )
                }
            },
            
            {
              title: '% Deducted from Driver',
              width: "30%",
              field: 'expenseIsDeductable',
              render: rowData => {
                return (
                  <React.Fragment>
                   {rowData.expenseIsDeductable && rowData.expenseDeductablePercentage > 0 && rowData.expenseDeductablePercentage +'%'}
                  </React.Fragment>
                )
              }
            },
            {
              title: '% Reimbersed to Driver',
              field: 'expenseIsReimbursable',
              render: rowData => {
                return (
                  <React.Fragment>
                    {rowData.expenseIsReimbursable && rowData.expenseReimbursablePercentage > 0 && rowData.expenseReimbursablePercentage +'%'}
                  </React.Fragment>
                )
              }
            },
            {
              title: 'Invoiced',
              field: 'expenseIsInvoiceable',
              render: rowData => {
                return (
                  <React.Fragment>
                    {rowData.expenseIsInvoiceable && rowData.expenseIsInvoiceable > 0 && rowData.expenseInvoicePercentage + '%'}
                  </React.Fragment>
                )
              }
            },
           
            {
              title: 'Receipt',
              hidden: true,
              render: rowData => {
                let url = rowData.expenseReceipt
                return (
                  <React.Fragment>
                    {url && <Link href={url} target="_blank"> Expense</Link>}
                  </React.Fragment>
                )
              }
            },
            { title: 'Amount ($)', field: 'expenseAmount',  render: rowData => roundDecimal(rowData.expenseAmount)},
            { title: 'Net Applied to Driver', field: 'netAppliedToDriver',  render: rowData => roundDecimal(rowData.netAppliedToDriver)},
            { title: 'Net Applied to Carrier', field: 'netAppliedToCarrier',  render: rowData => redForNegative(roundDecimal(rowData.netAppliedToCarrier))},

            
            // { title: 'Description', field: 'desc' },
            // {
            //   title: 'Actions', 
            //   render: rowData => {
            //     return (
            //       <React.Fragment>
            //         <FTableAction 
            //             actions={this.getActions(rowData)}
            //             onTableAction = {this.onTableAction}
            //             rowData={rowData}
            //              />
            //       </React.Fragment>
            //     )
            //   }
            // },
            {
              title: 'Actions', 
              render: rowData => {
                let actions  = this.getActions(rowData, EXPENSE_EXPNESES)

                return (
                  <React.Fragment>
                    {!!actions.length && <FTableAction 
                        actions={actions}
                        onTableAction = {this.onTableAction}
                        rowData={rowData}
                         />
                    }
                    {!!!actions.length && <BlockIcon color="disabled" />}
                  </React.Fragment>
                )
              }
            },
          ]}
          actions={
            [
              // {
              //   icon: () => (
              //     <FRouterAddLink to={ROUTE_EXPENSE_URL + 'add'} color='secondary' />
              //   ),
              //   isFreeAction: true
              // },
              // rowData => ({
              //   icon: 'delete',
              //   iconProps: {color: 'action'},
              //   tooltip: 'Delete Expense',
              //   onClick: (event, rowData) => {
              //     this.deleteRow(rowData)
              //   },
              // }),
              this.getAddNewModelActions()
            ]}
          options = {{
              pageSize: this.state.pageSize,
              pageSizeOptions: this.state.pageSizeOptions,
              actionsColumnIndex: -1
           }}
          data={this.fetchData}
          title="Expensess`"
          listErrCode={this.state.listErrCode}
          // components={{
          //   Toolbar: props => (
          //       <div style={{ backgroundColor: '#e8eaf5' }}>
          //           <MTableToolbar {...props} />
          //       </div>
          //   )
          // }}
        />
      </div>
    );
  }
}


export default connect(
  mapStateToProps, mapDispatchToProps)(Expenses)
