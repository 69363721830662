import * as CONST from "../util/Const";

export const Labels = {
    CARRIER: 'Authority / Carrier',
    FIRST_NAME: 'First Name',
    MIDDLE_NAME: 'Middle Name',
    LAST_NAME: 'Last Name',
    PHONE_NUMBER: 'Phone Number',
    EMAIL: 'Email',
    DATE_OF_BIRTH: 'Date Of Birth',
    DESCRIPTION_MEMO: 'Notes / Memos',
    TRUCK: 'Truck',
    DATE: 'Date',
    JURISDICTION: 'Jurisdiction',
    DISTANCE_MILE: 'Distance (Mi)',
    BACK_BUTTON: 'Back',
    DATE_FROM: 'Date From',
    DATE_TO: 'Date To',
    CITY: 'City',
    STATUS: 'Status',
    DISTANCE: 'distance',
    ERROR: 'error',
   

    // Driver Info
    DRIVER_ID: 'Driver Id',
    DRIVER_TYPE: 'Driver Type',
    DRIVER_HIRING_DATE: 'Hiring Date',
    TOTAL_NUM_PREVIOUS_EMPLOYERS: 'Total Employers',
    TOTAL_WORKING_HOURS_PREVIOUS_EMPLOYERS: 'Total Working Hours',
    TOTAL_WORKING_MILEAGES_PREVIOUS_EMPLOYERS: 'Total Mileage',
    LAST_EMPLOYMENT_DATE_PREVIOUS_EMPLOYER: 'Last Employment Date',

    TOTAL_VIOLATIONS_PREVIOUS_EMPLOYERS: 'Total Violations',
    TOTAL_ACCIDENTS_PREVIOUS_EMPLOYERS: 'Total Accidents',
    TOTAL_VIOLATIONS: 'Total Up-to-date  Violations',
    TOTAL_ACCIDENTS: 'Total Up-to-date  Accidents',

    DRIVER_APP_USERNAME: 'User Name',
    DRIVER_APP_PASSWORD: 'Password',
    DRIVER_GENERAL_INFO_SUMMARY: 'General Driver Information',
    DRIVER_PREVIOUS_EMPLOYMENT_SUMMARY: 'Driver Previous Employment Summary',
    DRIVER_APP_INFO_SUMMARY: 'Driver App Login Information',

    DRIVER_UP_TO_DATE_VIOLATION_SUMMARY: 'Violations / Accident Summary',

    //Report - IFTA
    IFTA_SUMMARY: 'IFTA Summary',


    //Import Mileage
    IMPORTE_MILEAGE_ID: 'Import Ref#',
    IMPORTE_MILEAGE_IMPORT_DATE: 'Date Imported',

    IMPORT_MILEAGE_PAGE_TITLE:'Mileage (Mi) Import',
    IMPORT_MILEAGE_HELP_KT: ' Below is the supported CSV headers format, with the date formatted as <b> YYYY-MM-DD </b> with  <span style="color:green"> <b>required</b>  </span> <span>fields highlighted </span>  \
                             <br/><br/> \
                             <span style="color:green">[ Date, Vehicle	Jurisdiction, 	Distance (mi),<span>\
                             <span style="color:black">Odometer Start, 	Odometer End,  	Latitude Start, 	Longitude Start, 	Latitude End, 	Longitude End ]</span>',
    IMPORT_MILEAGE_ERROR_TITLE: 'Trip Error Summary',
    IMPORT_MILEAGE_FROM_DATE: 'Trips Date From',
    IMPORT_MILEAGE_TO_DATE: 'Trips Date To',
    IMPORT_MILEAGE_DATA_SOURCE: 'Mileage Input Source',
    IMPORT_MILEAGE_UPLOAD_FILE: 'Upload Trip Data',
    IMPORT_MILEAGE_UPLOAD_BUTTON: 'Import Trips',
    IMPORT_MILEAGE_SUMMARY: 'Imported Trip Summary',
    IMPORT_MILEAGE_SUMMARY_TOTAL_ERRORS: 'Total Errors',
    IMPORT_MILEAGE_SUMMARY_TOTAL_DUPLICATES: 'Total Duplicates',
    IMPORT_MILEAGE_SUMMARY_TOTAL_UNKNOWN_VEHICLES: 'Total Unrecogonized Vehicles',
    IMPORT_MILEAGE_SUMMARY_TOTAL_JURISDICTION: 'Total Jurisdictions',
    IMPORT_MILEAGE_SUMMARY_TOTAL_DISTANCE: 'Total Imported Distance (Mi)',
    IMPORT_MILEAGE_SUMMARY_TOTAL_TRIPS: 'Total Imported Trips',
   
    IMPORT_MILEAGE_ERORR_MESSAGE: 'Trips imported with Errors, open to see error details',
    IMPORT_MILEAGE_SAVE_TRIPS: 'Save Trips',


    //Import FUEL
    IMPORTE_FUEL_ID: 'Import Ref#',
    IMPORTE_FUEL_IMPORT_DATE: 'Date Imported',
    IMPORT_FUEL_PAGE_TITLE:'Fuel Transaction Import',
    IMPORT_FLEET_ONE_EDGE_HELP: ' Below are Fleet One Edge supported CSV headers format, with the date formatted as <b> YYYY-MM-DD </b> with  <span style="color:green"> <b>required</b>  </span> <span>fields highlighted </span>  \
                             <br/><br/> \
                             <span style="color:green">[ Card #, Tran Date, Invoice, Unit, Driver Name, Odometerm,  Location Name, City, State/ Prov, Fees, Item,  Unit Price, Qty, Amt, DB, Currency <span>]',
    
    IMPORT_FLEET_RATE_V1_HELP: 'Below are Flat Rate supported CSV headers format, with the date formatted as <b> YYYY-MM-DD </b> with  <span style="color:green"> <b>required</b>  </span> <span>fields highlighted </span>  \
                             <br/><br/> \
                             <span style="color:green">[ Card #, Tran Date,	Qty, Unit Price, Amt, Location Name, City, State/ Prov, Item, <span>\
                             <span style="color:black">Tran Time, Fees, Invoice, Cash Advance, Cash Advance Charges]</span>',
    IMPORT_FLEET_RATE_HELP: 'Below are Flat Rate supported CSV headers format, with the date formatted as <b> YYYY-MM-DD </b> with  <span style="color:green"> <b>required</b>  </span> <span>fields highlighted </span>  \
                             <br/><br/> \
                             <span style="color:green">[ Card #, Tran Date, Invoice, Unit, Driver Name, Odometerm,  Location Name, City, State/ Prov, Fees, Item,  Unit Price, Qty, Amt, DB, Currency <span>\
                             <span style="color:black"> Card #, Tran Date, Invoice, Unit, Driver Name, Odometerm,  Location Name, City, State/ Prov, Fees, Item,  Unit Price, Qty, Amt, DB, Currency]</span>',
    IMPORT_TCS_HELP: 'Below are TCS supported CSV headers format, with the date formatted as <b> YYYY-MM-DD </b> with  <span style="color:green"> <b>required</b>  </span> <span>fields highlighted </span>  \
                             <br/><br/> \
                             <span style="color:green">[ TX Date, Card #, Truck Stop, City, State, Fuel Amt, Fuel Qty, Fuel PPG, Fuel Disc,	Bio Amt, Bio Qty, Bio PPG, Bio Disc, Reefer Amt, Reefer Qty, Reefer PPG, Reefer Disc, Oil Amt, Oil Qty, DEF Amt, DEF Qty, DEF PPG, Gas Amt, Gas Qty, Gas PPG, Cash, Check Amount, Money Code, Check, Transaction ID,Truck Stop Address <span>\
                             <span style="color:black">Processed Date, Hub/Odo,	Unit #, Driver #, Driver Name, Trip #, Trailer #, License, Auth Code, Driver Information, Check Issued To,	Check Purpose	Sales Tax	Misc 1,	Misc 1 Amt,	Misc 2,	Misc 2 Amt,	Misc 3,	Misc 3 Amt,	Misc 4,	Misc 4 Amt,	Disc, 	Total	Chain	In/Out of Network,	Fuel Total]</span>',
    IMPORT_UNKOWN_FUEL_HELP: 'Below are supported CSV headers format, with the date formatted as <b> YYYY-MM-DD </b> with  <span style="color:green"> <b>required</b>  </span> <span>fields highlighted </span>  \
                             <br/><br/> \
                             <span style="color:green">[ Card #, Tran Date,	Tran Id, Quantity, Unit Price, Amount, Location, City, State, Item, Fees, Note<span>]',

    IMPORT_FUEL_ERROR_TITLE: 'Fuel Transaction Error Summary',
    IMPORT_FUEL_FROM_DATE: 'Transaction Date From',
    IMPORT_FUEL_TO_DATE: 'Transaction Date To',
    IMPORT_FUEL_DATA_SOURCE: 'Fuel Card Source',
    IMPORT_FUEL_UPLOAD_FILE: 'Upload Fuel Transactions',
    IMPORT_FUEL_UPLOAD_BUTTON: 'Import Transactions',
    IMPORT_FUEL_SUMMARY: 'Imported Fuel Transactions Summary',
    IMPORT_FUEL_SUMMARY_TOTAL_ERRORS: 'Total Errors',
    IMPORT_FUEL_SUMMARY_TOTAL_DUPLICATES: 'Duplicates',
    IMPORT_FUEL_SUMMARY_TOTAL_UNKNOWN_FUEL_CARDS: 'Unrecogonized Fuel Cards',
    IMPORT_FUEL_SUMMARY_TOTAL_JURISDICTION: 'Total Jurisdictions',
    IMPORT_FUEL_SUMMARY_TOTAL_FUEL_QUANTITY: 'Fuel Quantity',
    IMPORT_FUEL_SUMMARY_TOTAL_TRANSACTIONS: 'Total Transactions',
    IMPORT_FUEL_SUMMARY_TOTAL_FEES: 'Total Fees',
    IMPORT_FUEL_SUMMARY_TOTAL_FUEL_FEES: 'Fuel Fees',
    IMPORT_FUEL_SUMMARY_TOTAL_CASH_ADVANCE_FEES: 'Advance Fees',
    IMPORT_FUEL_SUMMARY_TOTAL_CASH_ADVANCE: 'Cash Advance',
    IMPORT_FUEL_SUMMARY_TOTAL: 'Total',
    IMPORT_FUEL_SUMMARY_FUEL_TYPE_TOTAL: 'Fuels Amt',


    IMPORT_FUEL_ERORR_MESSAGE: 'Fuel transactions imported with Errors, open to see error details',
    IMPORT_FUEL_SAVE: 'Save Fuel Transactions',

    FUEL_CARD: 'Card #',
    FUEL_QUANTITY: "Fuel Quantity",
    FUEL_AMOUNT: "Amount",
    FUEL_LOCATION: "Location",
    FUEL_TRANSACTION_ID: "Transaction Id",


}

export const DashboardLabels = {
    
    //Compliance Dashboard
    DOCUMENT_COMPLIANCE_TITLE: {
        summary: 'Document Compliance',
        url: '',
        titleUrl: ''
    },
    COMPLIANCE_MISSING_DOCUMENT: {
        summary:'Missing Document',
        url: CONST.ROUTER_MISSING_DOCUMENTS_URL,
        titleUrl: ''
    },
    COMPLIANCE_EXPIRED_DOCS:  {
        summary: 'Expired Docs',
        url: CONST.ROUTER_EXPIRED_URL,
        titleUrl: ''
    },
    COMPLIANCE_EXPIRING_DOCS:  {
        summary:  'Expiring Docs',
        url: CONST.ROUTER_EXPIRING_URL,
        titleUrl: ''
    },

    DRUG_TEST_COMPLIANCE_TITLE: {
        summary:  'Drug Test Compliance',
        url: '',
        titleUrl: CONST.ROUTE_DRUG_TEST_URL
    },
    DRUG_PREEMPLOYMENT_UNTESTED:  {
        summary:  'Missing / Failed Pre-employment drug test',
        url: '',
        titleUrl: ''
    },
    DRUG_FAILED: {
        summary:  'Failed Post-employment drug test',
        url: '',
        titleUrl: ''
    },
    DRUG_RAND_TESTS_COVERAGE: {
        summary:    'Random Test Coverage',
        url: '',
        titleUrl: ''
    },
    DRIVER_EMPLOYMENT_HISTORY_COMPLIANCE_TITLE: {
        summary:   'Driver Employment Compliance',
        url: '',
        titleUrl: CONST.ROUTE_EMPLOYEMENT_HISTORY_URL
    },
    EMPLOYMENT_HISTORY_NOT_AVAILABLE: {
        summary:  'Missing Employment Records',
        url: '',
        titleUrl: ''
    },
    EMPLOYMENT_HISTORY_NOT_VERIFIABLE: {
        summary:  'Unverified Employers',
        url: '',
        titleUrl: ''
    },
    EMPLOYMENT_HISTORY_PENDING_VERIFICATION: {
        summary:  'Pending Verification',
        url: '',
        titleUrl: ''
    },

    TRAIFC_VIOLAITON_COMPLIANCE_TITLE: {
        summary:  'Traffic Violations',
        url: '',
        titleUrl: CONST.ROUTE_TRAFFIC_VIOLATION_URL
    },
    VIOLATION_TOTAL: {
        summary:  'Total Violations within 36  months',
        url: '',
        titleUrl: ''
    },
    VIOLATION_OPEN:  {
        summary:  'Unresolved Violations',
        url: '',
        titleUrl: ''
    },
    VIOLATION_PREVENTABLE: {
        summary:  'Preventable Violations',
        url: '',
        titleUrl: ''
    },
    PAST_N_VIOLATIONS:  {
        summary:  'Total Violations within 36  months',
        url: '',
        titleUrl: ''
    },
    VIOLATION_DOT_RECORDED: {
        summary:  'DOT Recorded Violations',
        url: ''
    },
    VIOLATION_UN_RECORDED: {
        summary:  'Missing Violation Records',
        url: '',
        titleUrl: ''
    },
    
    ACCIDENT_VIOLAITON_COMPLIANCE_TITLE: {
        summary:  'Accidents Violations',
        url: '',
        titleUrl: CONST.ROUTE_ACCIDENT_REGISTER_URL
    },
    ACCIDENT_TOTAL: {
        summary:  'Total Accidents within 36  months',
        url: '',
        titleUrl: ''
    },
    ACCIDENT_OPEN:  {
        summary:  'Unresolved Violations',
        url: '',
        titleUrl: ''
    },
    ACCIDENT_PREVENTABLE: {
        summary:  'Preventable Violations',
        url: ''
    },
    PAST_N_ACCIDENTS: {
        summary:  'Total Accidents within 36  months',
        url: ''
    },
    ACCIDENT_DOT_RECORDED:  {
        summary:  'DOT Recorded Violations',
        url: '',
        titleUrl: ''
    },
    ACCIDENT_UN_RECORDED: {
        summary:  'Missing Accident Records',
        url: '',
        titleUrl: ''
    },
    
    INVOICE_DASHBOARD_SUMMARY_TITLE: {
        summary:  'Invoice Summary',
        url: '',
        titleUrl: CONST.ROUTE_INVOICE_URL
    },
    INVOICE_OPEN: {
        summary:  'Missing / Unpaid Invoices',
        url: '',
        titleUrl: ''
    },
    INVOICE_INCOMPLETE: {
        summary:  'Canceled / Overdues',
        url: ''
    },
    INVOICE_COMPLETE: {
        summary: 'Complete',
        url: '',
        titleUrl: ''
    },
    
    TRUCK_INSPECTION_COMPLIANCE_TITLE: {
        summary: 'Truck Inspection Compliance',
        url: '',
        titleUrl: CONST.ROUTE_VEHICLE_INSPECTION_URL
    },
    PERIODIC_VEHICLE_INSPECTION_UNAVAILABLE:  {
        summary:  'Missing Yearly Inspections',
        url: '',
        titleUrl: ''
    },
    GENERAL_VEHICLE_INSPECTION_FAILED_OR_EXPIRED: {
        summary:  'Yearly Inspection Failed / Expired ',
        url: '',
        titleUrl: ''
    },
    PERIODIC_VEHICLE_INSPECTION_AFTER_ACTIVATION: {
        summary: 'Activated before Full Inspection',
        url: ''
    },
    VEHICLE_MAINTENANCE_OUTDATTED: {
        summary: 'Outdated Maintenance',
        url: ''
    },

    TRAILER_INSPECTION_COMPLIANCE_TITLE: {
        summary: 'Trailer Inspection Compliance',
        url: '',
        titleUrl: CONST.ROUTE_VEHICLE_INSPECTION_URL
    },
    TRAILER_PERIODIC_VEHICLE_INSPECTION_UNAVAILABLE: {
        summary: 'Missing Yearly Inspections',
        url: '',
        titleUrl: ''
    },
    TRAILER_GENERAL_VEHICLE_INSPECTION_FAILED_OR_EXPIRED: {
        summary: 'Yearly Inspection Failed / Expired ',
        url: '',
        titleUrl: ''
    },
    TRAILER_PERIODIC_VEHICLE_INSPECTION_AFTER_ACTIVATION: {
        summary: 'Activated before Full Inspection',
        url: '',
        titleUrl: ''
    },
    TRAILER_VEHICLE_MAINTENANCE_OUTDATTED:  {
        summary: 'Outdated Maintenance',
        url: '',
        titleUrl: ''
    },

    TRUCK_MAINTENANCE_SCHEDULE_COMPLIANCE_TITLE:  {
        summary: 'Truck Maintenance Schedule',
        url: '',
        titleUrl: CONST.ROUTE_MAINTENANCE_SCHEDULE_URL
    },
    MAINTENANCE_SCHEDULE_UNAVAILABLE:  {
        summary: 'Missing Maintenance Schedules',
        url: '',
        titleUrl: ''
    },
    MAINTENANCE_SCHEDULE_INCOMPLETE:  {
        summary: 'Incomplete Maintenance Schedules',
        url: '',
        titleUrl: ''
    },
    MAINTENANCE_SCHEDULE_UPCOMING: {
        summary:'Upcoming Maintenance Schedules',
        url: ''
    },
    MAINTENANCE_SCHEDULE_DISCARDED: {
        summary:   'Discarded Maintenance Schedules',
        url: '',
        titleUrl: ''
    },

    TRAILER_MAINTENANCE_SCHEDULE_COMPLIANCE_TITLE: {
        summary:  'Trailer Maintenance Schedule',
        url: '',
        titleUrl: CONST.ROUTE_MAINTENANCE_SCHEDULE_URL
    },
    TRAILER_MAINTENANCE_SCHEDULE_UNAVAILABLE: {
        summary:  'Missing Maintenance Schedules',
        url: '',
        titleUrl: ''
    },
    TRAILER_MAINTENANCE_SCHEDULE_INCOMPLETE: {
        summary:  'Incomplete Maintenance Schedules',
        url: '',
        titleUrl: ''
    },
    TRAILER_MAINTENANCE_SCHEDULE_UPCOMING: {
        summary:  'Upcoming Maintenance Schedules',
        url: '',
        titleUrl: ''
    },
    
    TRAILER_MAINTENANCE_SCHEDULE_DISCARDED: {
        summary:  'Discarded Maintenance Schedules',
        url: '',
        titleUrl: ''
    },
    
    DRIVER_DISCIPLINARY_COMPLIANCE_TITLE: {
        summary:  'Driver Disciplinary Summary',
        url: '',
        titleUrl: CONST.ROUTER_DRIVER_DISCIPLINARY_EVENT_URL
    },
   
    DISCIPLINARY_SEVERE_EVENT: {
        summary: 'Critical / Severe Events',
        url: '',
        titleUrl: ''
    },
    DISCIPLINARY_WARNING_EVENT: {
        summary: 'Warned Events',
        url: '',
        titleUrl: ''
    },
    DISCIPLINARY_PENDING_INVESTIGATION_EVENT: {
        summary: 'Pending Investigation',
        url: '',
        titleUrl: ''
    },
    DISCIPLINARY_TOTAL_EVENT: {
        summary:   'Total Events',
        url: '',
        titleUrl: ''
    },

    FLEET_SUMMARY_DASHBOARD_TITLE: {
        summary:   'Fleets Summary',
        url: '',
        titleUrl: CONST.ROUTE_FLEET_URL
    },
    FLEET_INPROGRESS: {
        summary:   'Inprogress',
        url: '',
        titleUrl: ''
    },
    FLEET_INCOMPLETE: 
    {
        summary:  'Incomplete / Cancelled',
        url: '',
        titleUrl: ''
    },
    FLEET_UNINVOICED: {
        summary:  'Missing invoices',
        url: '',
        titleUrl: ''
    }
}