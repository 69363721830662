import React from "react";
import { connect } from "react-redux"
import { withStyles } from "@material-ui/core/styles";


import { Grid } from "@material-ui/core";
import FSelect from "../widgets/FSelect";
import FMessage from "../widgets/FMessage";
import FInput from "../widgets/FInput";
import { mapDispatchToProps, mapStateToProps } from "../../fredux/ActionMaker";
import {API_ACCIDENT_REGISTER , API_DOCUMENTS_URL, TAB_SAFETY_AND_COMPLIANCE, CHOICE_ACCIDENT_REGISTER, API_CARRIERS_URL, API_DRIVERS_URL, API_EMPLOYEMENT_HISTORY, SAFETY_AND_COMPLINACE_TRAFFIC_VIOLATIONS } from "../../util/Const";
import DocumentView from "../document"
import { VALIDATE_REQUIRED, VALIDATE_CARRIER_REQUIRED, VALIDATE_OBJECT_REQUIRED } from "../../util/ValidatorConst";
import FDetailComponent from "../../util/FDetailComponent";
import FDate from "../widgets/FDate";
import FCheckBox from "../widgets/FCheckBox";
import { isValid } from "../../util/Validator";
import FAutoComplete from "../widgets/FAutoComplete";

const styles = theme => ({
    root: {
        display: "flex",

    },
    padding: {
        marginTop: theme.spacing(4)
    },

    header: {
        background: '#f5f5f5'
    },
    button: {
        marginRight: theme.spacing(2)
    }
});

const PROPS = {
    errorData: {
    },
    errorMessage: {
        accidentOrViolationDate: 'Invalid Date',
        accidentOrViolationLocation: 'Invalid Location',
        hazardousSpilled: 'Invald Value',
        accidentOrViolationType: 'Invalid Type',
        accidentOrViolationIncidentType: 'Invalid Data',
        accidentOrViolationIncidentResolution: 'Invalid Data',
        accidentOrViolationIncidentResolutionStatus: 'Invalid Data',
        accidentOrViolationCategory: 'Invalid Data',
        isAccidentOrViolationFromPreviousEmployment: 'Invalid Data',
        employmentHistory: "Select the driver's previous employer. Add in Contacts if needed; otherwise, ignore error message.",
        driver: 'No Driver Selected/Found  Under Carrier',
        carrier: 'Invalid Carrier'
    },
    touched: {
        accidentOrViolationIncidentType: true
    },
    data: {
        accidentOrViolationDate: '',
        accidentOrViolationLocation: '',
        hazardousSpilled: '',
        accidentOrViolationType: '',
        accidentOrViolationIncidentType: 'TRAFFIC_VIOLATION',
        accidentOrViolationIncidentResolution:'',
        accidentOrViolationIncidentResolutionStatus: '',
        accidentOrViolationCategory: '',
        desc: '',
        isAccidentOrViolationFromPreviousEmployment: '',
        employmentHistory: {},
        driver: {},
        carrier: {}
    },
    validators: {
        accidentOrViolationDate: [VALIDATE_REQUIRED],
        accidentOrViolationLocation: [VALIDATE_REQUIRED],
        hazardousSpilled: [],
        accidentOrViolationType: [VALIDATE_REQUIRED],
        accidentOrViolationIncidentType: [],
        accidentOrViolationIncidentResolution:[VALIDATE_REQUIRED],
        accidentOrViolationIncidentResolutionStatus:[VALIDATE_REQUIRED],
        accidentOrViolationCategory:[VALIDATE_REQUIRED],
        isAccidentOrViolationFromPreviousEmployment:  [],
        employmentHistory: [VALIDATE_OBJECT_REQUIRED],
        driver: [VALIDATE_OBJECT_REQUIRED],
        carrier: [VALIDATE_CARRIER_REQUIRED],
    }
}



class TrafficViolationDetail extends FDetailComponent {
    constructor(props) {
        super(props, API_ACCIDENT_REGISTER, CHOICE_ACCIDENT_REGISTER, PROPS)
    }

    isFormerEmploymentViolation = () => {
        // check date to doubke verify
        return (this.state.data.isAccidentOrViolationFromPreviousEmployment == true)
    }

    getErrorData = () => {
        let errorData = {
            accidentOrViolationDate: !isValid(this.state.data.accidentOrViolationDate, this.state.validators.accidentOrViolationDate),
            accidentOrViolationLocation: !isValid(this.state.data.accidentOrViolationLocation, this.state.validators.accidentOrViolationLocation),
            accidentOrViolationLocation: !isValid(this.state.data.accidentOrViolationLocation, this.state.validators.accidentOrViolationLocation),
            hazardousSpilled: !isValid(this.state.data.hazardousSpilled, this.state.validators.hazardousSpilled),
            accidentOrViolationType: !isValid(this.state.data.accidentOrViolationType, this.state.validators.accidentOrViolationType),
            accidentOrViolationIncidentType: !isValid(this.state.data.accidentOrViolationIncidentType, this.state.validators.accidentOrViolationIncidentType),
            accidentOrViolationIncidentResolution: !isValid(this.state.data.accidentOrViolationIncidentResolution, this.state.validators.accidentOrViolationIncidentResolution),
            accidentOrViolationIncidentResolutionStatus: !isValid(this.state.data.accidentOrViolationIncidentResolutionStatus, this.state.validators.accidentOrViolationIncidentResolutionStatus),
            accidentOrViolationCategory: !isValid(this.state.data.accidentOrViolationCategory, this.state.validators.accidentOrViolationCategory),

            // isAccidentOrViolationFromPreviousEmployment:
            //       !isValid(this.state.data.isAccidentOrViolationFromPreviousEmployment,
            //            this.state.validators.isAccidentOrViolationFromPreviousEmployment),

            employmentHistory: this.state.data.isAccidentOrViolationFromPreviousEmployment &&  !isValid(this.state.data.employmentHistory, this.state.validators.employmentHistory),

            driver: !isValid(this.state.data.driver, this.state.validators.driver),
            carrier: !isValid(this.state.data.carrier, this.state.validators.carrier)
        }
        return errorData
    }

  

    clearEmploymentHistory = () => {
        if (this.state.data.employmentHistory && this.state.data.employmentHistory.prettyName){
            this.updateValues('employmentHistory', {employmentHistory: ''})
        }
    }
    onCarrierChange = () => {
        this.updateValues('driver', {driver: ''})
        this.clearEmploymentHistory()
    }

    onDriverChange = () => {
        this.clearEmploymentHistory()
    }

    getCurrentTitle = () => {
        let pName = (this.state.data.driver.prettyName && this.state.data.driver.prettyName + ' -  ' + this.state.data.accidentOrViolationType)  || ''

        let title = 'Traffic Violation ' + pName
        this.props.updateCurrentTab(TAB_SAFETY_AND_COMPLIANCE)
        this.props.updateCurrentTitle && this.props.updateCurrentTitle(title)
        return title
    }
    
    render() {
        // let pName = (this.state.data.driver.prettyName && this.state.data.driver.prettyName + ' -  ' + this.state.data.accidentOrViolationType)  || ''
        const { classes } = this.props;
         this.getCurrentTitle()
        return (
            <React.Fragment>
                {/* <FTitle label={title}/> */}
                <Grid container direction='column' justify='space-between' alignItems='stretch' spacing={3} >
                    <Grid item xs>
                        <FMessage error={this.props.crudServerError}
                            success={this.props.crudServerSuccess} />
                    </Grid>
                    <Grid container className={classes.root} spacing= {2} xs={12} >
                            <Grid item xs={3}>
                                    <FAutoComplete
                                        url={API_CARRIERS_URL}
                                        name='carrier'
                                        hasError={this.state.errorData.carrier}
                                        helpMessage={this.state.errorMessage.carrier}
                                        label='Authority *'
                                        // queryParam='search'
                                        value={this.state.data.carrier}
                                        defaultValue={this.state.data.carrier}
                                        notifyParent={this.updateValues}
                                        disabled={!this.state.isNew}
                                        notifyDependency={this.onCarrierChange}

                                        />

                                    {/* <FSelect
                                        name='carrier'
                                        value={this.state.data.carrier && this.state.data.carrier.id}
                                        hasError={this.state.errorData.carrier}
                                        helpMessage={this.state.errorMessage.carrier}
                                        label='Carrier'
                                        data={getCarriers(this.props)}
                                        valueRef='prettyName'
                                        keyRef='id'
                                        notifyParent={this.updateValues} /> */}
                                </Grid>
                            <Grid item xs={3}>
                                    <FAutoComplete
                                        url={API_DRIVERS_URL}
                                        // queryParam={`carrier=${this.state.data.carrier && this.state.data.carrier.id}`}
                                        hasCarrier={true}
                                        carrier={this.state.data.carrier}
                                        name='driver'
                                        hasError={this.state.errorData.driver}
                                        helpMessage={this.state.errorMessage.driver}
                                        label='Driver *'
                                        // queryParam='search'
                                        value={this.state.data.driver}
                                        defaultValue={this.state.data.driver}
                                        notifyParent={this.updateValues}
                                        // disabled={!this.state.isNew}
                                        notifyDependency={this.onDriverChange}
                                        />

                                    {/* <FSelect
                                        name='driver'
                                        value={this.state.data.driver && this.state.data.driver.id}
                                        hasError={this.state.errorData.driver}
                                        helpMessage={this.state.errorMessage.driver}
                                        label='Driver'
                                        
                                        data={getDrivers(this.props, this.state.data.carrier)}
                                        valueRef='prettyName'
                                        keyRef='id'
                                        // disabled={!this.state.isNew}
                                        notifyParent={this.updateValues} /> */}
                                </Grid>
                           
                            <Grid item xs>
                                <FSelect
                                    name='accidentOrViolationType'
                                    value={this.state.data.accidentOrViolationType}
                                    hasError={this.state.errorData.accidentOrViolationType}
                                    helpMessage={this.state.errorMessage.accidentOrViolationType}
                                    label='Violation'
                                    data={this.props.accident_or_violation_register_violation_type_choices}
                                    valueRef='prettyName'
                                    keyRef='prettyName'
                                    notifyParent={this.updateValues}
                                />
                            </Grid>
                            <Grid item xs>
                                <FSelect
                                    name='accidentOrViolationIncidentResolution'
                                    value={this.state.data.accidentOrViolationIncidentResolution}
                                    hasError={this.state.errorData.accidentOrViolationIncidentResolution}
                                    helpMessage={this.state.errorMessage.accidentOrViolationIncidentResolution}
                                    label='Incident Resolution                                    '
                                    data={this.props.accidentOrViolationIncidentResolutionChoices}
                                    valueRef='prettyName'
                                    keyRef='prettyName'
                                    notifyParent={this.updateValues}
                                />
                            </Grid>
                    </Grid>
                    <Grid container className={classes.root} spacing= {2} xs={12} >

                               <Grid item xs={4}>
                                    <FDate
                                        name='accidentOrViolationDate'
                                        required={true}
                                        defaultValue={this.state.data.accidentOrViolationDate}
                                        hasError={this.state.errorData.accidentOrViolationDate}
                                        helpMessage={this.state.errorMessage.accidentOrViolationDate}
                                        label="Violation Date "
                                        notifyParent={this.updateValues} />
                               
                                </Grid>

                            <Grid item xs>
                               <FInput name='accidentOrViolationLocation' required
                                    value={this.state.data.accidentOrViolationLocation}
                                    hasError={this.state.errorData.accidentOrViolationLocation}
                                    helpMessage={this.state.errorMessage.accidentOrViolationLocation}
                                    label="Address" notifyParent={this.updateValues}
                                />
                            </Grid>
                            
                            <Grid item xs>
                                <FSelect
                                    name='accidentOrViolationCategory'
                                    value={this.state.data.accidentOrViolationCategory}
                                    hasError={this.state.errorData.accidentOrViolationCategory}
                                    helpMessage={this.state.errorMessage.accidentOrViolationCategory}
                                    label='Violation Category'
                                    data={this.props.accidentOrViolationCategory}
                                    valueRef='prettyName'
                                    keyRef='prettyName'
                                    notifyParent={this.updateValues}
                                />
                            </Grid>
                            <Grid item xs>
                                <FSelect
                                    name='accidentOrViolationIncidentResolutionStatus'
                                    value={this.state.data.accidentOrViolationIncidentResolutionStatus}
                                    hasError={this.state.errorData.accidentOrViolationIncidentResolutionStatus}
                                    helpMessage={this.state.errorMessage.accidentOrViolationIncidentResolutionStatus}
                                    label='Incident Status'
                                    data={this.props.accidentOrViolationIncidentResolutionStatusChoices}
                                    valueRef='prettyName'
                                    keyRef='prettyName'
                                    notifyParent={this.updateValues}
                                />
                            </Grid>
                           
                    </Grid>     
                    
                    <Grid container className={classes.root} spacing= {2} xs={12} >
                            <Grid item xs={1.2}>
                                    <FCheckBox
                                        name='isAccidentOrViolationFromPreviousEmployment'
                                        value={this.state.data.isAccidentOrViolationFromPreviousEmployment}
                                        hasError={this.state.errorData.isAccidentOrViolationFromPreviousEmployment}
                                        helpMessage={this.state.errorMessage.isAccidentOrViolationFromPreviousEmployment}
                                        label=" Is Violation from Former Employment"
                                        notifyParent={this.updateValues} />
                                </Grid>
                                
                                <Grid item xs >
                                <FAutoComplete
                                        url={API_EMPLOYEMENT_HISTORY}
                                        queryParam={`driver=${this.state.data.driver && this.state.data.driver.id}`}

                                        hasCarrier={true}
                                        carrier={this.state.data.carrier}
                                        name='employmentHistory'
                                        hasError={this.state.errorData.employmentHistory}
                                        helpMessage={this.state.errorMessage.employmentHistory}
                                        label='Former Employment History'
                                        value={this.state.data.employmentHistory}
                                        defaultValue={this.state.data.employmentHistory}
                                        notifyParent={this.updateValues}
                                        // disabled={!this.state.isNew}
                                        />

                                    {/* <FSelect
                                        name='employmentHistory'
                                        disabled={!this.isFormerEmploymentViolation()}
                                        value={this.state.data.employmentHistory && this.state.data.employmentHistory.id}
                                        hasError={this.state.errorData.employmentHistory}
                                        helpMessage={this.state.errorMessage.employmentHistory}
                                        label='Former Employment History *'
                                        data={getEmploymentHistory(this.props, this.state.data.carrier, this.state.data.driver)}
                                        valueRef='prettyName'
                                        keyRef='id'
                                        notifyParent={this.updateValues} /> */}
                                 </Grid>
                                 <Grid item xs={3}>
                                    <FCheckBox
                                        name='isAccidentOrViolationPreventable'
                                        value={this.state.data.isAccidentOrViolationPreventable}
                                        hasError={this.state.errorData.isAccidentOrViolationPreventable}
                                        helpMessage={this.state.errorMessage.isAccidentOrViolationPreventable}
                                        label="Is Violation Preventable"
                                        notifyParent={this.updateValues} />
                                </Grid>
                                <Grid item xs={3}>
                                    <FCheckBox
                                        name='isAccidentOrViolationDOTRecorded'
                                        value={this.state.data.isAccidentOrViolationDOTRecorded}
                                        hasError={this.state.errorData.isAccidentOrViolationDOTRecorded}
                                        helpMessage={this.state.errorMessage.isAccidentOrViolationDOTRecorded}
                                        label="Is Violation DOT Recorded"
                                        notifyParent={this.updateValues} />
                                </Grid>
                        </Grid>
                         <Grid container className={classes.root} spacing= {2} xs={12} >
                            <Grid item xs>
                                 <FInput
                                    name='desc' value={this.state.data.desc} multiline={true}
                                    value={this.state.data.desc}
                                    hasError={this.state.errorData.desc}
                                    helpMessage={this.state.errorMessage.desc}
                                    label="Description" notifyParent={this.updateValues} />
                            </Grid>
                    </Grid>     
                    
                    <Grid item container xs={8} >
                            {this.getUpdateButton(this.props.profile, SAFETY_AND_COMPLINACE_TRAFFIC_VIOLATIONS)}
                    </Grid>
                    {/* <Grid item container xs={8} >
                        <FButton color='primary'
                            label={this.state.isNew ? 'Save' : 'Update'}
                            loading={this.state.loading}
                            onClick={this.onSaveData}
                        />
                        <Grid item style={{ paddingTop: '9px' }}>
                            <FRouteBackLink variant='outlined' color='secondary' className={'backButton'} />
                        </Grid>
                    </Grid> */}
                    <Grid item xs className={classes.padding}>
                        {!this.state.isNew &&
                            <DocumentView
                                ownerId={this.props.match.params.id}
                                owner={this.state.data}
                                listUrl={API_DOCUMENTS_URL + `?&accident_or_violation_register=${this.props.match.params.id}`}
                                ownerType='accident_or_violation_register'
                                policy_id={SAFETY_AND_COMPLINACE_TRAFFIC_VIOLATIONS}
                                documentChoices={this.props.document_accident_or_violation_register_violation_choices}
                            />}
                    </Grid>
                </Grid >
             </React.Fragment>
        );
    };
}


export default withStyles(styles)(connect(
    mapStateToProps, mapDispatchToProps)(TrafficViolationDetail))
