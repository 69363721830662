import React from 'react';
import FRouterLink, { FRouterAddLink } from '../widgets/FRouterLink';
import { API_FLEET_URL, ROUTE_DRIVER_URL, ROUTE_VEHICLE_URL, ROUTE_DISPATCHER_URL, ROUTE_FLEET_URL, ROUTE_CARRIERS_URL, ROUTE_CUSTOMER_URL, TAB_FLEET, FLEET_FLEETS, CHOICE_FLEET } from '../../util/Const';
import FListComponent from '../../util/FListComponent';
import { Link } from '@material-ui/core';
import { mapStateToProps, mapDispatchToProps } from '../../fredux/ActionMaker';
import { connect } from "react-redux"
import { roundDecimal } from '../../util';
import FTableAction, { ACTION_TABLE_DELETE_ROW } from '../widgets/FTableAction';
import FMaterialTable from '../widgets/FMaterialTable';
import BlockIcon from '@material-ui/icons/Block';
import { getStatusIcon } from '../../util/icon_util';

class Fleets extends FListComponent {
  constructor(props) {
    super(props, API_FLEET_URL, CHOICE_FLEET)
    this.currentTitle = 'Fleets'
    this.currentTab = TAB_FLEET
    this.routeUrl = ROUTE_FLEET_URL
    this.policyID = FLEET_FLEETS
    this.includeStateAction = false
  }
  
  // getActions = (rowData) => {
  //   let actions = []
  //   // rowData.state == 'ACTIVE'? actions.push(ACTION_TABLE_DEACTIVATE): 
  //   //       actions.push(ACTION_TABLE_ACTIVATE)
  //   actions.push(ACTION_TABLE_DELETE_ROW)
  //   return actions
  // }


  render() {

    return (
      <div>

        <FMaterialTable
          tableRef={this.tableRef}
          columns={[
            {
              title: 'Reference #', field: 'fleetLoadNumber',
              render: rowData => {
                return (
                  <React.Fragment>
                    <FRouterLink editIcon={true} label={rowData.fleetLoadNumber} to={ROUTE_FLEET_URL + rowData.id} />
                  </React.Fragment>
                )
              }
            },
            {
              title: 'Carrier', 
              render: this.getCarrierLink
            },
          
            {
              title: 'Dispatcher', 
              render: this.getDispatcherLink
            },
            {
              title: 'Driver', 
              render: this.getDriverLink
            },
            // {
            //   title: 'Driver', field: 'driver.prettyName',
            //   render: rowData => {
            //     return (
            //       <React.Fragment>
            //         <FRouterLink label={rowData.driver.prettyName} 
            //            to={ROUTE_DRIVER_URL + rowData.driver.id}
            //            disabled={isProfileDispatcher(this.props.profile)}/>
            //       </React.Fragment>
            //     )
            //   }
            // },
            // {
            //   title: 'Carrier', field: 'carrier.prettyName',
            //   render: rowData => {

            //     return (
            //       <React.Fragment>
            //         <FRouterLink label={rowData.carrier.prettyName} to={ROUTE_CARRIERS_URL + rowData.carrier.id}
            //           disabled={ isProfileDispatcher(this.props.profile)}/>
            //       </React.Fragment>
            //     )
            //   }
            // },
           
            {
              title: 'Customer', 
              render: this.getCustomerLink
            },
            // {
            //   title: 'Customer',
            //   field: 'prettyName',
            //   render: rowData => {
            //     if (!!!rowData.customer){
            //       return '';
            //     }
            //     return (
            //       <React.Fragment>
            //         <FRouterLink 
            //           label={rowData.customer.prettyName} 
            //           to={ROUTE_CUSTOMER_URL + rowData.customer.id} />
            //       </React.Fragment>
            //     )
            //   }
            // },
            
            { title: 'Pickup Date', field: 'fleetPickUpDate',
             render: rowData => this.getPrettyDate(rowData.fleetPickUpDate) },

            // {
            //   title: 'Trip Date', render: rowData => {
            //     return (
            //       <React.Fragment>
            //          <b>{this.getPrettyDate(rowData.fleetPickUpDate)}</b> to <b>{this.getPrettyDate(rowData.fleetDeliveryDate)}</b>
            //       </React.Fragment>
            //     )
            //   }
            // },
            {
              title: 'Trip Location', field: 'fleetPickupLocation',
              render: rowData => {
                return (
                  <React.Fragment>
                     <b>{rowData.fleetPickupLocation}</b> to <b>{rowData.fleetDeliveryLocation}</b>
                  </React.Fragment>
                )
              }
            },
            // { title: 'Pickup Location', field: 'fleetPickupLocation' },
            // { title: 'Delivery Date', render: rowData => this.getPrettyDate(rowData.fleetDeliveryDate) },
            // { title: 'Delivery Location', field: 'fleetDeliveryLocation' },

            
            {
              title: 'Dispatcher', hidden: true, render: rowData => {
                return (
                  <React.Fragment>
                    <FRouterLink label={rowData.fleetDispatcher} to={ROUTE_DISPATCHER_URL + rowData.fleetDispatcher} />
                  </React.Fragment>
                )
              }
            },
            { title: 'Fee', field: 'fleetLoadFee', render: rowData => roundDecimal(rowData.fleetLoadFee) },
            { title: 'Lumper Fee', field: 'fleetLumperFee', render: rowData => roundDecimal(rowData.fleetLumperFee) },
            {
              title: 'Confirmation / BOL',
              render: rowData => {
                return (
                  <React.Fragment>
                    {rowData.fleetConfirmation && <Link href={rowData.fleetConfirmation} target="_blank">Confirmation</Link>}
                    &nbsp; &nbsp; | &nbsp; &nbsp;
                    {rowData.fleetBOL && <Link href={rowData.fleetBOL} target="_blank">BOL</Link>}
                  </React.Fragment>
                )
              }
            },
            {
              title: 'Status', 
              render: rowData => {
                return (
                  <React.Fragment>
                      {getStatusIcon(rowData)}
                  </React.Fragment>
                )
              }
            },
            // { title: 'Fleet Status', field: 'status' },

            // {
            //   title: 'Actions', 
            //   render: rowData => {
            //     return (
            //       <React.Fragment>
            //         <FTableAction 
            //             actions={this.getActions(rowData)}
            //             onTableAction = {this.onTableAction}
            //             rowData={rowData}
            //              />
            //       </React.Fragment>
            //     )
            //   }
            // },
            {
              title: 'Actions', 
              render: rowData => {
                let actions  = this.getActions(rowData, FLEET_FLEETS)

                return (
                  <React.Fragment>
                    {!!actions.length && 
                      <FTableAction 
                        actions={actions}
                        onTableAction = {this.onTableAction}
                        rowData={rowData}
                         />
                    }
                    {!!!actions.length && <BlockIcon color="disabled" />}
                  </React.Fragment>
                )
              }
            },
          ]}
          actions={
            [
              // {
              //   icon: () => (
              //     <FRouterAddLink to={ROUTE_FLEET_URL + 'add'} color='secondary' />
              //   ),
              //   isFreeAction: true
              // }
              this.getAddNewModelActions()
            ]} 
          options = {{
              pageSize: this.state.pageSize,
              pageSizeOptions: this.state.pageSizeOptions,
              actionsColumnIndex: -1
           }}
          data={this.fetchData}
          title="Fleets"
          listErrCode={this.state.listErrCode}
          
          // components={{
          //   Toolbar: props => (
          //       <div style={{ backgroundColor: '#e8eaf5' }}>
          //           <MTableToolbar {...props} />
          //       </div>
          //   )
          // }}
        />

      </div>
    );
  }
}


export default connect(
  mapStateToProps, mapDispatchToProps)(Fleets)
