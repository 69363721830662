import AppBar from '@material-ui/core/AppBar';
import CssBaseline from '@material-ui/core/CssBaseline';
import Divider from '@material-ui/core/Divider';
import Drawer from '@material-ui/core/Drawer';
import { withStyles } from '@material-ui/core/styles';
import Toolbar from '@material-ui/core/Toolbar';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import ReactNotification from 'react-notifications-component';
import 'react-notifications-component/dist/theme.css';
import { connect } from "react-redux";
import { withRouter } from 'react-router-dom';
import { mapDispatchToProps, mapStateToProps } from '../../fredux/ActionMaker';
import * as CONST from "../../util/Const";
import {  ACCOUNT_INSYNC_PROFILE_REFRESH_INTERVAL, PROFILE_DATA_REFRESH_INTERVAL, PROFILE_STATE_REFRESH_INTERVAL } from '../../util/Const';
import { hasNewVersionUpdate, getAuthToken, getProfile, initStore, logout, refreshInSyncProfile, updateProfileState, validateSubscriptionWithRerouteToPaymentPage} from '../../util/FAPI';
import SignIn from '../auth/SignIn';
import AdminListItems from '../nav_list/AdminNavList';
import DashboardNavList from '../nav_list/DashboardNavList';
import DriverAppListItems from '../nav_list/DriverAppNavList';
import ExpenseListItems from '../nav_list/ExpenseNavList';
import FinanceListItems from '../nav_list/FinanceNavList';
import FleetListItems from '../nav_list/FleetNavList';
import ProfileListItems from '../nav_list/ProfileNavList';
import ReportNavList from '../nav_list/ReportNavList';

import SafetyAndComplianceListItems from '../nav_list/SafetyAndComplianceNavList';
import FooterNavList from '../nav_list/FooterNavList';
import FVersionInfo from  '../widgets/FVersionInfo';
import FRoute from './FRoute';
import ProfileTabs from './ProfileTabs';

import logo from '../images/zlogo2.png'; 
import logoDev from '../images/red-zt-logo.png'; 
import { isUserAdminOrCarrier } from '../../util/ACL';




const drawerWidth = 240;

const styles = theme => ({
    root: {
        display: 'flex',
    },
    toolbar: {
        boxShadow: 0,

    },

    toolbarIcon: {

        backgroundColor: 'rgb(245, 247, 248)',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        padding: '0 8px',
        ...theme.mixins.toolbar,
    },
    appBar: {
        marginLeft: 0,
        backgroundColor: 'rgb(245, 247, 248)',
        zIndex: theme.zIndex.drawer + 1,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),

    },
    appBarShift: {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    menuButton: {
        marginLeft: 12,
        marginRight: 36,
    },
    menuButtonHidden: {
        display: 'none',
    },
    logo: {
        flexGrow: 1,
        // fontSize: 25,
        fontWeight: 'bold',
        color: "blue",
        // height: '100vh',
        width: '10px',
    },
    // zlogo: {
    //     // flexGrow: 1,
    //     // width: '100vw',
    //     // height: '100vh',
    //     // alignItems: 'left',
    //     // justifyContent: 'flex-start',
    //     // marginRight: '50px'
    // },
    logo_dev: {
        flexGrow: 1,
        fontSize: 25,
        fontWeight: 'bold',
        color: "red",
        marginLeft: '50px'
    },
    title: {
        flexGrow: 1,
        color: "black",
    },
    drawerPaper: {
        backgroundColor: 'rgb(245, 247, 248)',
        // color: "#4a88c2",
        color:  'rgba(0, 0, 0, 0.54)',
        position: 'relative',
        whiteSpace: 'nowrap',
        width: drawerWidth,
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    drawerPaperClose: {
        overflowX: 'hidden',
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        width: theme.spacing(7),
        [theme.breakpoints.up('sm')]: {
            width: theme.spacing(9),
        },
    },
    // appBarSpacer: theme.mixins.toolbar,
    appBarSpacer:  {
    //   marginTop: '35px',
      paddingTop: '2.7em'
    },
    content: {
        flexGrow: 1,
        padding: theme.spacing(3),
        // height: '130vh',
        minHeight: '100vh',
        minWidth: '100vh',

        // overflow: auto,
        backgroundColor: '#fff'
    },
    chartContainer: {
        marginLeft: -22,
    },
    tableContainer: {
        height: 300,
    },

    h5: {
        marginBottom: theme.spacing(2),
    },

    selectCarrier: {
        color: "white",
    },

    rootCarrier: {
        color: "gray",
    },
    displayCarrier: {
        color: "white"
    },

    logout: {
        color: "#4a88c2",
        paddingLeft: theme.spacing(2)
    }
});

class Main extends React.Component {
    state = {
        open: true,
    };

    componentDidMount() {
        getProfile(this.props)
        initStore(this.props)
        this.refreshProfile = setInterval(() => getProfile(this.props),
             PROFILE_DATA_REFRESH_INTERVAL);
        this.updateProfileState = setInterval(() => updateProfileState(this.props),
             PROFILE_STATE_REFRESH_INTERVAL);
        this.refreshInsyncProfiles = setInterval(() =>  refreshInSyncProfile(this.props),
             ACCOUNT_INSYNC_PROFILE_REFRESH_INTERVAL);
        this.updateTabsOnHistoryChange()
    }

    componentWillUnmount() {
        clearInterval(this.refreshProfile);
        clearInterval(this.updateProfileState);
        clearInterval(this.refreshInsyncProfiles);
        this.refreshProfile = null;
        this.updateProfileState = null;
        this.refreshInsyncProfiles = null;
    }

    handleDrawerOpen = () => {
        this.setState({ open: true });
    };

    handleDrawerClose = () => {
        this.setState({ open: false });
    };
    
    updateCurrentCarrier = (name, obj) => {
    //    carrierDetail = filter_from_list_of_carriers_from_profile_carriers
    //    this.props.updateCurrentCarrier({currentCarrier: carrierDetail})
    }
    logout = () => {
        logout(this.props)
    }
    getEnv = () => {
        const { classes } = this.props;
        if (!CONST.BASE_API_URL.includes('admin.ztrucking.com')) {
            return 'PRODUCTION'
        }
        return 'DEV'
    }
    updateTabsOnHistoryChange = () => {
        let pathName = this.props.location && this.props.location.pathname
        if (pathName.includes(CONST.TAB_FLEET) && this.props.currentTab !== CONST.TAB_FLEET){
            this.props.updateCurrentTab(CONST.TAB_FLEET)
        }
        if (pathName.includes(CONST.TAB_REPORT) && this.props.currentTab !== CONST.TAB_REPORT){
            this.props.updateCurrentTab(CONST.TAB_REPORT)
        }
        
        if (pathName.includes(CONST.TAB_DASHBOARD) && this.props.currentTab !== CONST.TAB_DASHBOARD){
            this.props.updateCurrentTab(CONST.TAB_DASHBOARD)
        }
        if (pathName.includes(CONST.TAB_EXPENSE) && this.props.currentTab !== CONST.TAB_EXPENSE){
            this.props.updateCurrentTab(CONST.TAB_EXPENSE)
        }
        if (pathName.includes(CONST.TAB_SAFETY_AND_COMPLIANCE) && this.props.currentTab !== CONST.TAB_SAFETY_AND_COMPLIANCE){
            this.props.updateCurrentTab(CONST.TAB_SAFETY_AND_COMPLIANCE)
        }
       
        if (pathName.includes(CONST.TAB_ACCOUNTING) && this.props.currentTab !== CONST.TAB_ACCOUNTING){
            this.props.updateCurrentTab(CONST.TAB_ACCOUNTING)
        }
        if (pathName.includes(CONST.TAB_ADMIN) && this.props.currentTab !== CONST.TAB_ADMIN){
            this.props.updateCurrentTab(CONST.TAB_ADMIN)
        }
    
        if (pathName.includes(CONST.TAB_DRIVER_APP) && this.props.currentTab !== CONST.TAB_DRIVER_APP){
            this.props.updateCurrentTab(CONST.TAB_DRIVER_APP)
        }
        if (pathName.includes(CONST.TAB_PROFILE) && this.props.currentTab !== CONST.TAB_PROFILE){
            this.props.updateCurrentTab(CONST.TAB_PROFILE)
        }
    }
    
    /**
     * Show/ihde navlist on the side according to the tab id
     * @param {*} tabId 
     */
    showNavList = (tabId) => {
        if (this.props.currentTab === tabId) {
            return true;
        }
        return false;

    };
    
    render() {
        const { classes } = this.props;
        let authData = getAuthToken()
        if (!authData) {
            return (<SignIn />)
        }
        
        
        validateSubscriptionWithRerouteToPaymentPage(this.props.profile, this.props)
        // this.updateTabsOnHistoryChange()
        return (
            <div>

                    <div className={classes.root}>
                        <CssBaseline />

                        <AppBar
                            elevation={1}
                            position="absolute" 
                            className={classNames(classes.appBar, this.state.open && classes.appBarShift)}
                            >
                            <Toolbar disableGutters={!this.state.open} className={classes.toolbar}>

                                {/* <Typography
                                    component="h1"
                                    variant="h6"
                                    color="inherit"
                                    noWrap
                                    className={classes.title}>
                                    {this.props.currentTitle}
                                </Typography> */}
                                <ProfileTabs {...this.props}/>
                                
                            </Toolbar>
                        </AppBar>
                        <Drawer
                            variant="permanent"
                            classes={{
                                paper: classNames(classes.drawerPaper, !this.state.open && classes.drawerPaperClose),
                            }}
                            open={this.state.open}>
                            {/* <div className={classes.toolbarIcon}> */}
                            <div className={classes.toolbarIcon}>
                                {/* <Avatar alt="ztrucking" src="/favicon.ico"  className={classes.logo}/> */}
                                {/* <Avatar alt="ztrucking" src="/zlogo2.png"   className={classes.zlogo} sx={{ flex:1, width: '100%', height: '100%' }}/> */}
                                {CONST.isProdEnv() && <img src={logo} alt="Carrier Management System" className={classes.logo} />}
                                {!CONST.isProdEnv() && <img src={logoDev} alt="Carrier Management System" className={classes.logo} />}
                                {/* <Typography
                                    component="h1"
                                    variant="h6"
                                    noWrap
                                    className={this.getLogoClass()}>
                                    ZTrucking
                                </Typography> */}
                                {/* <IconButton onClick={this.handleDrawerClose}>
                                    <ChevronLeftIcon />
                                </IconButton> */}
                            </div>
                            <Divider />

                                {this.showNavList(CONST.TAB_DASHBOARD) && <DashboardNavList />}
                                {this.showNavList(CONST.TAB_REPORT) && <ReportNavList />}
                                {this.showNavList(CONST.TAB_FLEET) && <FleetListItems />}
                                {this.showNavList(CONST.TAB_ADMIN) && <AdminListItems />}

                                {this.showNavList(CONST.TAB_DRIVER_APP) && <DriverAppListItems />}
                                {this.showNavList(CONST.TAB_EXPENSE) && <ExpenseListItems />}

                                {this.showNavList(CONST.TAB_SAFETY_AND_COMPLIANCE) && <SafetyAndComplianceListItems />}
                                {this.showNavList(CONST.TAB_ACCOUNTING) && <FinanceListItems />}
                              
                                {this.showNavList(CONST.TAB_PROFILE) && <ProfileListItems {...this.props} />}
                                <FooterNavList 
                                   profileName={this.props.profile && this.props.profile.profileName}
                                   isAdminOrCarrier={isUserAdminOrCarrier(this.props)}/>
                         

                        </Drawer>

                        <ReactNotification />
                        <div className={classes.content}>
                                <div className={classes.appBarSpacer} />
                                  <p align="right" style={{color: "#6b6b6e"}}>  {this.props.currentTitle}</p>
                            <FRoute />
                        
                        </div>
                        <FVersionInfo showWarnning={hasNewVersionUpdate(this.props)} version={this.props[CONST.ZT_ADMIN_UI_VERSION_NAME]}/>
            </div>
            </div>
        );
    }
}

Main.propTypes = {
    classes: PropTypes.object.isRequired,
};


export default withStyles(styles)(withRouter(connect(
    mapStateToProps, mapDispatchToProps)(Main)))
