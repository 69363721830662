import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux"
import { withStyles } from "@material-ui/core/styles";
import { Grid } from "@material-ui/core";
import FMessage from "../widgets/FMessage";
import FInput from "../widgets/FInput";
import { mapDispatchToProps, mapStateToProps } from "../../fredux/ActionMaker";
import { isValid } from "../../util/Validator";
import { VALIDATE_REQUIRED, VALIDATE_DATE, VALIDATE_OBJECT_REQUIRED, VALIDATE_CARRIER_REQUIRED, 
       VALIDATE_FEE, VALIDATE_ZERO_OR_POSITIVE,
        VALIDATE_DAYS_FREQUENCY, VALIDATE_POSITIVE, VALIDATE_ADDRESS_CITY_STATE, VALIDATE_FILE_SIZE, VALIDATE_0_100_PERCENTAGE } from "../../util/ValidatorConst";
import FDetailComponent from "../../util/FDetailComponent";
import FDate from "../widgets/FDate";
import FCheckBox from "../widgets/FCheckBox";
import FFileUpload from "../widgets/FFileUpload";

import FAutoComplete from "../widgets/FAutoComplete";


import FSelect from "../widgets/FSelect";
import * as CONST from "../../util/Const"
import { getNextDateFrom, toFloat, roundDisplay } from "../../util";
import FAddress from "../widgets/FAddress";
import CompanyPopUp from "../company/CompanyPopUp";

const styles = theme => ({
    reimbursablePercentage :{
        marginRight: theme.spacing(2),
    }, 
    invoiceablePercentage: {
        marginRight: theme.spacing(2),
    },
    padding :{
        marginTop: theme.spacing(1),
        marginBottom:  theme.spacing(1),
    }, 
    deductable: {
        marginTop: theme.spacing(2),
        marginBottom:  theme.spacing(1),
    },
    desc: {
        marginTop:  theme.spacing(2),
        marginLeft: theme.spacing(8),
    },
    expenseReceipt: {
        marginRight: theme.spacing(10),
    },
    space: {
        marginRight: theme.spacing(10),
    },
    spaceLeft: {
        marginLeft: theme.spacing(10),
    }
});

const PROPS = {
    total:0,
    data: {
        expenseFor: 'fleet',
        expenseType: 'MAINTENANCE',

        expenseTransactionID: '',
        expenseTransactionDate: '',
        expenseAmount: 0,
        driver: {},
        vehicle: {},
        // dispatcher: {},
        carrier: {},
        fleet: {},
        expenseReceipt: '',
        expenseIsReimbursable: '',
        expenseReimbursablePercentage: 0,

        expenseIsDeductable: '',
        expenseDeductablePercentage: 0,

        expenseMaintenanceCategory: '',
        company: {},
        expenseMaintenanceMechanicName: '',
        vehicleMaintenanceMechanicEmail: '',
        vehicleMaintenanceMechanicPhoneNumber: '',
        expensePayCheckTransactionDate: '',
        expenseInvoiceTransactionDate: '',
        
        expenseIsRecurring: false,
        expenseRecurringStartDate: '',
        expenseRecurringEndDate: getNextDateFrom(90),
        expenseRecurringDays: '',

        expenseFee: 0,
        expenseUnitPrice: 0,
        expenseQuantity: 1,
        expenseFuelType: 'UN_KNOWN',
        
        address:{},
        disc: ''
    },
    errorData: {
    },
    touched: {
        expenseFor: true, 
        expenseType: true,
    },
    errorMessage: {
        expenseFor: 'Invalid Data',
        expenseTransactionID: 'Invalid Transaction ID',
        expenseTransactionDate: 'Invalid Date',
        expenseAmount: 'Invalid Expense Amount, Use deductable or reimbursable to record negative',
        driver: 'No Driver Selected/Found  Under Carrier',
        vehicle: 'No Vehicle Selected/Found  Under Carrier',
        dispatcher: 'No Dispatcher Selected/Found  Under Carrier',
        carrier: 'Invalid Carrier',
        fleet: 'No fleet Selected/Found  Under Carrier',
        expenseReceipt: 'Invalid Receipt',
        expenseIsReimbursable: 'Invalid value',
        expenseReimbursablePercentage: 'Invalid percentage value [0 - 100]',
        expenseIsDeductable: 'Invalid Value',
        expenseDeductablePercentage: 'Invalid percentage value [0 - 100]',
        expenseIsInvoiceable: 'Invalid Value',
        expenseInvoicePercentage: 'Invalid percentage value [0 - 100]',
        expenseType: 'Invalid Expense Type',
        company: 'Invalid Auto Serivce',
        expenseMaintenanceCategory: 'Invalid Maintenance Category',
        expenseMaintenanceMechanicName: 'Invalid Name',
        expenseMaintenanceMechanicEmail: 'Invalid Email',
        expenseMaintenanceMechanicPhoneNumber: 'Invalid Phone Number',
        expensePayCheckTransactionDate: 'Invalid Pay Check Transaction Date',
        expenseInvoiceTransactionDate: 'InvalidInvoice Transaction Date',

        expenseIsRecurring: 'Invalid Value',
        expenseRecurringStartDate: 'Invalid Start Date / Interval',
        expenseRecurringEndDate: 'Invalid End Date / Interval',
        expenseRecurringDays: 'Invalid Frequency number of days,  Max 360 days',
        expenseFee: 'Invalid Fee',
        expenseUnitPrice: 'Invalid Amount',
        expenseQuantity: 'Invalid Quantity',
        expenseFuelType: 'Invald Fuel Type',
        disc: ''
    },
    validators: {
        expenseFor: [VALIDATE_REQUIRED],
        expenseTransactionID: [],
        expenseTransactionDate: [VALIDATE_DATE],
        expenseAmount: [VALIDATE_FEE],
        driver: [VALIDATE_OBJECT_REQUIRED],
        vehicle: [VALIDATE_OBJECT_REQUIRED],
        dispatcher: [VALIDATE_REQUIRED],
        carrier: [VALIDATE_CARRIER_REQUIRED],
        // fleet: [VALIDATE_REQUIRED],
        // customer: [VALIDATE_REQUIRED],
        expenseReceipt: [VALIDATE_FILE_SIZE],
        expenseIsReimbursable: [],
        expenseReimbursablePercentage: [VALIDATE_0_100_PERCENTAGE],
       
        expenseIsDeductable: [],
        expenseDeductablePercentage: [VALIDATE_0_100_PERCENTAGE],
        expenseIsInvoiceable: [],
        expenseInvoicePercentage: [VALIDATE_0_100_PERCENTAGE],

        expenseType: [VALIDATE_REQUIRED],
        expenseMaintenanceCategory: [VALIDATE_REQUIRED],
        // expenseMaintenanceMechanicName: [VALIDATE_REQUIRED],
        // expenseMaintenanceMechanicEmail: [VALIDATE_EMAIL],
        // expenseMaintenanceMechanicPhoneNumber: [VALIDATE_PHONE_NUMBER],
        expensePayCheckTransactionDate: [],
        expenseInvoiceTransactionDate: [],

        expenseIsRecurring: [],
        expenseRecurringStartDate: [VALIDATE_REQUIRED],
        expenseRecurringEndDate:[VALIDATE_REQUIRED],
        expenseRecurringDays: [VALIDATE_DAYS_FREQUENCY],

        expenseFee: [VALIDATE_ZERO_OR_POSITIVE],
        expenseUnitPrice: [VALIDATE_ZERO_OR_POSITIVE],
        expenseQuantity: [VALIDATE_POSITIVE],

        expenseFuelType:[VALIDATE_REQUIRED],
        address: [VALIDATE_ADDRESS_CITY_STATE],

        disc: [],

    }
}

class VehicleMaintenanceDetail extends FDetailComponent {

    constructor(props) {
        super(props, CONST.API_VEHICLE_MAINTENANCE_URL, 'expense', PROPS)
    }

    getErrorData = () => {
        let errorData = {
            expenseFor: !isValid(this.state.data.expenseFor, this.state.validators.expenseFor),
            expenseTransactionDate: !isValid(this.state.data.expenseTransactionDate, this.state.validators.expenseTransactionDate),
            // Make readonly
            expenseUnitPrice: !isValid(this.state.data.expenseUnitPrice, this.state.validators.expenseUnitPrice),
            expenseQuantity: !isValid(this.state.data.expenseQuantity, this.state.validators.expenseQuantity),
            expenseFee: !isValid(this.state.data.expenseFee, this.state.validators.expenseFee),
            expenseAmount: !isValid(this.state.data.expenseAmount, this.state.validators.expenseAmount),

            driver: !isValid(this.state.data.driver, this.state.validators.driver),
            vehicle:  !isValid(this.state.data.vehicle, this.state.validators.vehicle),
            // dispatcher: this.isExpenseModule('dispatcher') && !isValid(this.state.data.dispatcher, this.state.validators.dispatcher),
            carrier:  !isValid(this.state.data.carrier, this.state.validators.carrier),
            
            expenseType: !isValid(this.state.data.expenseType, this.state.validators.expenseType),

            expenseReimbursablePercentage: this.state.data.expenseIsReimbursable && !isValid(this.state.data.expenseReimbursablePercentage, this.state.validators.expenseReimbursablePercentage),
            expenseDeductablePercentage: this.state.data.expenseIsDeductable && !isValid(this.state.data.expenseDeductablePercentage, this.state.validators.expenseDeductablePercentage),
            expenseInvoicePercentage: this.state.data.expenseIsInvoiceable && !isValid(this.state.data.expenseInvoicePercentage, this.state.validators.expenseInvoicePercentage),
            // fleet: this.state.data.expenseIsInvoiceable && !isValid(this.state.data.fleet, this.state.validators.fleet),

            expenseMaintenanceCategory: !isValid(this.state.data.expenseMaintenanceCategory, this.state.validators.expenseMaintenanceCategory),
            // expenseMaintenanceMechanicName: this.isMaintenanceExpense() && !isValid(this.state.data.expenseMaintenanceMechanicName, this.state.validators.driver),
            // expenseMaintenanceMechanicEmail: this.isMaintenanceExpense() && !isValid(this.state.data.expenseMaintenanceMechanicEmail, this.state.validators.driver),
            // expenseMaintenanceMechanicPhoneNumber: this.isMaintenanceExpense() && !isValid(this.state.data.expenseMaintenanceMechanicPhoneNumber, this.state.validators.driver),
            address:  !isValid(this.state.data.address, this.state.validators.address),
   
            // expenseRecurringStartDate: this.isRecurringExpense() && !this.isValidRecurringExpense(),
            // expenseRecurringEndDate: this.isRecurringExpense() && !this.isValidRecurringExpense(),
            // expenseRecurringDays: this.isRecurringExpense() && !isValid(this.state.data.expenseRecurringDays, this.state.validators.expenseRecurringDays),
        }
        return errorData
    }

    // getExpenseChoices = () => {
    //     let expenseForModule = this.state.data.expenseFor
    //     switch (expenseForModule) {
    //         case CONST.CHOICE_DISPATCHER:
    //         case CONST.CHOICE_DRIVER:
    //         case CONST.CHOICE_PERSONNEL:
    //             return this.props.expense_personnel_choices
    //         case CONST.CHOICE_VEHICLE:
    //             return this.props.expense_vehicle_choices
    //         case CONST.CHOICE_CARRIER:
    //         case CONST.CHOICE_COMPANY:
    //             return this.props.expense_carrier_choices
    //         case CONST.CHOICE_FLEET:
    //             return this.props.expense_fleet_choices
    //     }
    // }

    isMaintenanceExpense = () => {
        return (this.state.data.expenseType == 'MAINTENANCE')
    }

    // isFuelExpense = () => {
    //     return (this.state.data.expenseType) == 'FUEL' &&
    //         (this.state.data.expenseFor == 'vehicle')
    // }

    isFleetExpense = () => {
        return (this.state.data.expenseFor == CONST.CHOICE_FLEET)
    }

    isDeductable = () => {
        return (this.isFleetExpense())
    }

    isInvoiceable = () => {
        return (this.isFleetExpense())
    }

    isExpenseModule = (mod) => {
        return (this.state.data.expenseFor == mod)
    }

    // isValidFleetExpense = () => {
    //     if (!this.state.data.expenseIsRecurring) {
    //         return true;
    //     }
    //     let valid =  validateIsValidInterval(null, this.state.data.expenseRecurringStartDate)
 
    //     valid =  valid && validateIsValidInterval(this.state.data.expenseRecurringStartDate, this.state.data.expenseRecurringEndDate)
    //     return valid
    //  }

    // isRecurringExpense = () => {
    //     return this.state.data.expenseIsRecurring
    //  }

    // isValidRecurringExpense = () => {
    //    if (!this.state.data.expenseIsRecurring) {
    //        return true;
    //    }
    //    let maxNumDays = this.props.profile.hasUnlimitedRecurringFrequency ? 360 : 90
    //    let valid =  validateIsValidInterval(null, this.state.data.expenseRecurringStartDate)
    //    let maxDate = getNextDateFrom(maxNumDays, this.state.data.expenseRecurringStartDate)
    //    valid =  valid && validateIsValidInterval(this.state.data.expenseRecurringStartDate, this.state.data.expenseRecurringEndDate)
    //    valid = valid && validateIsValidInterval(this.state.data.expenseRecurringEndDate, maxDate)
    //    return valid
    // }

    // showModule = (module) => {
    //     switch (module) {
    //         case CONST.CHOICE_DRIVER:
    //             return (this.isExpenseModule(CONST.CHOICE_DRIVER) || this.isFleetExpense())
    //         case CONST.CHOICE_VEHICLE:
    //             return (this.isExpenseModule(CONST.CHOICE_VEHICLE) || this.isFleetExpense())
    //         case CONST.CHOICE_CARRIER:
    //             return (this.isExpenseModule(CONST.CHOICE_CARRIER) || this.isFleetExpense())
    //         case CONST.CHOICE_DISPATCHER:
    //             return (this.isExpenseModule(CONST.CHOICE_DISPATCHER))
    //         case CONST.CHOICE_CUSTOMER:
    //             return (this.isExpenseModule(CONST.CHOICE_CUSTOMER) || this.isInvoiceable())
    //     }
    // }
    
    onCarrierChange = () => {
        this.updateValues('driver', {driver: ''})
        this.updateValues('vehicle', {vehicle: ''})

        // this.updateValues('fleet', {fleet: ''})
        // this.updateValues('dispatcher', {dispatcher: ''})
    }

    getCurrentTitle = () => {
        let title =  this.state.data.expenseType
        this.props.updateCurrentTab(CONST.TAB_SAFETY_AND_COMPLIANCE)
        this.props.updateCurrentTitle && this.props.updateCurrentTitle(title)
    }
    
    //@Override
    getExcludeKeys = () => {
          if (!this.state.isNew){
              return ['expenseRecurringStartDate', 'expenseRecurringEndDate', 'expenseRecurringDays']
          }
          return []
    }
    
    hasCustomErrors = () => {
        return !(this.isAddressValid(false, true, true, false))
        
    }

    render() {
        const { classes } = this.props;
        this.getCurrentTitle()
        let totalExpenseAmount = toFloat(this.state.data.expenseQuantity) * toFloat(this.state.data.expenseUnitPrice) + toFloat(this.state.data.expenseFee)
        totalExpenseAmount = roundDisplay(totalExpenseAmount, 2)
        // this.props.updateCurrentTitle && this.props.updateCurrentTitle(this.getCurrentTitle())
        return (
            <React.Fragment>
                {/* <FTitle label={'Expense -  ' + this.state.data.expenseType} /> */}
                <Grid container direction='column' justify='space-between' alignItems='stretch' spacing={3} >
                    <Grid item xs>
                        <FMessage error={this.props.crudServerError}
                            success={this.props.crudServerSuccess} />
                    </Grid>
 
                    
                    <Grid container spacing= {4} xs={12} >
                            <Grid item xs={3} >
                                    <FAutoComplete
                                        url={CONST.API_CARRIERS_URL}
                                        name='carrier'
                                        hasError={this.state.errorData.carrier}
                                        helpMessage={this.state.errorMessage.carrier}
                                        label='Authority'
                                        // queryParam='search'
                                        value={this.state.data.carrier}
                                        defaultValue={this.state.data.carrier}
                                        notifyParent={this.updateValues}
                                        disabled={!this.state.isNew}
                                        notifyDependency={this.onCarrierChange}

                                        />
                                  
                                </Grid>

                            <Grid item xs >
                                    <FSelect
                                        name='expenseMaintenanceCategory'
                                        value={this.state.data.expenseMaintenanceCategory}
                                        hasError={this.state.errorData.expenseMaintenanceCategory}
                                        helpMessage={this.state.errorMessage.expenseMaintenanceCategory}
                                        label='Maintenance Type'
                                        data={this.props.vehicle_maintenanceType_choices}
                                        valueRef='prettyName'
                                        keyRef='prettyName'
                                        notifyParent={this.updateValues} />
                            </Grid>

                           
                            

                                <Grid item xs disabled={this.isNew}>
                                    <FInput
                                        name='expenseTransactionID'
                                        value={this.state.data.expenseTransactionID}
                                        hasError={this.state.errorData.expenseTransactionID}
                                        helpMessage={this.state.errorMessage.expenseTransactionID}
                                        label="Transaction ID"
                                        disabled={!this.state.isNew}
                                        notifyParent={this.updateValues} />
                                </Grid>
                                <Grid item xs>
                                    <FDate
                                        name='expenseTransactionDate'
                                        required={true}
                                        defaultValue={this.state.data.expenseTransactionDate}
                                        hasError={this.state.errorData.expenseTransactionDate}
                                        helpMessage={this.state.errorMessage.expenseTransactionDate}
                                        label="Transaction Date "
                                        notifyParent={this.updateValues} />
                               
                                </Grid>

                              
                        </Grid>

                       
                       
                       
                        <Grid container spacing= {2} xs={12} >
                    
                                <Grid item xs={3}>
                                    <FAutoComplete
                                            url={CONST.API_DRIVERS_URL}
                                            // queryParam={`carrier=${this.state.data.carrier && this.state.data.carrier.id}`}
                                            hasCarrier={true}
                                            carrier={this.state.data.carrier}
                                            name='driver'
                                            hasError={this.state.errorData.driver}
                                            helpMessage={this.state.errorMessage.driver}
                                            label='Driver'
                                            // queryParam='search'
                                            value={this.state.data.driver}
                                            defaultValue={this.state.data.driver}
                                            notifyParent={this.updateValues}
                                            // disabled={!this.state.isNew}
                                            
                                            />
                                   
                                </Grid>
                                <Grid item xs={3}>
                                   <FAutoComplete
                                        url={CONST.API_VEHICLES_URL}
                                        // queryParam={'vehicleType=TRUCK'}
                                        hasCarrier={true}
                                        carrier = {this.state.data.carrier}
                                        name='vehicle'
                                        hasError={this.state.errorData.vehicle}
                                        helpMessage={this.state.errorMessage.vehicle}
                                        label='Truck / Trailer'
                                        // queryParam='search'
                                        value={this.state.data.vehicle}
                                        defaultValue={this.state.data.vehicle}
                                        notifyParent={this.updateValues}
                                        // disabled={!this.state.isNew}
                                        />
                                    
                                </Grid>

                                <Grid item xs={3}>
                                    <FAutoComplete
                                        url={CONST.API_SERVICE_PROVIDER}
                                        queryParam={'companyType=AUTO_SERVICE_PROVIDER'}
                                        hasCarrier={true}
                                        carrier = {this.state.data.carrier}
                                        name='company'
                                        hasError={this.state.errorData.company}
                                        helpMessage={this.state.errorMessage.company}
                                        label='Auto Service Provider'
                                        // queryParam='search'
                                        value={this.state.data.company}
                                        defaultValue={this.state.data.company}
                                        notifyParent={this.updateValues}
                                        // disabled={!this.state.isNew}
                                        // Show add button on autocomplete widget
                                        showAdd={true} 
                                        // Onclick the add button 
                                        onEdit={this.onShowAddCompany}
                                        />
                                  <CompanyPopUp 
                                    dialogEditMode={this.state.showAddCompany}
                                    companyCode = {CONST.CompanyCode.SERVICE_PROVIDER}
                                    notifyDialogClose = {() => this.setState({showAddCompany: false})}
                                    choices={this.props.companyServiceProviderContactTypeChoice}
                                    title={'Auto Service Provider'}
                                    carrier = {this.state.data.carrier}
                                  />
                                </Grid>
                        </Grid>

                        <Grid container spacing= {4} xs={12} className={classes.padding}>
                                <Grid item xs>
                                    <FAddress 
                                        name='address' 
                                        notifyParent={this.updateValues}
                                        address={this.state.data.address}
                                        hasError={this.state.errorData.address}
                                        city={true}
                                        state={true}
                                        
                                    />
                                </Grid>

                        </Grid>
                        {/* <Grid container spacing= {4} xs={12} className={classes.padding}>
                                <Grid item xs>
                                    <FInput
                                        name='expenseMaintenanceMechanicName'
                                        value={this.state.data.expenseMaintenanceMechanicName}
                                        hasError={this.state.errorData.expenseMaintenanceMechanicName}
                                        helpMessage={this.state.errorMessage.expenseMaintenanceMechanicName}
                                        label="Mechanic Name "
                                        notifyParent={this.updateValues} />
                                </Grid>
                                <Grid item xs>
                                    <FInput name='expenseMaintenanceMechanicEmail'
                                        value={this.state.data.expenseMaintenanceMechanicEmail}
                                        hasError={this.state.errorData.expenseMaintenanceMechanicEmail}
                                        helpMessage={this.state.errorMessage.expenseMaintenanceMechanicEmail}
                                        label="Mechanic Email" notifyParent={this.updateValues}
                                    />
                                </Grid>
                                <Grid item xs >
                                    <FInput name='expenseMaintenanceMechanicPhoneNumber'
                                        value={this.state.data.expenseMaintenanceMechanicPhoneNumber}
                                        hasError={this.state.errorData.expenseMaintenanceMechanicPhoneNumber}
                                        helpMessage={this.state.errorMessage.expenseMaintenanceMechanicPhoneNumber}
                                        label="Mechanic Phone Number" notifyParent={this.updateValues}
                                    />
                                </Grid>
                        </Grid> */}
                        <Grid container spacing= {4} xs={12} >
                                
                  
                                <Grid item xs>
                                    <FInput
                                        name='expenseUnitPrice'
                                        required={true}
                                        type='number'
                                        value={this.state.data.expenseUnitPrice}
                                        hasError={this.state.errorData.expenseUnitPrice}
                                        helpMessage={this.state.errorMessage.expenseUnitPrice}
                                        label="Unit Price "
                                        notifyParent={this.updateValues} />
                                </Grid>

                                <Grid item xs>
                                    <FInput
                                        name='expenseQuantity'
                                        required={true}
                                        type='number'
                                        value={this.state.data.expenseQuantity}
                                        hasError={this.state.errorData.expenseQuantity}
                                        helpMessage={this.state.errorMessage.expenseQuantity}
                                        label="Quantity "
                                        notifyParent={this.updateValues} />
                                </Grid>
                                <Grid item xs>
                                    <FInput
                                        name='expenseFee'
                                        type='number'
                                        value={this.state.data.expenseFee}
                                        hasError={this.state.errorData.expenseFee}
                                        helpMessage={this.state.errorMessage.expenseFee}
                                        label="Transaction Fee "
                                        notifyParent={this.updateValues} />
                                </Grid>

                                <Grid item xs>
                                    <FInput
                                        name='expenseAmount'
                                        // required={true}
                                        // type='number'
                                        disabled
                                        value={totalExpenseAmount}
                                        hasError={this.state.errorData.expenseAmount}
                                        helpMessage={this.state.errorMessage.expenseAmount}
                                        label="Total"
                                        notifyParent={this.updateValues} />
                                </Grid>
                        </Grid>
                        <Grid container xs={12}  spacing={2} className={classes.deductable} >
                                <Grid item xs={1.2}>
                                    <FCheckBox
                                            name='expenseIsDeductable'
                                            value={this.state.data.expenseIsDeductable}
                                            hasError={this.state.errorData.expenseIsDeductable}
                                            helpMessage={this.state.errorMessage.expenseIsDeductable}
                                            label=" Deductable"
                                            notifyParent={this.updateValues} />
                                </Grid>
                                <Grid item xs={2} className={classes.space}>
                                    <FInput
                                        disabled={!this.state.data.expenseIsDeductable}
                                        name='expenseDeductablePercentage' 
                                        required={true}
                                        type='number'
                                        value={this.state.data.expenseDeductablePercentage}
                                        hasError={this.state.errorData.expenseDeductablePercentage}
                                        helpMessage={this.state.errorMessage.expenseDeductablePercentage}
                                        label="Driver's deductable percentage "
                                        notifyParent={this.updateValues} />
                                </Grid>
                                
                                <Grid item xs={1.3}  className={classes.deductable}>
                                    <FCheckBox
                                        name='expenseIsReimbursable'
                                        value={this.state.data.expenseIsReimbursable}
                                        value={this.state.data.expenseIsReimbursable}
                                        hasError={this.state.errorData.expenseIsReimbursable}
                                        helpMessage={this.state.errorMessage.expenseIsReimbursable}
                                        label=" Reimbursable"
                                        notifyParent={this.updateValues} />
                                </Grid>
                                <Grid item xs={2}  className={classes.reimbursablePercentage}>
                                    <FInput
                                        disabled={!this.state.data.expenseIsReimbursable}
                                        name='expenseReimbursablePercentage'
                                        required={true}
                                        type='number'
                                        value={this.state.data.expenseReimbursablePercentage}
                                        hasError={this.state.errorData.expenseReimbursablePercentage}
                                        helpMessage={this.state.errorMessage.expenseReimbursablePercentage}
                                        label=" Driver's reimbursable percentage"
                                        notifyParent={this.updateValues} />
                                </Grid>

                                <Grid item xs={4} className={classes.spaceLeft}>
                                    <FFileUpload
                                        value={this.state.data.expenseReceipt}
                                        name='expenseReceipt'
                                        label="Upload Expense Receipt"
                                        hasError={this.state.errorData.expenseReceipt}
                                        helpMessage={this.state.errorMessage.expenseReceipt}
                                        notifyParent={this.updateValues} />
                                </Grid>
                                                         
                        </Grid>
                      
                       <Grid container  xs={8} >
                                
                           {/* <Grid item xs={2.5}>
                                    <FFileUpload
                                        value={this.state.data.expenseReceipt}
                                        name='expenseReceipt'
                                        label="Upload Expense Receipt"
                                        hasError={this.state.errorData.expenseReceipt}
                                        helpMessage={this.state.errorMessage.expenseReceipt}
                                        notifyParent={this.updateValues} />
                                </Grid> */}
                            <Grid item xs >
                                <FInput
                                    name='desc' value={this.state.data.desc} multiline={true}
                                    value={this.state.data.desc}
                                    hasError={this.state.errorData.desc}
                                    helpMessage={this.state.errorMessage.desc}
                                    label="Description" notifyParent={this.updateValues} />
                            </Grid>
                        </Grid>
                      
                    <Grid item container xs={8} >
                            {this.getUpdateButton(this.props.profile, CONST.SAFETY_AND_COMPLINACE_VEHICLE_MAINTENANCES)}
                    </Grid>
                    {/* <Grid item container xs={8} >
                            <FButton color='primary'
                                label={this.state.isNew ? 'Save' : 'Update'}
                                loading={this.state.loading}
                                onClick={this.onSaveData}
                            />
                            <Grid item style={{ paddingTop: '9px' }}>
                               <FRouteBackLink variant='outlined' color='secondary' className={'backButton'} />
                            </Grid>
                    </Grid> */}
            </Grid>

            </React.Fragment>
        );
    };
}

VehicleMaintenanceDetail.propTypes = {
    classes: PropTypes.object.isRequired
};

export default withStyles(styles)(connect(
    mapStateToProps, mapDispatchToProps)(VehicleMaintenanceDetail))
