import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux"
import { withStyles } from "@material-ui/core/styles";
import { Grid } from "@material-ui/core";
import FMessage from "../widgets/FMessage";
import FInput from "../widgets/FInput";
import { mapDispatchToProps, mapStateToProps } from "../../fredux/ActionMaker";
import { API_DOCUMENTS_URL, API_DRIVERS_URL, 
       CHOICE_DRIVER, API_CARRIERS_URL, ADMIN_DRIVERS, TAB_DRIVER_APP } from "../../util/Const";
import { isValid } from "../../util/Validator";
import { VALIDATE_REQUIRED, VALIDATE_PHONE_NUMBER, VALIDATE_EMAIL, VALIDATE_ADDRESS, VALIDATE_ZERO_OR_POSITIVE } from "../../util/ValidatorConst";
import FDetailComponent from "../../util/FDetailComponent";
import FSelect from "../widgets/FSelect";
import FDate from "../widgets/FDate";
import FAutoComplete from "../widgets/FAutoComplete";
import FAddress from "../widgets/FAddress";
import DocumentView from "../document"
import ChangePassword from "../auth/ChangePassword";

import FGroupBox from "../widgets/FGroupBox";
import {Labels} from "../../localizations/Resource"
import FMemo from "../widgets/FMemo";
import FFileUpload from "../widgets/FFileUpload";



const styles = theme => ({
    padding: {
        marginTop: theme.spacing(4),
    },
});

const PROPS = {
    errorData: {
        driverFirstName: false,
        driverLastName: false,
        driverMiddleName: false,
        driverType: false,
        driverDOB: false,
        driverPhoneNumber: false,
        driverEmail: false,
        driverAccessID: false,
        carrier: false,
        driverHiredDate: false,
        driverTotalPerviousEmployments: false,
        driverTotalPerviousEmploymentsDrivingHours: false,
        driverTotalPerviousEmploymentsDrivingMileages: false,
        driverLastDroveDateBeforeCurrentEmployment: false,

        driverTotalViolations: false,
        driverTotalAccidents: false,
       
    },
    errorMessage: {
        driverFirstName: 'Invalid Name',
        driverMiddleName: 'Invalid Name',
        driverLastName: 'Invalid Name',
        driverType: 'Invalid Driver Type',
        driverDOB: 'Invalid Date Of Birth', 
        driverPhoneNumber: 'Invalid Phone Number',
        driverEmail: 'Invalid Email',
        driverAccessID: 'Invalid Driver ID',
        carrier: 'Invalid carrier',
        driverHiredDate: "Invalid Hired Date",
        driverTotalPerviousEmployments: 'Invalid Input',
        driverTotalPerviousEmploymentsDrivingHours: 'Invalid Input',
        driverTotalPerviousEmploymentsDrivingMileages: 'Invalid Input',
        driverLastDroveDateBeforeCurrentEmployment: 'Invalid Input',
        
        driverTotalViolations: 'Invalid Input',
        driverTotalAccidents: 'Invalid Input',
       
    },
    data: {
        driverFirstName: '',
        driverMiddleName: '',
        driverLastName: '',
        driverType: '',
        driverDOB: new Date(),
        driverPhoneNumber: '',
        driverEmail: '',
        driverAccessID: '',
        address: '',
        driverHiredDate: '',
        driverTotalPerviousEmployments: 0,
        driverTotalPerviousEmploymentsDrivingHours: 0,
        driverTotalPerviousEmploymentsDrivingMileages: 0,
        driverLastDroveDateBeforeCurrentEmployment: null,
        driverTotalViolations: 0,
        driverTotalAccidents: 0,
        profilePicture: "",
        carrier: {},
        profile: {},
    },
    validators: {
        driverFirstName: [VALIDATE_REQUIRED],
        driverMiddleName: [],
        driverLastName: [VALIDATE_REQUIRED],
        driverType: [VALIDATE_REQUIRED],
        driverDOB: [VALIDATE_REQUIRED],
        driverPhoneNumber: [VALIDATE_PHONE_NUMBER],
        driverEmail: [VALIDATE_EMAIL],
        driverAccessID: [],
        carrier: [VALIDATE_REQUIRED],
        driverHiredDate:  [VALIDATE_REQUIRED],
        address: [VALIDATE_ADDRESS],
        driverTotalPerviousEmployments: [VALIDATE_ZERO_OR_POSITIVE],
        driverTotalPerviousEmploymentsDrivingHours: [VALIDATE_ZERO_OR_POSITIVE],
        driverTotalPerviousEmploymentsDrivingMileages: [VALIDATE_ZERO_OR_POSITIVE],
        driverLastDroveDateBeforeCurrentEmployment: [],
        driverTotalViolations: [VALIDATE_ZERO_OR_POSITIVE],
        driverTotalAccidents: [VALIDATE_ZERO_OR_POSITIVE],
        
    },
    touched: {}
}

class DriverDetial extends FDetailComponent {

    constructor(props) {
        super(props, API_DRIVERS_URL, CHOICE_DRIVER, PROPS)
    }

    getProfileId = () => {
        return this.state.data.profile.id
    }

    getErrorData = () => {
        let errorData = {
            driverFirstName: !isValid(this.state.data.driverFirstName, this.state.validators.driverFirstName),
            driverLastName: !isValid(this.state.data.driverLastName, this.state.validators.driverLastName),
            driverType: !isValid(this.state.data.driverType, this.state.validators.driverType),
            driverDOB: !isValid(this.state.data.driverDOB, this.state.validators.driverDOB),

            driverPhoneNumber: !isValid(this.state.data.driverPhoneNumber, this.state.validators.driverPhoneNumber),
            driverEmail: !isValid(this.state.data.driverEmail, this.state.validators.driverEmail),
            carrier: !isValid(this.state.data.carrier, this.state.validators.carrier),
            driverHiredDate: !isValid(this.state.data.driverHiredDate, this.state.validators.driverHiredDate),
            address: !isValid(this.state.data.address, this.state.validators.address),
            driverTotalPerviousEmployments: !isValid(this.state.data.driverTotalPerviousEmployments, this.state.validators.driverTotalPerviousEmployments),
            driverTotalPerviousEmploymentsDrivingHours: !isValid(this.state.data.driverTotalPerviousEmploymentsDrivingHours, this.state.validators.driverTotalPerviousEmploymentsDrivingHours),
            driverTotalPerviousEmploymentsDrivingMileages: !isValid(this.state.data.driverTotalPerviousEmploymentsDrivingMileages, this.state.validators.driverTotalPerviousEmploymentsDrivingMileages),
            driverLastDroveDateBeforeCurrentEmployment: !isValid(this.state.data.driverLastDroveDateBeforeCurrentEmployment, this.state.validators.driverLastDroveDateBeforeCurrentEmployment),
            driverTotalAccidents: !isValid(this.state.data.driverTotalAccidents, this.state.validators.driverTotalAccidents),
            driverTotalViolations: !isValid(this.state.data.driverTotalViolations, this.state.validators.driverTotalViolations)

        }
        return errorData
    }

    getCurrentTitle = () => {
        let title =  ' Driver ' + this.state.data.driverFirstName + ' ' + this.state.data.driverLastName
        this.props.updateCurrentTab(TAB_DRIVER_APP)
        this.props.updateCurrentTitle && this.props.updateCurrentTitle(title)
    }

    hasCustomErrors = () => {
        return !(this.isAddressValid(true, true, true))
     }

    render() {
        const { classes } = this.props;
        this.getCurrentTitle()
        return (
            <React.Fragment>
                {/* <FTitle label={'Driver -  ' + this.state.data.driverFirstName + ' ' + this.state.data.driverLastName} /> */}
                <Grid container direction='column' justify='space-between' alignItems='stretch' spacing={3} >
                    <Grid item xs>
                        <FMessage error={this.props.crudServerError}
                            success={this.props.crudServerSuccess} />
                    </Grid>
                    <FGroupBox groupTitle={Labels.DRIVER_GENERAL_INFO_SUMMARY} >

                    <Grid container className={classes.root} spacing= {2} xs={12} >
                           <Grid item xs>
                                        <FAutoComplete
                                            url={API_CARRIERS_URL}
                                            required
                                            name='carrier'
                                            hasError={this.state.errorData.carrier}
                                            helpMessage={this.state.errorMessage.carrier}
                                            // label='Authority'
                                            label={Labels.CARRIER} 
                                            // queryParam={'prettyName=true'}
                                            // queryParam='search'
                                            value={this.state.data.carrier}
                                            defaultValue={this.state.data.carrier}
                                            notifyParent={this.updateValues}
                                            disabled={!this.state.isNew}
                                            />
                                </Grid>
                            <Grid item xs>
                                            <FInput name='username' required
                                                value={this.state.data.profile.username}
                                                disabled={true}
                                                hasError={this.state.errorData.username}
                                                helpMessage={this.state.errorMessage.username}
                                                // label="User Name"
                                                label={Labels.DRIVER_APP_USERNAME} 
                                                notifyParent={this.updateValues}
                                            />
                                    </Grid>  
                                {/* <Grid item xs hidden={this.state.isNew}>
                                        <FInput name='driverAccessID' required
                                            value={this.state.data.driverAccessID}
                                            hasError={this.state.errorData.driverAccessID}
                                            helpMessage={this.state.errorMessage.driverAccessID}
                                            // label="Driver ID" 
                                            label={Labels.DRIVER_ID} 
                                            disabled={true}
                                            notifyParent={this.updateValues}
                                        />
                                </Grid> */}
                                 <Grid item xs>
                                    <FInput name='driverEmail' required
                                        value={this.state.data.driverEmail}
                                        hasError={this.state.errorData.driverEmail}
                                        helpMessage={this.state.errorMessage.driverEmail}
                                        // label="Email"
                                        label={Labels.EMAIL} 
                                         notifyParent={this.updateValues}
                                    />
                                </Grid>
                                <Grid item xs>
                                    <FSelect
                                        name='driverType'
                                        value={this.state.data.driverType}
                                        hasError={this.state.errorData.driverType}
                                        helpMessage={this.state.errorMessage.driverType}
                                        // label='Driver Type'
                                        label={Labels.DRIVER_TYPE} 
                                        data={this.props.driver_driverType_choices}
                                        valueRef='prettyName'
                                        keyRef='prettyName'
                                        notifyParent={this.updateValues}
                                    />
                                </Grid>
                                <Grid item xs>
                                    <FDate
                                    name='driverHiredDate'
                                    required={true}
                                    defaultValue={this.state.data.driverHiredDate}
                                    hasError={this.state.errorData.driverHiredDate}
                                    helpMessage={this.state.errorMessage.driverHiredDate}
                                    // label="Job Start Date"
                                    label={Labels.DRIVER_HIRING_DATE} 
                                    notifyParent={this.updateValues} 
                                    />
                                </Grid>
                                
                    </Grid>
                    <Grid container className={classes.root} spacing= {4} xs={12} >
                                <Grid item xs>
                                    <FInput name='driverFirstName' required
                                        value={this.state.data.driverFirstName}
                                        hasError={this.state.errorData.driverFirstName}
                                        helpMessage={this.state.errorMessage.driverFirstName}
                                        // label="First Name" 
                                         label={Labels.FIRST_NAME} 
                                        notifyParent={this.updateValues}
                                    />
                                </Grid>
                                <Grid item xs>
                                    <FInput name='driverMiddleName'
                                        value={this.state.data.driverMiddleName}
                                        hasError={this.state.errorData.driverMiddleName}
                                        helpMessage={this.state.errorMessage.driverMiddleName}
                                        // label="Middle Name"
                                        label={Labels.MIDDLE_NAME} 
                                         notifyParent={this.updateValues}
                                    />
                                </Grid>
                                <Grid item xs>
                                    <FInput name='driverLastName' required
                                        value={this.state.data.driverLastName}
                                        hasError={this.state.errorData.driverLastName}
                                        helpMessage={this.state.errorMessage.driverLastName}
                                        // label="Last Name" 
                                        label={Labels.LAST_NAME} 
                                        notifyParent={this.updateValues}
                                    />
                                </Grid>
                                <Grid item xs>
                                    <FDate
                                    name='driverDOB'
                                    required={true}
                                    defaultValue={this.state.data.driverDOB}
                                    hasError={this.state.errorData.driverDOB}
                                    helpMessage={this.state.errorMessage.driverDOB}
                                    // label="Date Of Birth "
                                    label={Labels.DATE_OF_BIRTH} 
                                    notifyParent={this.updateValues} 
                                    />
                                </Grid>
                               
                                <Grid item xs>
                                    <FInput name='driverPhoneNumber' required
                                        value={this.state.data.driverPhoneNumber}
                                        hasError={this.state.errorData.driverPhoneNumber}
                                        helpMessage={this.state.errorMessage.driverPhoneNumber}
                                        // label="Phone Number"
                                        label={Labels.PHONE_NUMBER} 
                                         notifyParent={this.updateValues}
                                    />
                                </Grid>

                               
                                
                    </Grid>
                   
    
                    <Grid container className={classes.root} spacing= {4} xs={12} >
                    <Grid item xs>  
                        <FAddress 
                                        name='address' 
                                        notifyParent={this.updateValues}
                                        address={this.state.data.address}
                                        hasError={this.state.errorData.address}
                                        place={true}
                                        city={true}
                                        state={true}
                                        zipCode={true}
                            />
                           </Grid>

                           <Grid item xs={2}>
                                    <FFileUpload
                                        value={this.state.data.profilePicture}
                                        name='profilePicture'
                                        label="Profile Picture"
                                        hasError={this.state.errorData.profilePicture}
                                    helpMessage={this.state.errorMessage.profilePicture}
                                        notifyParent={this.updateValues} />
                            </Grid>
                    </Grid >
               </FGroupBox>
               <FGroupBox groupTitle={Labels.DRIVER_PREVIOUS_EMPLOYMENT_SUMMARY} >
                    <Grid container className={classes.root} spacing= {2} xs={12} >
                               <Grid item xs>
                                    <FInput
                                        name='driverTotalPerviousEmployments'
                                        required
                                        type='number'
                                        value={this.state.data.driverTotalPerviousEmployments}
                                        hasError={this.state.errorData.driverTotalPerviousEmployments}
                                        helpMessage={this.state.errorMessage.driverTotalPerviousEmployments}
                                        // label="Total Recorded Pervious Employments"
                                        label={Labels.TOTAL_NUM_PREVIOUS_EMPLOYERS} 
                                        notifyParent={this.updateValues} />
                                </Grid>
                                <Grid item xs>
                                    <FInput
                                        name='driverTotalPerviousEmploymentsDrivingHours'
                                        required={true}
                                        type='number'
                                        value={this.state.data.driverTotalPerviousEmploymentsDrivingHours}
                                        hasError={this.state.errorData.driverTotalPerviousEmploymentsDrivingHours}
                                        helpMessage={this.state.errorMessage.driverTotalPerviousEmploymentsDrivingHours}
                                        // label="Total Driving Hours (Previous Employments) "
                                        label={Labels.TOTAL_WORKING_HOURS_PREVIOUS_EMPLOYERS} 
                                        notifyParent={this.updateValues} />
                                </Grid>
                                <Grid item xs>
                                    <FInput
                                        name='driverTotalPerviousEmploymentsDrivingMileages'
                                        required={true}
                                        type='number'
                                        value={this.state.data.driverTotalPerviousEmploymentsDrivingMileages}
                                        hasError={this.state.errorData.driverTotalPerviousEmploymentsDrivingMileages}
                                        helpMessage={this.state.errorMessage.driverTotalPerviousEmploymentsDrivingMileages}
                                        // label="Total Drove Mileages (Previous Employments) "
                                        label={Labels.TOTAL_WORKING_MILEAGES_PREVIOUS_EMPLOYERS} 
                                        notifyParent={this.updateValues} />
                                </Grid>
                                
                                <Grid item xs>
                                    <FDate
                                    name='driverLastDroveDateBeforeCurrentEmployment'
                                    required={true}
                                    // minDate={true}
                                    maxDate={true}
                                    defaultValue={this.state.data.driverLastDroveDateBeforeCurrentEmployment}
                                    hasError={this.state.errorData.driverLastDroveDateBeforeCurrentEmployment}
                                    helpMessage={this.state.errorMessage.driverLastDroveDateBeforeCurrentEmployment}
                                    // label="Last Drove date (Previous Employment )"
                                    label={Labels.LAST_EMPLOYMENT_DATE_PREVIOUS_EMPLOYER} 
                                    notifyParent={this.updateValues} 
                                    />
                                </Grid>
                    </Grid>
                    </FGroupBox>
                    <FGroupBox groupTitle={Labels.DRIVER_UP_TO_DATE_VIOLATION_SUMMARY} >

                    <Grid container className={classes.root} spacing= {4} xs={12} >
                               <Grid item xs>
                                    <FInput
                                        name='driverTotalViolations'
                                        type='number'
                                        value={this.state.data.driverTotalViolations}
                                        hasError={this.state.errorData.driverTotalViolations}
                                        helpMessage={this.state.errorMessage.driverTotalViolations}
                                        // label="Total Violations (including Previous Employments)"
                                        label={Labels.TOTAL_VIOLATIONS} 
                                        notifyParent={this.updateValues} />
                                </Grid>
                                <Grid item xs>
                                    <FInput
                                        name='driverTotalAccidents'
                                        type='number'
                                        value={this.state.data.driverTotalAccidents}
                                        hasError={this.state.errorData.driverTotalAccidents}
                                        helpMessage={this.state.errorMessage.driverTotalAccidents}
                                        label={Labels.TOTAL_ACCIDENTS} 
                                        // label="Total Accidents (including Previous Employments)"
                                        notifyParent={this.updateValues} />
                                </Grid>
                    </Grid>
                    </FGroupBox>
                    {/* <Grid container className={classes.root} spacing= {4} xs={12} >
                           
                            <Grid item xs>
                                 <FInput
                                    name='desc' value={this.state.data.desc} multiline={true}
                                    value={this.state.data.desc}
                                    hasError={this.state.errorData.desc}
                                    helpMessage={this.state.errorMessage.desc}
                                    label="Description" notifyParent={this.updateValues} />
                            </Grid>
                    </Grid > */}
                    <FMemo
                            name='desc' value={this.state.data.desc} multiline={true}
                            value={this.state.data.desc}
                            hasError={this.state.errorData.desc}
                            helpMessage={this.state.errorMessage.desc}
                            // label="Description" 
                            rows={3}
                            // label={Labels.DESCRIPTION_MEMO} 
                            notifyParent={this.updateValues} 
                    />
                    <Grid container className={classes.root} spacing= {4} xs={12} >
                        {/* <Grid item container xs={8} >
                            <FButton color='primary'
                                label={this.state.isNew ? 'Save' : 'Update'}
                                loading={this.state.loading}
                                onClick={this.onSaveData}
                            />
                            <Grid item style={{ paddingTop: '9px' }}>
                            <FRouteBackLink variant='outlined' color='secondary' className={'backButton'} />
                            </Grid>
                        </Grid> */}
                        <Grid item container xs={8} >
                            {this.getUpdateButton(this.props.profile, ADMIN_DRIVERS)}
                        </Grid>
                    </Grid>
                    <Grid container >
                       {this.state.data.profile && this.state.data.profile.id &&
                            <Grid item xs={12}>
                                 <ChangePassword profileId={this.getProfileId()} module="driver profile" />
                             </Grid>
                        }
                        </Grid>

                    <Grid item xs className={classes.padding}>
                        {!this.state.isNew &&
                            <DocumentView
                                ownerId={this.props.match.params.id}
                                owner={this.state.data}
                                listUrl={API_DOCUMENTS_URL + `?&driver=${this.props.match.params.id}`}
                                ownerType='driver'
                                policy_id={ADMIN_DRIVERS}
                            />}
                    </Grid>
                </Grid >

            </React.Fragment>
        );
    };
}

DriverDetial.propTypes = {
    classes: PropTypes.object.isRequired
};

export default withStyles(styles)(connect(
    mapStateToProps, mapDispatchToProps)(DriverDetial))
