import React from 'react';
import { Link } from '@material-ui/core';
import FListComponent from '../../util/FListComponent';
import { mapStateToProps, mapDispatchToProps } from '../../fredux/ActionMaker';
import { connect } from "react-redux"

import FRouterLink from '../widgets/FRouterLink';

import { ROUTE_VEHICLE_MAINTENANCE_URL, ROUTE_DRIVER_URL, API_VEHICLE_MAINTENANCE_URL, TAB_SAFETY_AND_COMPLIANCE, SAFETY_AND_COMPLINACE_VEHICLE_MAINTENANCES, CHOICE_MAINTENANCE_RECORD } from '../../util/Const';
import { roundDecimal } from '../../util';
import FMaterialTable from '../widgets/FMaterialTable';
import FTableAction from '../widgets/FTableAction';
import { isProfileDispatcher } from '../../util/ACL';
import AttachmentIcon from '@material-ui/icons/Attachment';
import { green } from '@material-ui/core/colors';
import BlockIcon from '@material-ui/icons/Block';

class VehicleMaintenances extends FListComponent {

  constructor(props) {
    super(props, API_VEHICLE_MAINTENANCE_URL, CHOICE_MAINTENANCE_RECORD, `expenseType=MAINTENANCE&`)
    this.currentTitle = 'Vehicle Maintenance'
    this.currentTab = TAB_SAFETY_AND_COMPLIANCE
    this.routeUrl = ROUTE_VEHICLE_MAINTENANCE_URL
    this.policyID = SAFETY_AND_COMPLINACE_VEHICLE_MAINTENANCES
    this.includeStateAction = false
  }

  getExpenseFor = (rowData) => {
   let expenseFor = rowData.expenseFor
   if (expenseFor != 'fleet') {
     return expenseFor
   }
   return (
    <React.Fragment>
      <FRouterLink label={rowData.driver.prettyName} 
         to={ROUTE_DRIVER_URL + rowData.driver.id}
         disabled={isProfileDispatcher(this.props.profile)}/>
    </React.Fragment>
  )
  }

  // getActions = (rowData) => {
  //   let actions = []
  //   // rowData.state == 'ACTIVE'? actions.push(ACTION_TABLE_DEACTIVATE): 
  //   //       actions.push(ACTION_TABLE_ACTIVATE)
  //   actions.push(ACTION_TABLE_DELETE_ROW)
  //   return actions
  // }

  render() {
    return (
      <div>
        <FMaterialTable
          tableRef={this.tableRef}
          columns={[
            {
              title: 'Transaciton #', 
              field: 'expenseTransactionID',
              render: rowData => {
                return (
                  <React.Fragment>
                    <FRouterLink editIcon={true} label={rowData.expenseTransactionID} to={ROUTE_VEHICLE_MAINTENANCE_URL + rowData.id} />
                  </React.Fragment>
                )
              }
            },
            {
              title: 'Vehicle #', 
              render: this.getVehicleLink
            },
            // {
            //   title: 'Vehicle #', 
            //   field: 'prettyName',
            //   render: rowData => {
            //     return (
            //       <React.Fragment>
            //         <FRouterLink label={rowData.vehicle && rowData.vehicle.prettyName} to={ROUTE_VEHICLE_URL + rowData.vehicle.id} />
            //       </React.Fragment>
            //     )
            //   }
            // },
            // {
            //   title: 'Driver', field: 'driver.prettyName',
            //   render: rowData => {
            //     return (
            //       <React.Fragment>
            //         <FRouterLink label={rowData.driver.prettyName} 
            //            to={ROUTE_DRIVER_URL + rowData.driver.id}
            //            disabled={isProfileDispatcher(this.props.profile)}/>
            //       </React.Fragment>
            //     )
            //   }
            // },
            {
              title: 'Driver', 
              render: this.getDriverLink
            },
            { title: 'Maintenance Date / Receipt', field: 'expenseTransactionDate', 
              
               render:  rowData => {
                  const td = this.getPrettyDate(rowData.expenseTransactionDate)
                  return (
                    <React.Fragment>
                          
                          {td} { rowData.expenseReceipt && <Link href={rowData.expenseReceipt} target="_blank"> <AttachmentIcon style={{ fontSize: 25, color: green[500] }}/></Link>}

                    </React.Fragment>
                  )
                }
            },
            
            {title: 'Maintenance Type', field: 'expenseMaintenanceCategory'},
            { title: 'Amount ($)', field: 'expenseAmount',  render: rowData => roundDecimal(rowData.expenseAmount)},
            // { title: 'Description', field: 'desc' },
            {title: 'Desc', field: 'desc'},
            // {
            //   title: 'Actions', 
            //   render: rowData => {
            //     return (
            //       <React.Fragment>
            //         <FTableAction 
            //             actions={this.getActions(rowData)}
            //             onTableAction = {this.onTableAction}
            //             rowData={rowData}
            //              />
            //       </React.Fragment>
            //     )
            //   }
            // },
            {
              title: 'Actions', 
              render: rowData => {
                let actions  = this.getActions(rowData, SAFETY_AND_COMPLINACE_VEHICLE_MAINTENANCES)

                return (
                  <React.Fragment>
                    {!!actions.length && <FTableAction 
                        actions={actions}
                        onTableAction = {this.onTableAction}
                        rowData={rowData}
                         />
                    }
                    {!!!actions.length && <BlockIcon color="disabled" />}
                  </React.Fragment>
                )
              }
            },
          ]}
          actions={
            [
              // {
              //   icon: () => (
              //     <FRouterAddLink to={ROUTE_VEHICLE_MAINTENANCE_URL + 'add'} color='secondary' />
              //   ),
              //   isFreeAction: true
              // },
              this.getAddNewModelActions()

            ]}
          options = {{
              pageSize: this.state.pageSize,
              pageSizeOptions: this.state.pageSizeOptions,
              actionsColumnIndex: -1
           }}
          data={this.fetchData}
          title="Vehicle Maintenance"
          listErrCode={this.state.listErrCode}
      
        />
      </div>
    );
  }
}


export default connect(
  mapStateToProps, mapDispatchToProps)(VehicleMaintenances)
