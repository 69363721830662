import React from 'react'

import { Grid } from "@material-ui/core"
import FDate from '../../widgets/FDate';
import { connect } from 'react-redux'
import { mapDispatchToProps, mapStateToProps } from '../../../fredux/ActionMaker';
import { withStyles } from '@material-ui/core/styles'
import FAutoComplete from "../../widgets/FAutoComplete";
import { API_CARRIERS_URL, API_VEHICLES_URL, API_DRIVERS_URL, API_CHOICE_URL } from '../../../util/Const';
import FSelect from '../../widgets/FSelect';
import FButtonDropDown from '../../widgets/FButtonDropDown';


const styles = theme => ({
  root: {
     width: '100%'
  },
  dropDown: {
  },

  textNumber: {
    marginTop: theme.spacing(1),
  },
  generateButon: {
    marginTop: 2 * theme.spacing(1),
  },

  date: {
    width: '100%'
  },

  button: {
    marginTop: 3* theme.spacing(1),
  },
}); 

class ExpenseReportHeader extends React.Component {

  render() {
    const {classes} = this.props
    return (
      <React.Fragment>
      <Grid container className={classes.root} spacing= {1}  xs={12} >
      <Grid item xs>
            <FDate
              name='reportStartDate'
              className={classes.date}
              required={true}
              defaultValue={this.props.data.reportStartDate}
              hasError={this.props.errorData.reportStartDate}
              helpMessage={this.props.errorMessage.reportStartDate}
              label="Date From "
              notifyParent={this.props.updateValues} />
          </Grid>
          <Grid item xs>
            <FDate
              name='reportEndDate'
              className={classes.date}
              required={true}
              defaultValue={this.props.data.reportEndDate}
              hasError={this.props.errorData.reportEndDate}
              helpMessage={this.props.errorMessage.reportEndDate}
              label="Date To "
              notifyParent={this.props.updateValues} />
          </Grid>
          <Grid item xs className={classes.dropDown}>
          <FAutoComplete
                url={API_CARRIERS_URL}
                name='carrier'
                style={{width: 150}}
                hasError={this.props.errorData.carrier}
                helpMessage={this.props.errorMessage.carrier}
                label='Authority'
                // queryParam='search'
                value={this.props.data.carrier}
                defaultValue={this.props.data.carrier}
                notifyParent={this.props.updateValues}
                // disabled={!this.props.isNew}
                notifyDependency={this.onCarrierChange}
             />
          </Grid>
          <Grid item xs>
              <FSelect
                    name='expenseFor'
                    value={this.props.data.expenseFor}
                    hasError={this.props.errorData.expenseFor}
                    helpMessage={this.props.errorMessage.expenseFor}
                    label='Expense For'
                    data={this.props.expenseForModuleChoices}
                    valueRef='prettyName'
                    keyRef='prettyName'
                    notifyParent={this.props.updateValues}
                />
          </Grid>
          <Grid item xs className={classes.dropDown}>
          <FAutoComplete
                  url={API_DRIVERS_URL}
                  // queryParam={`carrier=${this.props.data.carrier && this.props.data.carrier.id}`}
                  hasCarrier={true}
                  style={{width: 150}}
                  carrier={this.props.data.carrier}
                  name='driver'
                  hasError={this.props.errorData.driver}
                  helpMessage={this.props.errorMessage.driver}
                  label='Driver'
                  // queryParam='search'
                  value={this.props.data.driver}
                  defaultValue={this.props.data.driver}
                  notifyParent={this.props.updateValues}
                  // disabled={!this.props.isNew}
            />
          </Grid>
          <Grid item xs className={classes.dropDown}>
            <FAutoComplete
                    url={API_VEHICLES_URL}
                    style={{width: 150}}
                    queryParam={'vehicleType=TRUCK'}
                    hasCarrier={true}
                    carrier = {this.props.data.carrier}
                    name='vehicle'
                    hasError={this.props.errorData.vehicle}
                    helpMessage={this.props.errorMessage.vehicle}
                    label='Truck'
                    // queryParam='search'
                    value={this.props.data.vehicle}
                    defaultValue={this.props.data.vehicle}
                    notifyParent={this.props.updateValues}
                    // disabled={!this.props.isNew}
                />
          </Grid>
         
          <Grid item xs>
          <FAutoComplete
                    url={API_CHOICE_URL}
                    style={{width: 250}}
                    queryParam={'choiceCategory=expense&excludePayCheck=1'}
                    // hasCarrier={true}
                    // carrier = {this.props.data.carrier}
                    name='expenseType'
                    hasError={this.props.errorData.expenseType}
                    helpMessage={this.props.errorMessage.expenseType}
                    label='Expense Type'
                    // queryParam='search'
                    value={this.props.data.expenseType && this.props.data.expenseType}
                    defaultValue={this.props.data.expenseType}
                    notifyParent={this.props.updateValues}
                    // disabled={!this.props.isNew}
                />
              {/* <FSelect
                  name='expenseType'
                  value={this.props.data.expenseType}
                  hasError={this.props.errorData.expenseType}
                  helpMessage={this.props.errorMessage.expenseType}
                  label='Expense Type'
                  data={getExpenseChoices(this.props)}
                  valueRef='prettyName'
                  keyRef='prettyName'
                  notifyParent={this.props.updateValues}
              /> */}
          </Grid>
         

          <Grid item xs style={{padding: "27px"}}>
                <FButtonDropDown
                    options= {['Download QUICKBOOKS', 'Download ZTRUCKING']}
                    values= {['&fmt=quickbooks', '&fmt=ztrucking']}
                    onClick={this.props.onDownload}
                />
          {/* <FButton color='primary'
                  disabled={this.props.disabled}
                  className={classes.button}
                  label={'Download CSV'}
                  loading={this.props.loading}
                  onClick={this.props.onDownload}
              /> */}
          </Grid>
          
        </Grid>
        
        </React.Fragment>    
    )
  }
}


export default withStyles(styles)(connect(
  mapStateToProps, mapDispatchToProps)(ExpenseReportHeader))
