import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux"
import { withStyles } from "@material-ui/core/styles";


import { Grid } from "@material-ui/core";
import FMessage from "../widgets/FMessage";
import FInput from "../widgets/FInput";
import { mapDispatchToProps, mapStateToProps } from "../../fredux/ActionMaker";
import { API_DOCUMENTS_URL, API_VEHICLES_URL, TAB_ADMIN,
        CHOICE_VEHICLE, API_DRIVERS_URL, API_CARRIERS_URL, ADMIN_VEHICLES } from "../../util/Const";
import DocumentView from "../document"
import { isValid } from "../../util/Validator";
import { VALIDATE_CARRIER_REQUIRED, VALIDATE_REQUIRED, VALIDATE_OBJECT_REQUIRED, VALIDATE_VIN_REQUIRED, VALIDATE_UPTO_CURRENT_YEAR, VALIDATE_POSITIVE } from "../../util/ValidatorConst";
import FDetailComponent from "../../util/FDetailComponent";
import FSelect from "../widgets/FSelect";
import FAutoComplete from "../widgets/FAutoComplete";
import { COUNTRY_STATES_NAMES } from "../../fredux/Const";
import FDate from "../widgets/FDate";
import FFileUpload from "../widgets/FFileUpload";

const styles = theme => ({
 
    padding: {
        marginTop: theme.spacing(2),
        marginBotom: theme.spacing(2),
    },
    dropDown: {
        marginTop: theme.spacing(1),
        marginLeft: theme.spacing(1),
        
      },
});

const PROPS = {
    errorData: {
        vehicleMake: false,
        vehicleModel: false,
        vehicleYear: false,
        vehicleNumber: false,
        vehicleVIN: false,
        vehicleType: false,
        vehiclePlateNumber: false,
        trailer: false,
        driver: false,
        carrier: false,
        vehicleStartDate: false,
        vehiclePlateState: false,
        vehicleCurrentOdometer: false, 
        

    },
    errorMessage: {
        vehicleMake: 'Invalid Vehicle Make',
        vehicleModel: 'Invalid Vehicle Model',
        vehicleYear: 'Invalid Year',
        vehicleNumber: 'Invalid Vehicle Number',
        vehicleVIN: 'Invalid VIN, needs to be atleast 11 Alpha Numeric character',
        vehicleType: 'Invalid Vehicle Type',
        vehiclePlateNumber: 'Invalid Plate Number',
        trailer: 'Invalid Trailer Data',
        driver: 'No Driver Selected/Found  Under Carrier',
        carrier: 'Invalid Carrier',
        vehicleStartDate: 'Invalid Start Date',
        vehiclePlateState: 'Invalid State',
        vehicleCurrentOdometer: 'Invalid Odometer Reading, should be positive number', 
    },
    data: {
        vehicleMake: '',
        vehicleModel: '',
        vehicleYear: '',
        vehicleYear: '',
        vehicleNumber: '',
        vehicleVIN: '',
        vehicleType: '',
        vehiclePlateNumber: '',
        trailer: {},
        driver: {},
        carrier: {},
        vehicleStartDate: '',
        vehiclePlateState: '',
        vehicleCurrentOdometer: '', 
        vehiclePicture: ''
    },
    validators: {
        vehicleMake: [VALIDATE_REQUIRED],
        vehicleModel: [VALIDATE_REQUIRED],
        vehicleYear: [VALIDATE_UPTO_CURRENT_YEAR],
        vehicleNumber: [VALIDATE_REQUIRED],
        vehicleVIN: [VALIDATE_VIN_REQUIRED],
        vehicleType: [VALIDATE_REQUIRED],
        vehiclePlateNumber: [VALIDATE_REQUIRED],
        trailer: [],
        driver: [VALIDATE_OBJECT_REQUIRED],
        carrier: [VALIDATE_CARRIER_REQUIRED],
        vehicleStartDate: [VALIDATE_REQUIRED],
        vehiclePlateState: [VALIDATE_REQUIRED],
        vehicleCurrentOdometer: [VALIDATE_POSITIVE], 
    },
    touched: {},
}

class VehicleDetial extends FDetailComponent {

    constructor(props) {
        super(props, API_VEHICLES_URL, CHOICE_VEHICLE, PROPS)
    }

    hasTrailer = () => {

        let isVehicleWithTrailer = this.state.data.vehicleType && this.state.data.vehicleType != 'TRAILER'
        return !!isVehicleWithTrailer
    }

    isTrailer = () => {
        let isTrailer = this.state.data.vehicleType && this.state.data.vehicleType == 'TRAILER'
        return !!isTrailer
    }
    
 
    getErrorData = () => {
        let errorData = {
            vehicleMake: !isValid(this.state.data.vehicleMake, this.state.validators.vehicleMake),
            vehicleModel: !isValid(this.state.data.vehicleModel, this.state.validators.vehicleModel),
            vehicleYear: !isValid(this.state.data.vehicleYear, this.state.validators.vehicleYear),
            vehicleNumber: !isValid(this.state.data.vehicleNumber, this.state.validators.vehicleNumber),
            vehicleVIN: !isValid(this.state.data.vehicleVIN, this.state.validators.vehicleVIN),
            vehicleStartDate: !isValid(this.state.data.vehicleStartDate, this.state.validators.vehicleStartDate),
            vehiclePlateState: !isValid(this.state.data.vehiclePlateState, this.state.validators.vehiclePlateState),
            vehicleType: !isValid(this.state.data.vehicleType, this.state.validators.vehicleType),
            
            vehiclePlateNumber: !isValid(this.state.data.vehiclePlateNumber, this.state.validators.vehiclePlateNumber),
            driver: !isValid(this.state.data.driver, this.state.validators.driver),
            carrier: !isValid(this.state.data.carrier, this.state.validators.carrier),
            trailer: !isValid(this.state.data.trailer, this.state.validators.trailer),
            vehicleCurrentOdometer: !isValid(this.state.data.vehicleCurrentOdometer, this.state.validators.vehicleCurrentOdometer),
        }
        return errorData
    }
    
    onCarrierChange = () => {
        this.updateValues('driver', {driver: ''})
        this.updateValues('trailer', {trailer: ''})
    }

    getCurrentTitle = () => {
        let title = 'Vehicle ' + this.state.data.vehicleNumber
        this.props.updateCurrentTab(TAB_ADMIN)
        this.props.updateCurrentTitle && this.props.updateCurrentTitle(title)
    }

    render() {
        const { classes } = this.props;
        // this.props.updateCurrentTitle && this.props.updateCurrentTitle(this.getCurrentTitle())
        this.getCurrentTitle()

        return (
            <React.Fragment>
                {/* <FTitle label={'Vehicle -  ' + this.state.data.vehicleNumber} /> */}
                <Grid container direction='column' justify='space-between' alignItems='stretch' spacing={3} >
                    <Grid item xs>
                        <FMessage error={this.props.crudServerError}
                            success={this.props.crudServerSuccess} />
                    </Grid>
                    <Grid container  spacing= {4} xs={12} >
                      <Grid item xs>
                        <FAutoComplete
                            url={API_CARRIERS_URL}
                            name='carrier'
                            hasCarrier={false}
                            hasError={this.state.errorData.carrier}
                            helpMessage={this.state.errorMessage.carrier}
                            label='Authority'
                            // queryParam='search'
                            value={this.state.data.carrier}
                            defaultValue={this.state.data.carrier}
                            notifyParent={this.updateValues}
                            disabled={!this.state.isNew}
                            notifyDependency={this.onCarrierChange}

                            />
                        </Grid>
                        <Grid item xs className={classes.dropDown}>
                                <FSelect
                                    name='vehicleType'
                                    value={this.state.data.vehicleType}
                                    hasError={this.state.errorData.vehicleType}
                                    helpMessage={this.state.errorMessage.vehicleType}
                                    label='Vehicle Type'
                                    data={this.props.vehicle_vehicleType_choices}
                                    valueRef='prettyName'
                                    keyRef='prettyName'
                                    notifyParent={this.updateValues}
                                />
                        </Grid>

                        <Grid item xs>
                            <FInput name='vehicleNumber' required
                                value={this.state.data.vehicleNumber}
                                hasError={this.state.errorData.vehicleNumber}
                                helpMessage={this.state.errorMessage.vehicleNumber}
                                label="Vehicle Number" notifyParent={this.updateValues}
                            />
                        </Grid>
                        {/* <Grid item xs>

                            <FInput name='vehiclePlateNumber' required
                                value={this.state.data.vehiclePlateNumber}
                                hasError={this.state.errorData.vehiclePlateNumber}
                                helpMessage={this.state.errorMessage.vehiclePlateNumber}
                                label="Licence Plate Number" notifyParent={this.updateValues}
                            />
                        </Grid> */}
                        <Grid item xs>
                            <FInput name='vehicleVIN' required
                                value={this.state.data.vehicleVIN}
                                hasError={this.state.errorData.vehicleVIN}
                                helpMessage={this.state.errorMessage.vehicleVIN}
                                // disabled={!this.state.isNew}
                                label="VIN"
                                notifyParent={this.updateValues}
                            />
                        </Grid>
                        
                        
                        <Grid item xs >
                            <FAutoComplete
                                url={API_DRIVERS_URL}
                                // queryParam={`carrier=${this.state.data.carrier && this.state.data.carrier.id}`}
                                hasCarrier={true}
                                carrier={this.state.data.carrier}
                                name='driver'
                                hasError={this.state.errorData.driver}
                                helpMessage={this.state.errorMessage.driver}
                                label='Driver'
                                // queryParam='search'
                                value={this.state.data.driver}
                                defaultValue={this.state.data.driver}
                                notifyParent={this.updateValues}
                                // disabled={!this.state.isNew}
                                />
                            
                        </Grid>
                    </Grid>

                    <Grid container className={classes.root} spacing= {4} xs={12} >

                        <Grid item xs>
                            <FInput name='vehicleYear' required
                                type='number'
                                
                                value={this.state.data.vehicleYear}
                                hasError={this.state.errorData.vehicleYear}
                                helpMessage={this.state.errorMessage.vehicleYear}
                                label="Year" notifyParent={this.updateValues}
                            />
                        </Grid>

                        <Grid item xs>

                            <FInput name='vehicleMake' required
                                value={this.state.data.vehicleMake}
                                hasError={this.state.errorData.vehicleMake}
                                helpMessage={this.state.errorMessage.vehicleMake}
                                label="Make" notifyParent={this.updateValues}
                            />
                        </Grid>
                        <Grid item xs>

                            <FInput name='vehicleModel' required
                                value={this.state.data.vehicleModel}
                                hasError={this.state.errorData.vehicleModel}
                                helpMessage={this.state.errorMessage.vehicleModel}
                                label="Model" notifyParent={this.updateValues}
                            />
                        </Grid>
                       
                        <Grid item xs>
                            <FInput name='vehicleCurrentOdometer' required
                                type='number'
                                value={this.state.data.vehicleCurrentOdometer}
                                hasError={this.state.errorData.vehicleCurrentOdometer}
                                helpMessage={this.state.errorMessage.vehicleCurrentOdometer}
                                label="Current Odometer Reading" notifyParent={this.updateValues}
                            />
                        </Grid>        

                    </Grid>
                    
                     <Grid container className={classes.root} spacing= {4} xs={12} >
                            <Grid item xs>
                                    <FInput name='vehiclePlateNumber' required
                                        value={this.state.data.vehiclePlateNumber}
                                        hasError={this.state.errorData.vehiclePlateNumber}
                                        helpMessage={this.state.errorMessage.vehiclePlateNumber}
                                        label="Licence Plate Number" notifyParent={this.updateValues}
                                    />
                            </Grid>
                            <Grid item xs >
                                    <FSelect
                                        name='vehiclePlateState' required
                                        value={this.state.data.vehiclePlateState}
                                        hasError={this.state.errorData.vehiclePlateState}
                                        helpMessage={this.state.errorMessage.vehiclePlateState}
                                        label={'Licence Issuing State'}
                                        data={COUNTRY_STATES_NAMES}
                                        valueRef='prettyName'
                                        keyRef='prettyName'
                                        notifyParent={this.updateValues}
                                    />
                            </Grid>
                             
                            <Grid item xs>
                                    <FDate
                                        name='vehicleStartDate'
                                        required={true}
                                        defaultValue={this.state.data.vehicleStartDate}
                                        hasError={this.state.errorData.vehicleStartDate}
                                        helpMessage={this.state.errorMessage.vehicleStartDate}
                                        label="Vehicle Activated under current DOT Date "
                                        notifyParent={this.updateValues} />
                               
                                </Grid>
                    </Grid>
                    <Grid container  spacing= {2} xs={12} style={{paddingLeft: '10px'}}>
                        {!this.isTrailer() && <Grid item xs={2}>
                                    <FSelect
                                        name='truckType'
                                        value={this.state.data.truckType}
                                        hasError={this.state.errorData.truckType}
                                        helpMessage={this.state.errorMessage.truckType}
                                        label='Truck class / Type *'
                                        data={this.props.vehicle_truck_type_choices}
                                        valueRef='prettyName'
                                        keyRef='prettyname'
                                        notifyParent={this.updateValues} />
                                </Grid>}
                                {this.hasTrailer() && <Grid item  xs={4}>
                            
                                    <FAutoComplete
                                        url={API_VEHICLES_URL}
                                        queryParam={'vehicleType=TRAILER'}
                                        hasCarrier={true}
                                        carrier = {this.state.data.carrier}
                                        name='trailer'
                                        hasError={this.state.errorData.trailer}
                                        helpMessage={this.state.errorMessage.trailer}
                                        label='Trailer'
                                        // queryParam='search'
                                        value={this.state.data.trailer}
                                        defaultValue={this.state.data.trailer}
                                        notifyParent={this.updateValues}
                                        // disabled={!this.state.isNew}
                                        />
                            </Grid>}
                            {this.isTrailer() && <Grid item xs={3}>
                                   <FSelect
                                        name='trailerType'
                                        value={this.state.data.trailerType}
                                        hasError={this.state.errorData.trailerType}
                                        helpMessage={this.state.errorMessage.trailerType}
                                        label='Trailer Type *'
                                        data={this.props.vehicle_trailer_type_choices}
                                        valueRef='prettyName'
                                        keyRef='prettyName'
                                        notifyParent={this.updateValues} />
                                </Grid>}
                        <Grid item xs={2}>
                            <FFileUpload
                                value={this.state.data.vehiclePicture}
                                name='vehiclePicture'
                                label="Vehicle Picture"
                                hasError={this.state.errorData.vehiclePicture}
                                helpMessage={this.state.errorMessage.vehiclePicture}
                                notifyParent={this.updateValues} />
                       </Grid>
                    
                        <Grid item xs={5} >
                                <FInput
                                name='desc' value={this.state.data.desc} multiline={true}
                                value={this.state.data.desc}
                                hasError={this.state.errorData.desc}
                                helpMessage={this.state.errorMessage.desc}
                                label="Description" notifyParent={this.updateValues} />
                    </Grid>
                       </Grid>
                    
                   
                </Grid>
          
                <Grid container className={classes.root} spacing= {4} xs={12} >
                        {/* <Grid item container xs={8} >
                            <FButton color='primary'
                                label={this.state.isNew ? 'Save' : 'Update'}
                                loading={this.state.loading}
                                onClick={this.onSaveData}
                            />
                            <Grid item style={{ paddingTop: '9px' }}>
                            <FRouteBackLink variant='outlined' color='secondary' className={'backButton'} />
                            </Grid>
                        </Grid> */}
                         <Grid item container xs={8} >
                            {this.getUpdateButton(this.props.profile, ADMIN_VEHICLES)}
                        </Grid>
                </Grid>

                <Grid item xs className={classes.padding}>
                    {!this.state.isNew &&
                        <DocumentView
                            ownerId={this.props.match.params.id}
                            owner={this.state.data}
                            listUrl={API_DOCUMENTS_URL + `?&vehicle=${this.props.match.params.id}`}
                            ownerType='vehicle'
                            policy_id={ADMIN_VEHICLES}
                        />}
                </Grid>

                {/* <Grid item xs className={classes.padding}>
                    {!this.state.isNew &&
                        <MaintenanceRecord
                            queryParams={`vehicle=${this.props.match.params.id}&expenseType=MAINTENANCE&`}
                            listUrl={API_EXPENSE_URL}
                        />}

                </Grid> */}
            </React.Fragment >
        );
    };
}

VehicleDetial.propTypes = {
    classes: PropTypes.object.isRequired
};

export default withStyles(styles)(connect(
    mapStateToProps, mapDispatchToProps)(VehicleDetial))
