import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux"
import { withStyles } from "@material-ui/core/styles";
import { Grid } from "@material-ui/core";

import { mapDispatchToProps, mapStateToProps } from "../../fredux/ActionMaker";
import { API_EXPENSE_URL } from "../../util/Const";
import { VALIDATE_REQUIRED, VALIDATE_DATE, VALIDATE_EMAIL, VALIDATE_PHONE_NUMBER, 
       VALIDATE_FEE, VALIDATE_ZERO_OR_POSITIVE,
        VALIDATE_DAYS_FREQUENCY, VALIDATE_POSITIVE, VALIDATE_ADDRESS_CITY_STATE, VALIDATE_FILE_SIZE, VALIDATE_0_100_PERCENTAGE, VALIDATE_CARRIER_REQUIRED, VALIDATE_OBJECT_REQUIRED } from "../../util/ValidatorConst";


import * as CONST from "../../util/Const"

import { getNextDateFrom} from "../../util";
import ExpenseDetailBase from "./ExpenseDetailBase"

const styles = theme => ({
    reimbursablePercentage :{
        marginRight: theme.spacing(2),

    }, 
    invoiceablePercentage: {
        marginRight: theme.spacing(2),

    },
    padding :{
        marginTop: theme.spacing(1),
        marginBottom:  theme.spacing(1),
    }, 
    deductable: {
        marginLeft: theme.spacing(0.5),
        marginTop: theme.spacing(1),
        marginBottom:  theme.spacing(1),
    },
    desc: {
        marginTop:  theme.spacing(2),
        marginLeft:  theme.spacing(8),
    },
    expenseReceipt: {
        marginRight: theme.spacing(10),
    },
    space: {
        marginRight: theme.spacing(10),
    },
    spaceLeft: {
        marginLeft: theme.spacing(10),
    }
});

const PROPS = {
    total:0,
    data: {
        expenseFor: '',
        expenseTransactionID: '',
        expenseTransactionDate: '',
        expenseAmount: 0,
        driver: {},
        vehicle: {},
        dispatcher: {},
        carrier: {},
        fleet: {},
        expenseReceipt: '',
        expenseIsReimbursable: '',
        expenseReimbursablePercentage: 0,

        expenseIsDeductable: '',
        expenseDeductablePercentage: 0,

        expenseIsInvoiceable: '',
        expenseInvoicePercentage: 0,

        expenseType: '',
        
        expenseMaintenanceCategory: '',
        fuelCardExpenseType: '',
        company: {},
        expenseMaintenanceMechanicName: '',
        vehicleMaintenanceMechanicEmail: '',
        vehicleMaintenanceMechanicPhoneNumber: '',
        expensePayCheckTransactionDate: '',
        expenseInvoiceTransactionDate: '',
        
        expenseIsRecurring: false,
        expenseRecurringStartDate: '',
        expenseRecurringEndDate: getNextDateFrom(90),
        scheduleFrequencyStrategyType: '',
        scheduleDay: '',
        expenseRecurringDays: '',

        expenseFee: 0,
        expenseUnitPrice: 0,
        expenseQuantity: 1,
        expenseFuelType: 'UN_KNOWN',
        
        address:{},
        disc: ''
    },
    errorData: {
    },
    touched: {},
    errorMessage: {
        expenseFor: 'Invalid Data',
        expenseTransactionID: 'Invalid Transaction ID',
        expenseTransactionDate: 'Invalid Date',
        expenseAmount: 'Invalid Expense Amount, Use deductable or reimbursable to record negative',
        driver: 'No Driver Selected/Found  Under Carrier',
        vehicle: 'No Vehicle Selected/Found  Under Carrier',
        dispatcher: 'No Dispatcher Selected/Found  Under Carrier',
        carrier: 'Invalid Carrier',
        fleet: 'No fleet Selected/Found  Under Carrier',
        expenseReceipt: 'Invalid Receipt',
        expenseIsReimbursable: 'Invalid value',
        expenseReimbursablePercentage: 'Invalid percentage value [0 - 100]',
        expenseIsDeductable: 'Invalid Value',
        expenseDeductablePercentage: 'Invalid percentage value [0 - 100]',
        expenseIsInvoiceable: 'Invalid Value',
        expenseInvoicePercentage: 'Invalid percentage value [0 - 100]',
        expenseType: 'Invalid Expense Type',
        company: 'Invalid Auto Serivce',
        
        fuelCardExpenseType: 'Invalid Fuel Card Transaction Type',
        expenseMaintenanceCategory: 'Invalid Maintenance Category',
        expenseMaintenanceMechanicName: 'Invalid Name',
        expenseMaintenanceMechanicEmail: 'Invalid Email',
        expenseMaintenanceMechanicPhoneNumber: 'Invalid Phone Number',
        expensePayCheckTransactionDate: 'Invalid Pay Check Transaction Date',
        expenseInvoiceTransactionDate: 'InvalidInvoice Transaction Date',

        expenseIsRecurring: 'Invalid Value',
        expenseRecurringStartDate: 'Invalid Start Date / Interval',
        expenseRecurringEndDate: 'Invalid End Date / Interval',
        expenseRecurringDays: 'Invalid Frequency number of days,  Max 360 days',
        expenseFee: 'Invalid Fee',
        expenseUnitPrice: 'Invalid Amount',
        expenseQuantity: 'Invalid Quantity',
        expenseFuelType: 'Invald Fuel Type',
        disc: ''
    },
    validators: {
        expenseFor: [VALIDATE_REQUIRED],
        expenseTransactionID: [],
        expenseTransactionDate: [VALIDATE_DATE],
        expenseAmount: [VALIDATE_FEE],
        driver: [VALIDATE_REQUIRED],
        vehicle: [VALIDATE_REQUIRED],
        dispatcher: [VALIDATE_REQUIRED],
        carrier: [VALIDATE_CARRIER_REQUIRED],
        fleet: [VALIDATE_REQUIRED],
        // customer: [VALIDATE_REQUIRED],
        
        expenseReceipt: [VALIDATE_FILE_SIZE],
        expenseIsReimbursable: [],
        expenseReimbursablePercentage: [VALIDATE_0_100_PERCENTAGE],
       
        expenseIsDeductable: [],
        expenseDeductablePercentage: [VALIDATE_0_100_PERCENTAGE],
        expenseIsInvoiceable: [],
        expenseInvoicePercentage: [VALIDATE_0_100_PERCENTAGE],

        expenseType: [VALIDATE_REQUIRED],
        fuelCardExpenseType: [VALIDATE_REQUIRED],
        expenseMaintenanceCategory: [VALIDATE_REQUIRED],
        expenseMaintenanceMechanicName: [VALIDATE_REQUIRED],
        expenseMaintenanceMechanicEmail: [VALIDATE_EMAIL],
        expenseMaintenanceMechanicPhoneNumber: [VALIDATE_PHONE_NUMBER],
        expensePayCheckTransactionDate: [],
        expenseInvoiceTransactionDate: [],

        expenseIsRecurring: [],
        expenseRecurringStartDate: [VALIDATE_REQUIRED],
        expenseRecurringEndDate:[VALIDATE_REQUIRED],
        expenseRecurringDays: [VALIDATE_DAYS_FREQUENCY],

        expenseFee: [VALIDATE_ZERO_OR_POSITIVE],
        expenseUnitPrice: [VALIDATE_ZERO_OR_POSITIVE],
        expenseQuantity: [VALIDATE_POSITIVE],

        expenseFuelType:[VALIDATE_REQUIRED],
        address: [VALIDATE_ADDRESS_CITY_STATE],

        disc: [],

    }
}


class ExpenseDetail extends ExpenseDetailBase {

    constructor(props) {
        super(props, API_EXPENSE_URL, 'expense', PROPS)
        this.detailType = 'EXP'
    }

    render() {
        return (
            <React.Fragment> {this.getDetail()}
                <Grid container direction='column'
                     justify='space-between' alignItems='stretch' spacing={3} >

                <Grid item container xs={8} >
                        {this.getUpdateButton(this.props.profile, CONST.EXPENSE_EXPNESES)}
                </Grid>
                </Grid>
            </React.Fragment>
        )
    }

}


ExpenseDetail.propTypes = {
    classes: PropTypes.object.isRequired
};

export default withStyles(styles)(connect(
    mapStateToProps, mapDispatchToProps)(ExpenseDetail))

