import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux"
import { withStyles } from "@material-ui/core/styles";
import { Grid } from "@material-ui/core";
import FInput from "../widgets/FInput";
import { mapDispatchToProps, mapStateToProps } from "../../fredux/ActionMaker";
import FDate from "../widgets/FDate";
import FCheckBox from "../widgets/FCheckBox";

import FAutoComplete from "../widgets/FAutoComplete";


import FSelect from "../widgets/FSelect";
import * as CONST from "../../util/Const"
import { getNextDateFrom, toFloat, roundDisplay, getPrettyID } from "../../util";
import RecExpenseChildren from "./RecExpenseChildren";
import ExpenseDetailBase from "./ExpenseDetailBase";

const styles = theme => ({
    reimbursablePercentage :{
        marginRight: theme.spacing(2),

    }, 
    invoiceablePercentage: {
        marginRight: theme.spacing(2),

    },
    padding :{
        marginTop: theme.spacing(1),
        marginBottom:  theme.spacing(1),
    }, 
    deductable: {
        marginLeft: theme.spacing(0.5),
        marginTop: theme.spacing(1),
        marginBottom:  theme.spacing(1),
    },
    desc: {
        marginTop:  theme.spacing(2),
        marginLeft:  theme.spacing(8),
    },
    expenseReceipt: {
        marginRight: theme.spacing(10),
    },
    space: {
        marginRight: theme.spacing(10),
    },
    spaceLeft: {
        marginLeft: theme.spacing(10),
    }
});

const PROPS = {
    total:0,
    data: {
        expenseFor: '',
        expenseTransactionID: '',
        expenseTransactionDate: '',
        expenseAmount: 0,
        driver: {},
        vehicle: {},
        dispatcher: {},
        carrier: {},
        fleet: {},
        expenseReceipt: '',
        expenseIsReimbursable: '',
        expenseReimbursablePercentage: 0,

        expenseIsDeductable: '',
        expenseDeductablePercentage: 0,

        expenseIsInvoiceable: '',
        expenseInvoicePercentage: 0,

        expenseType: '',
        expenseMaintenanceCategory: '',
        company: {},
        expenseMaintenanceMechanicName: '',
        vehicleMaintenanceMechanicEmail: '',
        vehicleMaintenanceMechanicPhoneNumber: '',
        expensePayCheckTransactionDate: '',
        expenseInvoiceTransactionDate: '',
        
        expenseIsRecurring: false,
        expenseRecurringStartDate: '',
        expenseRecurringEndDate: getNextDateFrom(90),
        expenseRecurringDays: '',

        expenseFee: 0,
        expenseUnitPrice: 0,
        expenseQuantity: 1,
        expenseFuelType: 'UN_KNOWN',
        expenseRecurringGroupId: '',
        address:{},
        expenseRawData: {},
        disc: ''
    },
    errorData: {
        expenseRawData: {},
    },
    touched: {},
    errorMessage: {
        expenseRawData: {},
        expenseFor: 'Invalid Data',
        expenseTransactionID: 'Invalid Transaction ID',
        expenseTransactionDate: 'Invalid Date',
        expenseAmount: 'Invalid Expense Amount, Use deductable or reimbursable to record negative',
        driver: 'No Driver Selected/Found  Under Carrier',
        vehicle: 'No Vehicle Selected/Found  Under Carrier',
        dispatcher: 'No Dispatcher Selected/Found  Under Carrier',
        carrier: 'Invalid Carrier',
        fleet: 'No fleet Selected/Found  Under Carrier',
        expenseReceipt: 'Invalid Receipt',
        expenseIsReimbursable: 'Invalid value',
        expenseReimbursablePercentage: 'Invalid percentage value [0 - 100]',
        expenseIsDeductable: 'Invalid Value',
        expenseDeductablePercentage: 'Invalid percentage value [0 - 100]',
        expenseIsInvoiceable: 'Invalid Value',
        expenseInvoicePercentage: 'Invalid percentage value [0 - 100]',
        expenseType: 'Invalid Expense Type',
        company: 'Invalid Auto Serivce',

        expenseMaintenanceCategory: 'Invalid Maintenance Category',
        expenseMaintenanceMechanicName: 'Invalid Name',
        expenseMaintenanceMechanicEmail: 'Invalid Email',
        expenseMaintenanceMechanicPhoneNumber: 'Invalid Phone Number',
        expensePayCheckTransactionDate: 'Invalid Pay Check Transaction Date',
        expenseInvoiceTransactionDate: 'InvalidInvoice Transaction Date',

        expenseIsRecurring: 'Invalid Value',
        expenseRecurringStartDate: 'Invalid Start Date / Interval',
        expenseRecurringEndDate: 'Invalid End Date / Interval',
        expenseRecurringDays: 'Invalid Frequency number of days,  Max 360 days',
        expenseFee: 'Invalid Fee',
        expenseUnitPrice: 'Invalid Amount',
        expenseQuantity: 'Invalid Quantity',
        expenseFuelType: 'Invald Fuel Type',
        disc: ''
    },
    validators: {}
}


class RecExpensesDetail extends ExpenseDetailBase {

    constructor(props) {
        super(props, CONST.API_REC_EXPENSE_URL + '/', 'expense', PROPS)
        this.detailType = 'REC'
    }

    getCurrentTitle = () => {
        let title = 'Expense ' +  this.state.data && this.state.data.expenseRawData && this.state.data.expenseRawData.expenseType
        this.props.updateCurrentTab(CONST.TAB_EXPENSE)
        this.props.updateCurrentTitle && this.props.updateCurrentTitle(title)
    }
    
    // This class is used for both readonly and expense detail -- when it is on rec-exp mode is meant to be on read-only mode
    expDetailType = () => {
        return "REC-EXP"
    }


    render() {
        const { classes } = this.props;
        this.getCurrentTitle()
        let expense = this.state.data.expenseRawData || {}
        let totalExpenseAmount = toFloat(expense.expenseQuantity) * toFloat(expense.expenseUnitPrice) + toFloat(expense.expenseFee)
        totalExpenseAmount = roundDisplay(totalExpenseAmount, 2)

        return (
            <React.Fragment>

                <Grid container direction='column' justify='space-between' alignItems='stretch' spacing={3} >
                 
                <Grid container spacing= {2} xs={12} >
                                
                                <Grid item xs={2}>
                                    <FInput
                                        name='expenseRecurringGroupId'
                                        value={getPrettyID(this.state.data.expenseRecurringGroupId, 10)}
                                        hasError={this.state.errorData.expenseRecurringGroupId}
                                        helpMessage={this.state.errorMessage.expenseRecurringGroupId}
                                        label='Recurring Group ID'
                                        data={this.props.expenseForModuleChoices}
                                        required={true}
                                        disabled={!this.state.isNew || this.isInRecGroupingMode()}
                                        notifyParent={this.updateValues} />
                                </Grid>
                                <Grid item xs={2}>
                                    <FDate
                                            name='expenseRecurringStartDate'
                                            required={true}
                                            defaultValue={this.state.data.expenseRecurringStartDate}
                                            hasError={this.state.errorData.expenseRecurringStartDate}
                                            helpMessage={this.state.errorMessage.expenseRecurringStartDate}
                                            label="Recurrance Start Date "
                                            disabled={  this.isInRecGroupingMode()}
                                            notifyParent={this.updateValues} />
                                </Grid>
    
                                <Grid item xs>
                                        <FDate
                                                name='expenseRecurringEndDate'
                                                required={true}
                                                defaultValue={this.state.data.expenseRecurringEndDate}
                                                hasError={this.state.errorData.expenseRecurringEndDate}
                                                helpMessage={this.state.errorMessage.expenseRecurringEndDate}
                                                label="Recurrance End Date "
                                                disabled={  this.isInRecGroupingMode()}
                                                notifyParent={this.updateValues} />
                                </Grid>
    
                                {/* <Grid item xs>
                                    <FInput
                                            name='expenseRecurringDays'
                                            value={this.state.data.expenseRecurringDays}
                                            hasError={this.state.errorData.expenseRecurringDays}
                                            helpMessage={this.state.errorMessage.expenseRecurringDays}
                                            label='Schedule Date Frequency'
                                            required={true}
                                            disabled={!this.state.isNew || this.isInRecGroupingMode()}
                                            notifyParent={this.updateValues} />
                                    </Grid> */}
                                <Grid item xs>
                                    <FInput
                                        name='scheduleIntervalDesc'
                                        disabled
                                        value={this.state.data.scheduleIntervalDesc}
                                        label="Schedule Info"
                                        notifyParent={this.updateValues} />
                                </Grid>
                               <Grid item xs>
                                    <FInput
                                            name='expenseTotalRecurringGenerated'
                                            value={this.state.data.expenseTotalRecurringGenerated}
                                            hasError={this.state.errorData.expenseTotalRecurringGenerated}
                                            helpMessage={this.state.errorMessage.expenseTotalRecurringGenerated}
                                            label='Total Expenses scheduled'
                                            required={true}
                                            disabled={!this.state.isNew || this.isInRecGroupingMode()}
                                            notifyParent={this.updateValues} />
                                    </Grid>
                      

                        </Grid>

                    <Grid container spacing= {2} xs={12} >
                                
                                <Grid item xs={2}>
                                    <FSelect
                                        name='expenseFor'
                                        value={this.state.data.expenseRawData.expenseFor}
                                        hasError={this.state.errorData.expenseFor}
                                        helpMessage={this.state.errorMessage.expenseFor}
                                        label='Expense For'
                                        data={this.props.expenseForModuleChoices}
                                        valueRef='prettyName'
                                        keyRef='prettyName'
                                        required={true}
                                        disabled={!this.state.isNew || this.isInRecGroupingMode()}
                                        notifyParent={this.updateValues} />
                                </Grid>
                                <Grid item xs={2}>
                                    <FInput
                                        name='expenseType'
                                        value={this.state.data.expenseRawData.expenseType}
                                        hasError={this.state.errorData.expenseType}
                                        helpMessage={this.state.errorMessage.expenseType}
                                        label='Expense Type'
                                        // data={this.getExpenseChoices()}
                                        // valueRef='prettyName'
                                        // keyRef='prettyName'
                                        disabled={!this.state.isNew  || this.isInRecGroupingMode()}
                                        notifyParent={this.updateValues} />
                                </Grid>
    
                                <Grid item xs hidden={!this.isMaintenanceExpense()}>
                                    <FSelect
                                        name='expenseMaintenanceCategory'
                                        value={this.state.data.expenseRawData.expenseMaintenanceCategory}
                                        hasError={this.state.errorData.expenseMaintenanceCategory}
                                        helpMessage={this.state.errorMessage.expenseMaintenanceCategory}
                                        label='Maintenance Type'
                                        data={this.props.vehicle_maintenanceType_choices}
                                        valueRef='prettyName'
                                        keyRef='prettyName'
                                        disabled={this.isInRecGroupingMode()}
                                        notifyParent={this.updateValues} />
                                </Grid>
    
                                <Grid item xs disabled={this.isNew}>
                                    <FInput
                                        name='expenseTransactionID'
                                        value={this.state.data.expenseRawData.expenseTransactionID}
                                        hasError={this.state.errorData.expenseTransactionID}
                                        helpMessage={this.state.errorMessage.expenseTransactionID}
                                        label="Transaction ID"
                                        disabled={!this.state.isNew || this.isInRecGroupingMode()}
                                        notifyParent={this.updateValues} />
                                </Grid>
                                <Grid item xs>
                                    <FDate
                                        name='created'
                                        required={true}
                                        defaultValue={this.state.data.created}
                                        hasError={this.state.errorData.created}
                                        helpMessage={this.state.errorMessage.created}
                                        label="Date Created"
                                        disabled
                                        notifyParent={this.updateValues} />
                               
                                </Grid>
    
                                {/* <Grid item xs>
                                    <FInput
                                        name='expenseAmount'
                                        required={true}
                                        type='number'
                                        value={this.state.total}
                                        hasError={this.state.errorData.expenseAmount}
                                        helpMessage={this.state.errorMessage.expenseAmount}
                                        label="Expense Amount "
                                        notifyParent={this.updateValues} />
                                </Grid> */}
                      
                        </Grid>
    
                        <Grid container spacing= {4} xs={12}>
                                
                  
                                <Grid item xs>
                                    <FInput
                                        name='expenseUnitPrice'
                                        required={true}
                                        type='number'
                                        value={this.state.data.expenseRawData.expenseUnitPrice}
                                        hasError={this.state.errorData.expenseUnitPrice}
                                        helpMessage={this.state.errorMessage.expenseUnitPrice}
                                        label="Unit Price "
                                        disabled={  this.isInRecGroupingMode()}
                                        notifyParent={this.updateValues} />
                                </Grid>
    
                                <Grid item xs>
                                    <FInput
                                        name='expenseQuantity'
                                        required={true}
                                        type='number'
                                        value={this.state.data.expenseRawData.expenseQuantity}
                                        hasError={this.state.errorData.expenseQuantity}
                                        helpMessage={this.state.errorMessage.expenseQuantity}
                                        label="Quantity "
                                        disabled={  this.isInRecGroupingMode()}
                                        notifyParent={this.updateValues} />
                                </Grid>
                                <Grid item xs>
                                    <FInput
                                        name='expenseFee'
                                        type='number'
                                        value={this.state.data.expenseRawData.expenseFee}
                                        hasError={this.state.errorData.expenseFee}
                                        helpMessage={this.state.errorMessage.expenseFee}
                                        label="Transaction Fee "
                                        disabled={  this.isInRecGroupingMode()}
                                        notifyParent={this.updateValues} />
                                </Grid>
    
                                <Grid item xs>
                                    <FInput
                                        name='expenseAmount'
                                        // required={true}
                                        // type='number'
                                        disabled
                                        value={totalExpenseAmount}
                                        hasError={this.state.errorData.expenseAmount}
                                        helpMessage={this.state.errorMessage.expenseAmount}
                                        label="Total"
                                        notifyParent={this.updateValues} />
                                </Grid>
                                {/* <Grid item xs>
                                    <FInput
                                        name='scheduleIntervalDesc'
                                        disabled
                                        value={this.state.data.scheduleIntervalDesc}
                                        label="Schedule Info"
                                        notifyParent={this.updateValues} />
                                </Grid> */}
                                
                        </Grid>
                       
                       
                        <Grid container spacing= {2} xs={12} >
                                <Grid item xs hidden={!this.showModule(CONST.CHOICE_CARRIER)}>
                                    <FAutoComplete
                                        url={CONST.API_CARRIERS_URL}
                                        name='carrier'
                                        hasError={this.state.errorData.carrier}
                                        helpMessage={this.state.errorMessage.carrier}
                                        label='Authority'
                                        // queryParam='search'
                                        value={this.state.data.expenseRawData.carrier}
                                        defaultValue={this.state.data.carrier}
                                        notifyParent={this.updateValues}
                                        disabled={!this.state.isNew || this.isInRecGroupingMode()}
                                        notifyDependency={this.onCarrierChange}
    
                                        />
                                </Grid>
                                <Grid item xs hidden={!this.showModule(CONST.CHOICE_DRIVER)}>
                                    <FAutoComplete
                                            url={CONST.API_DRIVERS_URL}
                                            // queryParam={`carrier=${this.state.data.carrier && this.state.data.carrier.id}`}
                                            hasCarrier={true}
                                            carrier={this.state.data.expenseRawData.carrier}
                                            name='driver'
                                            hasError={this.state.errorData.driver}
                                            helpMessage={this.state.errorMessage.driver}
                                            label='Driver'
                                            // queryParam='search'
                                            value={this.state.data.driver}
                                            defaultValue={this.state.data.driver}
                                            notifyParent={this.updateValues}
                                            disabled={this.isInRecGroupingMode()}
                                            />
                                    
                                </Grid>
                                <Grid item xs hidden={!this.showModule(CONST.CHOICE_VEHICLE)}>
                                   <FAutoComplete
                                        url={CONST.API_VEHICLES_URL}
                                        // queryParam={'vehicleType=TRUCK'}
                                        hasCarrier={true}
                                        carrier = {this.state.data.expenseRawData.carrier}
                                        name='vehicle'
                                        hasError={this.state.errorData.vehicle}
                                        helpMessage={this.state.errorMessage.vehicle}
                                        label='Truck'
                                        // queryParam='search'
                                        value={this.state.data.vehicle}
                                        defaultValue={this.state.data.vehicle}
                                        notifyParent={this.updateValues}
                                        disabled={this.isInRecGroupingMode()}
                                        />
                                   
                                </Grid>
                                <Grid item xs hidden={!this.showModule(CONST.CHOICE_DISPATCHER)}>
                                 <FAutoComplete
                                        url={CONST.API_DISPATCHERS_URL}
                                        // queryParam={`carrier=${this.state.data.carrier && this.state.data.carrier.id}`}
                                        hasCarrier={true}
                                        carrier={this.state.data.expenseRawData.carrier}
                                        name='dispatcher'
                                        hasError={this.state.errorData.dispatcher}
                                        helpMessage={this.state.errorMessage.dispatcher}
                                        label='Dispatcher'
                                        // queryParam='search'
                                        value={this.state.data.dispatcher}
                                        defaultValue={this.state.data.dispatcher}
                                        notifyParent={this.updateValues}
                                        disabled={this.isInRecGroupingMode()}
                                        />
        
                                </Grid>
                               
                                <Grid item xs hidden={!this.isMaintenanceExpense()}>
                                    <FAutoComplete
                                        url={CONST.API_SERVICE_PROVIDER}
                                        queryParam={'companyType=AUTO_SERVICE_PROVIDER'}
                                        hasCarrier={true}
                                        carrier = {this.state.data.expenseRawData.carrier}
                                        name='company'
                                        hasError={this.state.errorData.company}
                                        helpMessage={this.state.errorMessage.company}
                                        label='Auto Service Provider'
                                        // queryParam='search'
                                        value={this.state.data.company}
                                        defaultValue={this.state.data.company}
                                        notifyParent={this.updateValues}
                                        disabled={this.isInRecGroupingMode()}
                                        // Show add button on autocomplete widget
                                        showAdd={true} 
                                        // Onclick the add button 
                                        onEdit={this.onShowAddCompany}
                                        />
                                  {/* <CompanyPopUp dialogEditMode={this.state.showAddCompany}
                                    notifyDialogClose = {() => this.setState({showAddCompany: false})}
                                    choices={this.props.companyServiceProviderContactTypeChoice}
                                    title={'Auto Service Provider'}
                                    carrier = {this.state.data.carrier}
                                  /> */}
                                </Grid>
    
                                
                        </Grid>
    
                        {/* <Grid container spacing= {4} xs={12} className={classes.padding}>
                                <Grid item xs>
                                    <FAddress 
                                        name='address' 
                                        notifyParent={this.updateValues}
                                        address={this.state.data.expenseRawData.address}
                                        hasError={this.state.errorData.address}
                                        disabled={this.isInRecGroupingMode()}
                                        city={this.state.data.expenseType == 'FUEL'}
                                        state={this.state.data.expenseType == 'FUEL'}
                                        
                                    />
                                </Grid>
    
                        </Grid> */}
                        
                        <Grid container xs={12}  spacing={1} className={classes.deductable} >
                            <Grid item xs={1.2}  hidden={!this.isDeductable()}>
                                <FCheckBox
                                        name='expenseIsDeductable'
                                        value={this.state.data.expenseIsDeductable}
                                        hasError={this.state.errorData.expenseIsDeductable}
                                        helpMessage={this.state.errorMessage.expenseIsDeductable}
                                        label=" Deductable"
                                        disabled={this.isInRecGroupingMode()}
                                        notifyParent={this.updateValues} />
                                </Grid>
                                <Grid item  xs={2}  className={classes.space} hidden={!this.isDeductable()}>
                                    <FInput
                                        disabled={!this.state.data.expenseIsDeductable || this.isInRecGroupingMode()}
                                        name='expenseDeductablePercentage' 
                                        required={true}
                                        type='number'
                                        value={this.state.data.expenseDeductablePercentage}
                                        hasError={this.state.errorData.expenseDeductablePercentage}
                                        helpMessage={this.state.errorMessage.expenseDeductablePercentage}
                                        label="Driver's deductable percentage "
                                        notifyParent={this.updateValues} />
                                </Grid>
                               
                                <Grid item xs={1.3}  className={classes.deductable} hidden={!this.isDeductable()}>
                                    <FCheckBox
                                        name='expenseIsReimbursable'
                                        value={this.state.data.expenseIsReimbursable}
                                        disabled={!this.state.data.expenseIsDeductable || this.isInRecGroupingMode()}
                                        hasError={this.state.errorData.expenseIsReimbursable}
                                        helpMessage={this.state.errorMessage.expenseIsReimbursable}
                                        label=" Reimbursable"
                                        notifyParent={this.updateValues} />
                                </Grid>
                                <Grid item xs={2}  className={classes.reimbursablePercentage}
                                      hidden={!this.isDeductable()} >
                                    <FInput
                                        disabled={!this.state.data.expenseIsReimbursable || this.isInRecGroupingMode()}
                                        name='expenseReimbursablePercentage'
                                        required={true}
                                        type='number'
                                        value={this.state.data.expenseReimbursablePercentage}
                                        hasError={this.state.errorData.expenseReimbursablePercentage}
                                        helpMessage={this.state.errorMessage.expenseReimbursablePercentage}
                                        label=" Driver's reimbursable percentage "
                                        notifyParent={this.updateValues} />
                                </Grid>
                                <Grid item xs={3} hidden={this.state.isNew || !this.showModule(CONST.CHOICE_DRIVER) || this.isInRecGroupingMode()}>
                                    <FDate
                                        name='expensePayCheckTransactionDate'
                                        required={true}
                                        defaultValue={this.state.data.expensePayCheckTransactionDate}
                                        hasError={this.state.errorData.expensePayCheckTransactionDate}
                                        helpMessage={this.state.errorMessage.expensePayCheckTransactionDate}
                                        label="Expense Pay Check Date "
                                        notifyParent={this.updateValues}
                                    />
                                </Grid>                           
                        </Grid>
                        <Grid container xs={12}  spacing={1} className={classes.deductable} >
                                <Grid item xs={1.3} hidden={!this.isInvoiceable()}>
                                    <FCheckBox
                                        name='expenseIsInvoiceable'
                                        value={this.state.data.expenseIsInvoiceable}
                                        value={this.state.data.expenseIsInvoiceable}
                                        hasError={this.state.errorData.expenseIsInvoiceable}
                                        helpMessage={this.state.errorMessage.expenseIsInvoiceable}
                                        label="Invoiceable"
                                        disabled={ this.isInRecGroupingMode()}
                                        notifyParent={this.updateValues} />
                                </Grid>
                               <Grid item xs={1.8} hidden={!this.state.data.expenseIsInvoiceable}
                                  className={classes.invoiceablePercentage}>
                                    <FInput
                                        disabled={!this.state.data.expenseIsInvoiceable || this.isInRecGroupingMode()}
                                        name='expenseInvoicePercentage'
                                        required={true}
                                        type='number'
                                        value={this.state.data.expenseInvoicePercentage}
                                        hasError={this.state.errorData.expenseInvoicePercentage}
                                        helpMessage={this.state.errorMessage.expenseInvoicePercentage}
                                        label=" Invoiceable Percentage "
                                        notifyParent={this.updateValues} />
                                </Grid>
                                
                                <Grid item xs={3}  hidden={!this.state.data.expenseIsInvoiceable}>
                                    <FAutoComplete
                                        disabled={!this.state.data.expenseIsInvoiceable || this.isInRecGroupingMode()}
                                        url={CONST.API_FLEET_URL}
                                        // queryParam={'status=COMPLETE'}
                                        hasCarrier={true}
                                        carrier = {this.state.data.carrier}
                                        name='fleet'
                                        hasError={this.state.errorData.fleet}
                                        helpMessage={this.state.errorMessage.fleet}
                                        label='Fleet'
                                        // queryParam='search'
                                        value={this.state.data.fleet}
                                        defaultValue={this.state.data.fleet}
                                        notifyParent={this.updateValues}
                                        />
    
        
                                </Grid>
                                <Grid item xs={3} hidden={this.state.isNew || !this.state.data.expenseIsInvoiceable}>
                                        <FDate
                                            name='expenseInvoiceTransactionDate'
                                            disabled={!this.state.data.expenseIsInvoiceable || this.isInRecGroupingMode()}
                                            required={true}
                                            defaultValue={this.state.data.expenseInvoiceTransactionDate}
                                            hasError={this.state.errorData.expenseInvoiceTransactionDate}
                                            helpMessage={this.state.errorMessage.expenseInvoiceTransactionDate}
                                            label="Expense Invoice Date "
                                            notifyParent={this.updateValues}
                                        />
                                    </Grid>
                        </Grid>
                       
                        {/* <Grid item xs hidden={!this.state.isNew}>
    
                            <RecurringExpenseConf 
                                data={this.state.data}
                                errorData={this.state.errorData}
                                errorMessage={this.state.errorMessage} 
                                notifyParent={this.updateValues}
                                profile={this.props.profile}
                                />
                            </Grid> */}
                
                       {/* <Grid container className={classes.expenseReceipt} spacing={1} xs={12} >
                          
                              <Grid item xs={2}>
                                    <FFileUpload
                                        disabled={this.isInRecGroupingMode()}
                                        value={this.state.data.expenseReceipt}
                                        name='expenseReceipt'
                                        label="Upload Expense Receipt"
                                        hasError={this.state.errorData.expenseReceipt}
                                        helpMessage={this.state.errorMessage.expenseReceipt}
                                        
                                        notifyParent={this.updateValues} />
                                </Grid>
                            <Grid item xs className={classes.desc}>
                                <FInput
                                    name='desc' value={this.state.data.desc} multiline={true}
                                    value={this.state.data.desc}
                                    hasError={this.state.errorData.desc}
                                    helpMessage={this.state.errorMessage.desc}
                                    disabled={this.isInRecGroupingMode()}
                                    label="Description" notifyParent={this.updateValues} />
                            </Grid>
                        </Grid> */}

            </Grid>
    
            <Grid item xs className={classes.padding}>
            {!this.state.isNew &&
                <RecExpenseChildren
                    expenseRecurringGroupId={this.props.match.params.id}
            />}
            </Grid>

            <Grid item container xs={8} >
                    {this.getBackButton('Back to Scheduled Expenses')}
            </Grid>
            </React.Fragment>
        );
                
    };
}



RecExpensesDetail.propTypes = {
    classes: PropTypes.object.isRequired
};

export default withStyles(styles)(connect(
    mapStateToProps, mapDispatchToProps)(RecExpensesDetail))


    