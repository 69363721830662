import React from 'react'

import { Grid } from "@material-ui/core"
import FDate from '../../widgets/FDate';
import { connect } from 'react-redux'
import { mapDispatchToProps, mapStateToProps } from '../../../fredux/ActionMaker';
import { withStyles } from '@material-ui/core/styles'
import FAutoComplete from "../../widgets/FAutoComplete";
import { API_CARRIERS_URL, API_VEHICLES_URL, API_DRIVERS_URL, API_DISPATCHERS_URL } from '../../../util/Const';
import FButton from "../../widgets/FButton";
import FSelect from '../../widgets/FSelect';


const styles = theme => ({
  root: {
     width: '100%'
  },
  dropDown: {
  },

  textNumber: {
    marginTop: theme.spacing(1),
  },
  generateButon: {
    marginTop: theme.spacing(2),
  },

  date: {
    width: '100%'
  },

  button: {
    marginTop: theme.spacing(3),
  },
}); 

class FleetReportHeader extends React.Component {

  render() {
    const {classes} = this.props
    return (
      <React.Fragment>
      <Grid container className={classes.root} spacing= {1}  xs={12} >
      <Grid item xs>
            <FDate
              name='fleetPickUpStartDate'
              className={classes.date}
              required={true}
              defaultValue={this.props.data.fleetPickUpStartDate}
              hasError={this.props.errorData.fleetPickUpStartDate}
              helpMessage={this.props.errorMessage.fleetPickUpStartDate}
              label="Date From "
              notifyParent={this.props.updateValues} />
          </Grid>
          <Grid item xs>
            <FDate
              name='fleetPickUpEndDate'
              className={classes.date}
              required={true}
              defaultValue={this.props.data.fleetPickUpEndDate}
              hasError={this.props.errorData.fleetPickUpEndDate}
              helpMessage={this.props.errorMessage.fleetPickUpEndDate}
              label="Date To "
              notifyParent={this.props.updateValues} />
          </Grid>
          <Grid item xs className={classes.dropDown}>
          <FAutoComplete
                url={API_CARRIERS_URL}
                name='carrier'
                style={{width: 150}}
                hasError={this.props.errorData.carrier}
                helpMessage={this.props.errorMessage.carrier}
                label='Authority'
                // queryParam='search'
                value={this.props.data.carrier}
                defaultValue={this.props.data.carrier}
                notifyParent={this.props.updateValues}
                // disabled={!this.props.isNew}
                notifyDependency={this.onCarrierChange}
             />
          </Grid>
          <Grid item xs className={classes.dropDown}>
          <FAutoComplete
                  url={API_DRIVERS_URL}
                  // queryParam={`carrier=${this.props.data.carrier && this.props.data.carrier.id}`}
                  hasCarrier={true}
                  style={{width: 150}}
                  carrier={this.props.data.carrier}
                  name='driver'
                  hasError={this.props.errorData.driver}
                  helpMessage={this.props.errorMessage.driver}
                  label='Driver'
                  // queryParam='search'
                  value={this.props.data.driver}
                  defaultValue={this.props.data.driver}
                  notifyParent={this.props.updateValues}
                  // disabled={!this.props.isNew}
            />
          </Grid>
          <Grid item xs className={classes.dropDown}>
            <FAutoComplete
                    url={API_VEHICLES_URL}
                    style={{width: 150}}
                    queryParam={'vehicleType=TRUCK'}
                    hasCarrier={true}
                    carrier = {this.props.data.carrier}
                    name='vehicle'
                    hasError={this.props.errorData.vehicle}
                    helpMessage={this.props.errorMessage.vehicle}
                    label='Truck'
                    // queryParam='search'
                    value={this.props.data.vehicle}
                    defaultValue={this.props.data.vehicle}
                    notifyParent={this.props.updateValues}
                    // disabled={!this.props.isNew}
                />
          </Grid>
          <Grid item xs className={classes.dropDown}>
            <FAutoComplete
                    url={API_DISPATCHERS_URL}
                    style={{width: 150}}
                    hasCarrier={true}
                    carrier = {this.props.data.carrier}
                    name='dispatcher'
                    hasError={this.props.errorData.dispatcher}
                    helpMessage={this.props.errorMessage.dispatcher}
                    label='Dispacher'
                    // queryParam='search'
                    value={this.props.data.dispatcher}
                    defaultValue={this.props.data.dispatcher}
                    notifyParent={this.props.updateValues}
                    // disabled={!this.props.isNew}
                />
          </Grid>
          <Grid item xs>
                <FSelect
                    name='status'
                    value={this.props.data.status}
                    hasError={this.props.errorData.status}
                    helpMessage={this.props.errorMessage.status}
                    label='Fleet Status'
                    data={this.props.statusChoice}
                    valueRef='prettyName'
                    keyRef='prettyName'
                    notifyParent={this.props.updateValues}
                />
            </Grid>
          <Grid item xs>
          <FButton color='primary'
                  disabled={this.props.disabled}
                  className={classes.button}
                  label={'Download CSV'}
                  loading={this.props.loading}
                  onClick={this.props.onDownload}
              />
          </Grid>
          
        </Grid>
        
        </React.Fragment>    
    )
  }
}


export default withStyles(styles)(connect(
  mapStateToProps, mapDispatchToProps)(FleetReportHeader))
